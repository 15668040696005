
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';

import { Accordion, AccordionSummary, AccordionDetails, Modal, TextField, MenuItem, Checkbox, Box, Button, FormControlLabel, Grid } from '@mui/material';

import { EditorState, ContentState, convertFromHTML, convertFromRaw, convertToRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { htmlToDraft } from 'draft-js-import-html';
import { stateFromHTML } from 'draft-js-import-html';
import { Editor } from 'react-draft-wysiwyg';

import RichTextEditor from './../../Commonfields/RichTextEditorComponent';
import Apiservice from "../../../Services/Apiservice";
import CommoncircularProgress from "../../../Shared/CommoncircularProgress/CommoncircularProgress";
import { URL } from "../../../Constants/apiUrl";
import UpdateSurveyName from '../UpdateSurveyNamePopup/UpdateSurveyNamePopup';
import { surveyNames, updateSurveyNameVariant } from '../../../Constants/static';
import "./SurveyPreviewPopup.css"

function SurveyPreviewPopup({ code1, isOpen, handleOKButton, isUpdated, initialSurveyName, reference, campaignCode, disabledflag, isEditedFlag}) {

	let code= sessionStorage.getItem("setCampaignselector")
    let surveyArray = sessionStorage.getItem("surveyArray")
    
    if (surveyArray === null){
        surveyArray = []
    }

    var req_body = {
        "survey_name": "",
        "survey_desc": "",
        "reference": null,
        "reference_order": 0,
        "is_custom": 0,
        "campaign_code": campaignCode,
        "welcome_text": {
            "en": "",
            "es": "",
            "vi": ""
        },
        "is_previewed": 0,
        "survey_code": [],
        "survey_template": [{
            "ref_questions": [


            ],
            "ref_answers": {

            }
        }],
        "invite_reminder_list": [
            {
                "sms_text": {
                    "en": "",
                    "es": "",
                    "vi": ""
                },
                "email_subject": "",
                "email_body": "",
                "invite_code": "NRSMS",
                "is_created": 1,
                "schedule_date": "1964-05-22 19:30:34"
            },
            {
                "sms_text": "",
                "email_subject": {
                    "en": "",
                    "es": "",
                    "vi": ""
                },
                "email_body": {
                    "en": "",
                    "es": "",
                    "vi": ""
                },
                "invite_code": "NREML",
                "is_created": 1,
                "schedule_date": "1964-05-22 19:30:34"
            },
            {
                "sms_text": {
                    "en": "",
                    "es": "",
                    "vi": ""
                },
                "email_subject": "",
                "email_body": "",
                "invite_code": "FRSMS",
                "is_created": 1,
                "schedule_date": "1964-05-22 19:30:34"
            },
            {
                "sms_text": "",
                "email_subject": {
                    "en": "",
                    "es": "",
                    "vi": ""
                },
                "email_body": {
                    "en": "",
                    "es": "",
                    "vi": ""
                },
                "invite_code": "FREML",
                "is_created": 1,
                "schedule_date": "1964-05-22 19:30:34"
            },
            {
                "sms_text": {
                    "en": "",
                    "es": "",
                    "vi": ""
                },
                "email_subject": "",
                "email_body": "",
                "invite_code": "FNSMS",
                "is_created": 1,
                "schedule_date": "1964-05-22 19:30:34"
            },
            {
                "sms_text": "",
                "email_subject": {
                    "en": "",
                    "es": "",
                    "vi": ""
                },
                "email_body": {
                    "en": "",
                    "es": "",
                    "vi": ""
                },
                "invite_code": "FNEML",
                "is_created": 1,
                "schedule_date": "1964-05-22 19:30:34"
            }
        ]
    }
    const invitations_reminders = [
        {
            "id": "sms_invitation",
            "title": "SMS Invitation",
            "description": "SMS Invitation Description"
        },
        {
            "id": "email_invitation",
            "title": "Email Invitation",
            "description": "Email Invitation Description"
        },
        {
            "id": "first_reminder_sms",
            "title": "First Reminder SMS",
            "description": "First Reminder SMS Description"
        },
        {
            "id": "first_reminder_email",
            "title": "First Reminder Email",
            "description": "First Reminder Email Description"
        },
        {
            "id": "final_reminder_sms",
            "title": "Final Reminder SMS",
            "description": "Final Reminder SMS Description"
        },
        {
            "id": "final_reminder_email",
            "title": "Final Reminder Email",
            "description": "Final Reminder Email Description"
        },
    ]
    const initialFlags = {
        "email_invitation": false,
        "final_reminder_email": false,
        "final_reminder_sms": false,
        "first_reminder_email": false,
        "first_reminder_sms": false,
        "sms_invitation": false,
        "thankyou_template": false,
        "welcome_template": false
    }

    const [focusStates, setFocusStates] = useState({
        surveyName: false,
        surveyDescription: false,
        question: false,
    });
    const [payload, setPayload] = useState(req_body);
    const [expanded, setExpanded] = useState('panel1');
    const [isLoading, setIsLoading] = useState(false);
    const [question, setQuestion] = useState('');
    const [questions, setQuestions] = useState('');
    const [WECAPQuestions, setWECAPQuestions] = useState([]);
    const [questionID, setQuestionID] = useState('');
    const [answers, setAnswers] = useState([]);
    const [language, setLanguage] = useState('en');
    const [questionIndex, setQuestionIndex] = useState(null);
    const [surveyTemplateDisplay, setSurveyTemplateDisplay] = useState(true);
    const [surveyQuestionDisplay, setSurveyQuestionDisplay] = useState(false);
    const [smsInvitationDisplay, setSmsInvitationDisplay] = useState(false);
    const [emailInvitationDisplay, setEmailInvitationDisplay] = useState(false);
    const [selectedSmsEmail, setSelectedSmsEmail] = useState('');
    const [emailSubject, setEmailSubject] = useState('');
    const [firstemailSubject, setFirstEmailSubject] = useState('');
    const [finalemailSubject, setFinalEmailSubject] = useState('');
    const [emailBody, setEmailBody] = useState(EditorState.createEmpty());
    const [firstemailBody, setFirstEmailBody] = useState('');
    const [finalemailBody, setFinalEmailBody] = useState('');
    const [messageText, setMessageText] = useState('');
    const [firstmessageText, setFirstMessageText] = useState('');
    const [finalmessageText, setFinalMessageText] = useState('');
    const [result, setResult] = useState(null);
    const [surveyCode, setSurveyCode] = useState([])
    const [surveyName, setSurveyName] = useState('')
    const [surveyDesc, setSurveyDesc] = useState('')

    const containerRef = useRef(null);
    const editorRef = useRef(null);

    const [previousValue, setPreviousValue] = useState('');
    const [currentValue, setCurrentValue] = useState('');

    const [selectedDescription, setSelectedDescription] = useState('');

    const [surveyNameError, setSurveyNameError] = useState('');
    const [surveyDescError, setSurveyDescError] = useState('');
    const [questionError, setQuestionError] = useState('');
    const [messageTextError, setMessageTextError] = useState('');
    const [subjectError, setSubjectError] = useState('');
    const [bodyTextError, setBodyTextError] = useState('');

    const [isDraft, setisDraft] = useState(false);

    const [surveyNameFocus, setSurveyNameFocus] = useState(false);
    const [surveyDescFocus, setSurveyDescFocus] = useState(false);
    const [questionFocus, setQuestionFocus] = useState(false);
    const inputRef = useRef(null);

    const [childModalopen, setChildModalOpen] = useState(false);
    const [isWECAP, setWECAP] = useState(false);

    const [ surveyUpdateVariant, setSurveyUpdateVariant ] = useState("")
    const [ checkVariant, setCheckVariant ] = useState("")

    const isFirstRender = useRef(true);

    const [ flags, setFlags ] = useState(initialFlags)
    const [ infoLangChange, setInfoLangChange ] = useState(false)


    // Event handler for focus
    const handleFocus = (fieldName, value) => {
        setPreviousValue(value);
        if (fieldName == 'surveyName') {
            setSurveyNameFocus(true)
        } else if (fieldName === 'surveyDescription') {
            setSurveyDescFocus(true)
        } else if (fieldName === 'question') {
            setQuestionFocus(true)
        }
        setFocusStates({ ...focusStates, [fieldName]: true });
    };

    // Event handler for blur
    const handleBlur = (fieldName) => {
        // setTimeout(() => {
        //     if (!inputRef.current.contains(document.activeElement)) {

        //         setFocusStates({ ...focusStates, [fieldName]: false });
        if (fieldName === 'surveyName') {
            if (currentValue.trim() === '') {
                setSurveyName(previousValue)
                setSurveyNameError('')
            }
        } else if (fieldName === 'surveyDescription') {
            if (currentValue === '') {
                setSurveyDesc(previousValue)
                setSurveyDescError('')
            }
        } else if (fieldName === 'question') {
            if (currentValue === '') {
                setQuestion(previousValue)
                setQuestionError('')
            }
        }
        //     }
        // }, 1000);
    };

    const handleTextFieldCancel = (fieldName) => {
        if (fieldName === 'surveyName') {
            setSurveyNameFocus(false)
            setSurveyName(previousValue)
        } else if (fieldName === 'surveyDescription') {
            setSurveyDescFocus(false)
            setSurveyDesc(previousValue)
        } else if (fieldName === 'question') {
            setQuestionFocus(false)
            setQuestion(previousValue)
        }
    }


    // const handleChange = (event) => {
    //     setCurrentValue(event.target.value);
    //     setQuestion(event.target.value);
    //     // setInputValue(event.target.value);
    // };

    // Handler to update the expanded state
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleSurveyNameChange = (e) => {
        setCurrentValue(e.target.value)
        setSurveyName(e.target.value);
        if (e.target.value.trim() === '') {
            setSurveyNameError('Survey name cannot be empty');
        } else {
            setSurveyNameError('');
        }

    }

    const handleupdatedSurveyName = (survey_name, variant) => {
        // let newPayload = {}


        payload.survey_name = survey_name
        payload.survey_desc = surveyDesc

        setSurveyName(survey_name)
        setSurveyNameFocus(false)
        setSurveyDescFocus(false)
        setQuestionFocus(false)
        isUpdated(true)

        if (variant === updateSurveyNameVariant["save_qn"]){
            if (isWECAP) {
                WECAPQuestions[questionIndex].question_text[language] = question
                let blockNumber = WECAPQuestions[questionIndex].index_value
                const index = payload.survey_template[blockNumber].ref_questions.findIndex(question => question.question_id === questionID);
                payload.survey_template[blockNumber].ref_questions[index].question_text[language] = question
            } else {
                if (questionIndex !== null){
                    questions[questionIndex].question_text[language] = question
                    payload.survey_template[0].ref_questions = questions
                    payload.survey_template[0].ref_answers = result.data.template[0].ref_answers
                }

            }

        }

        if (variant === updateSurveyNameVariant["save_inv"]){
            if (selectedSmsEmail === 'sms_invitation') {
                payload.invite_reminder_list[0].sms_text[language] = messageText
            } else if (selectedSmsEmail === 'email_invitation') {
                payload.invite_reminder_list[1].email_subject[language] = emailSubject
                payload.invite_reminder_list[1].email_body[language] = stateToHTML(emailBody.getCurrentContent())
            } else if (selectedSmsEmail === 'first_reminder_sms') {
                payload.invite_reminder_list[2].sms_text[language] = firstmessageText
            } else if (selectedSmsEmail === 'first_reminder_email') {
                payload.invite_reminder_list[3].email_subject[language] = firstemailSubject
                payload.invite_reminder_list[3].email_body[language] = stateToHTML(firstemailBody.getCurrentContent())
            } else if (selectedSmsEmail === 'final_reminder_sms') {
                payload.invite_reminder_list[4].sms_text[language] = finalmessageText
            } else if (selectedSmsEmail === 'final_reminder_email') {
                payload.invite_reminder_list[5].email_subject[language] = finalemailSubject
                payload.invite_reminder_list[5].email_body[language] = stateToHTML(finalemailBody.getCurrentContent())
            }
        }
        setPayload(payload)

        setCheckVariant(variant)
    };

    useEffect(() => {

        isEditedFlag = true

        if (checkVariant === updateSurveyNameVariant["save_qn"]) {
            questionSave();
        }
        if (checkVariant === updateSurveyNameVariant["save_info"]) {
            infoSave();
        }
        if (checkVariant === updateSurveyNameVariant["save_inv"]) {
            smsEmailSave();
        }
    }, [checkVariant]);




    const handleSurveyDescChange = (e) => {
        setCurrentValue(e.target.value)
        setSurveyDesc(e.target.value);
        if (e.target.value === '') {
            setSurveyDescError('Description cannot be empty');
        } else {
            setSurveyDescError('');
        }

    }

    const questionChange = (event) => {
        setCurrentValue(event.target.value)
        setQuestion(event.target.value);
        if (event.target.value === '') {
            setQuestionError('Question cannot be empty');
        } else {
            setQuestionError('');
        }
    };

    const messageTextChange = (event) => {
        if (selectedSmsEmail === 'sms_invitation') {
            setMessageText(event.target.value);
        } else if (selectedSmsEmail === 'first_reminder_sms') {
            setFirstMessageText(event.target.value)
        } else {
            setFinalMessageText(event.target.value)
        }
        if (event.target.value === '') {
            setMessageTextError('Message text cannot be empty');
        } else {
            setMessageTextError('');
        }
    };

    const emailSubjectChange = (event) => {
        if (selectedSmsEmail === 'email_invitation') {
            setEmailSubject(event.target.value);
        } else if (selectedSmsEmail === 'first_reminder_email') {
            setFirstEmailSubject(event.target.value)
        } else {
            setFinalEmailSubject(event.target.value)
        }
        if (event.target.value === '') {
            setSubjectError('Subject cannot be empty');
        } else {
            setSubjectError('');
        }
    };

    const handleEmailBodyTextChange = (value) => {
        const rawContent = convertToRaw(value.getCurrentContent());
        const htmlContent = stateToHTML(value.getCurrentContent());

        if (selectedSmsEmail === 'email_invitation') {
            setEmailBody(value);
        } else if (selectedSmsEmail === 'first_reminder_email') {
            setFirstEmailBody(value)
        } else {
            setFinalEmailBody(value)
        }


        const currentContent = value.getCurrentContent();
        if (currentContent.hasText() === false) {
            setBodyTextError('Welcome Text cannot be empty');
        } else {
            setBodyTextError('');
        }
    };

    function handleClickcancel(data) {
        payload.reference = reference
        
        if (!(code && code !== 'create_new' && isDraft)) {
            localStorage.setItem('surveyCreation', JSON.stringify(payload));
        }
        else{            
            localStorage.removeItem('surveyCreation');
        }
        
        handleOKButton(data, flags,code, JSON.stringify(payload));
        // }
    };

    const languageChange = (event) => {
        if(infoLangChange){
            setInfoLangChange(false)
        }
        setLanguage(event.target.value);
        if (questionIndex != null) {
            if (isWECAP) {
                setQuestion(WECAPQuestions[questionIndex].question_text[event.target.value])
            } else {
                setQuestion(result.data.template[0].ref_questions[questionIndex].question_text[event.target.value])
            }
        }
        if (selectedSmsEmail === 'sms_invitation') {
            if (result?.data?.invitations_reminders?.[selectedSmsEmail]?.body?.[event.target.value]) {
                setMessageText(result.data.invitations_reminders[selectedSmsEmail].body[event.target.value])
            }
            else if (payload.invite_reminder_list[0].sms_text[event.target.value]) {
                setMessageText(payload.invite_reminder_list[0].sms_text[event.target.value])
            }

            else {
                setMessageText('')
            }
        } else if (selectedSmsEmail === 'first_reminder_sms') {
            if (result?.data?.invitations_reminders?.[selectedSmsEmail]?.body?.[event.target.value]) {
                setFirstMessageText(result.data.invitations_reminders[selectedSmsEmail].body[event.target.value])
            }
            else if (payload.invite_reminder_list[2].sms_text[event.target.value]) {
                setFirstMessageText((payload.invite_reminder_list[2].sms_text[event.target.value]))
            }

            else {
                setFirstMessageText('')
            }
        } else if (selectedSmsEmail === 'final_reminder_sms') {
            if (result?.data?.invitations_reminders?.[selectedSmsEmail]?.body?.[event.target.value]) {
                setFinalMessageText(result.data.invitations_reminders[selectedSmsEmail].body[event.target.value])
            }
            else if (payload.invite_reminder_list[4].sms_text[event.target.value]) {
                setFinalMessageText((payload.invite_reminder_list[4].sms_text[event.target.value]))
            }

            else {
                setFinalMessageText('')
            }
        } else if (selectedSmsEmail === 'email_invitation') {
            if (result?.data?.invitations_reminders?.[selectedSmsEmail]?.subject?.[event.target.value]) {
                setEmailSubject(result.data.invitations_reminders[selectedSmsEmail].subject[event.target.value])
            }
            else if (payload.invite_reminder_list[1].email_subject[event.target.value]) {
                setEmailSubject(payload.invite_reminder_list[1].email_subject[event.target.value])
            }

            else {
                setEmailSubject('')
            }
            if (result?.data?.invitations_reminders?.[selectedSmsEmail]?.body?.[event.target.value]) {
                setEmailBody(convertHtmlToEditorState(result.data.invitations_reminders[selectedSmsEmail].body[event.target.value]))
            }
            else if (payload.invite_reminder_list[1].email_body[event.target.value]) {
                setEmailBody(convertHtmlToEditorState(payload.invite_reminder_list[1].email_body[event.target.value]))
            }

            else {
                setEmailBody('')
            }
        } else if (selectedSmsEmail === 'first_reminder_email') {
            if (result?.data?.invitations_reminders?.[selectedSmsEmail]?.subject?.[event.target.value]) {
                setFirstEmailSubject(result.data.invitations_reminders[selectedSmsEmail].subject[event.target.value])
            }
            else if (payload.invite_reminder_list[3].email_subject[event.target.value]) {
                setFirstEmailSubject(payload.invite_reminder_list[3].email_subject[event.target.value])
            }

            else {
                setFirstEmailSubject('')
            }
            if (result?.data?.invitations_reminders?.[selectedSmsEmail]?.body?.[event.target.value]) {
                setFirstEmailBody(convertHtmlToEditorState(result.data.invitations_reminders[selectedSmsEmail].body[event.target.value]))
            }
            else if (payload.invite_reminder_list[3].email_body[event.target.value]) {
                setFirstEmailBody(convertHtmlToEditorState(payload.invite_reminder_list[3].email_body[event.target.value]))
            }

            else {
                setFirstEmailBody('')
            }
        } else if (selectedSmsEmail === 'final_reminder_email') {
            if (result?.data?.invitations_reminders?.[selectedSmsEmail]?.subject?.[event.target.value]) {
                setFinalEmailSubject(result.data.invitations_reminders[selectedSmsEmail].subject[event.target.value])
            }
            else if (payload.invite_reminder_list[5].email_subject[event.target.value]) {
                setFinalEmailSubject(payload.invite_reminder_list[5].email_subject[event.target.value])
            }

            else {
                setFinalEmailSubject('')
            }
            if (result?.data?.invitations_reminders?.[selectedSmsEmail]?.body?.[event.target.value]) {
                setFinalEmailBody(convertHtmlToEditorState(result.data.invitations_reminders[selectedSmsEmail].body[event.target.value]))
            }
            else if (payload.invite_reminder_list[5].email_body[event.target.value]) {
                setFinalEmailBody(convertHtmlToEditorState(payload.invite_reminder_list[5].email_body[event.target.value]))
            }

            else {
                setFinalEmailBody('')
            }
        }

    };

    const convertHtmlToEditorState = (html) => {
        const blocksFromHTML = convertFromHTML(html);
        const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
        return EditorState.createWithContent(contentState);
    };

    const [checked, setChecked] = useState(false);

    useEffect(() => {
        setInfoLangChange(false);
        setLanguage('en')
        if (isOpen) {
            setSurveyTemplateDisplay(true)
            setSurveyQuestionDisplay(false)
            setSmsInvitationDisplay(false)
            setEmailInvitationDisplay(false)
            setQuestionIndex(null)
            setSelectedSmsEmail('')
            setExpanded('panel1')
            let surveyData = localStorage.getItem('surveyCreation')            
            
            if (code && !localStorage.getItem('surveyCreation')) {
                
                setIsLoading(true);
                const fetchData = async () => {
                    try {

                        // if(!localStorage.getItem('surveyCreation')){
                        let endpoint = "?survey_code=" + code + "&campaign_code=" + campaignCode + "&reference=" + reference;
                        let url = URL.SURVEY_PREVIEW + endpoint;
                        const response = await axios.get(url);
                        setResult(response.data)
                        payload.survey_code = response.data.data.survey_code
                        payload.survey_desc = response.data.data.survey_description
                        payload.survey_name = response.data.data.survey_name

                        setSurveyCode(response.data.data.survey_code)
                        setSurveyName(response.data.data.survey_name.trim())
                        console.log(response.data.data.survey_name.trim(),"5555");
                        
                        setSurveyDesc(response.data.data.survey_description)
                        if (response.data.data.invitations_reminders) {

                            payload.invite_reminder_list[0].sms_text = response.data.data.invitations_reminders.sms_invitation.body
                            payload.invite_reminder_list[1].email_subject = response.data.data.invitations_reminders.email_invitation.subject
                            payload.invite_reminder_list[1].email_body = response.data.data.invitations_reminders.email_invitation.body
                            payload.invite_reminder_list[2].sms_text = response.data.data.invitations_reminders.first_reminder_sms.body
                            payload.invite_reminder_list[3].email_subject = response.data.data.invitations_reminders.first_reminder_email.subject
                            payload.invite_reminder_list[3].email_body = response.data.data.invitations_reminders.first_reminder_email.body
                            payload.invite_reminder_list[4].sms_text = response.data.data.invitations_reminders.final_reminder_sms.body
                            payload.invite_reminder_list[5].email_subject = response.data.data.invitations_reminders.final_reminder_email.subject
                            payload.invite_reminder_list[5].email_body = response.data.data.invitations_reminders.final_reminder_email.body

                            payload.invite_reminder_list[0].schedule_date = response.data.data.invitations_reminders.sms_invitation.schedule_date
                            payload.invite_reminder_list[1].schedule_date = response.data.data.invitations_reminders.email_invitation.schedule_date
                            payload.invite_reminder_list[2].schedule_date = response.data.data.invitations_reminders.first_reminder_sms.schedule_date
                            payload.invite_reminder_list[3].schedule_date = response.data.data.invitations_reminders.first_reminder_email.schedule_date
                            payload.invite_reminder_list[4].schedule_date = response.data.data.invitations_reminders.final_reminder_sms.schedule_date
                            payload.invite_reminder_list[5].schedule_date = response.data.data.invitations_reminders.final_reminder_email.schedule_date
                            

                            //invitations & reminders
                            setMessageText(response.data.data.invitations_reminders.sms_invitation.body[language])
                            setEmailSubject(response.data.data.invitations_reminders.email_invitation.subject[language])
                            setEmailBody(convertHtmlToEditorState(response.data.data.invitations_reminders.email_invitation.body[language]))
                            setFirstMessageText(response.data.data.invitations_reminders.first_reminder_sms.body[language])
                            setFirstEmailSubject(response.data.data.invitations_reminders.first_reminder_email.subject[language])
                            setFirstEmailBody(convertHtmlToEditorState(response.data.data.invitations_reminders.first_reminder_email.body[language]))
                            setFinalMessageText(response.data.data.invitations_reminders.final_reminder_sms.body[language])
                            setFinalEmailSubject(response.data.data.invitations_reminders.final_reminder_email.subject[language])
                            setFinalEmailBody(convertHtmlToEditorState(response.data.data.invitations_reminders.final_reminder_email.body[language]))
                        }

                        setWECAP(response.data.data.reference === 'WECAP')

                        if (response.data.data.reference === 'WECAP') {
                            payload.survey_template = response.data.data.template
                            // Function to merge all ref_questions into a single array with index_value
                            const mergeRefQuestionsWithIndex = (data) => {
                                return data.flatMap((item, index) =>
                                    item.ref_questions.map(question => ({
                                        ...question,
                                        index_value: index
                                    }))
                                );
                            };

                            // Usage
                            const mergedRefQuestionsWithIndex = mergeRefQuestionsWithIndex(response.data.data.template);
                            setWECAPQuestions(mergedRefQuestionsWithIndex)
                        } else {
                            payload.survey_template[0].ref_questions = response.data.data.template[0].ref_questions
                            payload.survey_template[0].ref_answers = response.data.data.template[0].ref_answers
                            //questions
                            setQuestions(response.data.data.template[0].ref_questions)

                            setAnswers(response.data.data.template[0].ref_answers)
                        }



                        //isDraft
                        setisDraft(response.data.data.is_draft)
                        // }else{
                        //     let payload = JSON.parse(localStorage.getItem('surveyCreation'))
                        //     console.log(payload)
                        //     setSurveyCode(payload.survey_code)
                        //     setSurveyName(payload.survey_name)
                        //     setSurveyDesc(payload.survey_description)

                        //     // if (response.data.data.reference === 'WECAP') {
                        //         // Function to merge all ref_questions into a single array with index_value
                        //     //     const mergeRefQuestionsWithIndex = (data) => {
                        //     //         return data.flatMap((item, index) =>
                        //     //             item.ref_questions.map(question => ({
                        //     //                 ...question,
                        //     //                 index_value: index
                        //     //             }))
                        //     //         );
                        //     //     };

                        //     //     // Usage
                        //     //     const mergedRefQuestionsWithIndex = mergeRefQuestionsWithIndex(response.data.data.template);
                        //     //     setWECAPQuestions(mergedRefQuestionsWithIndex)
                        //     // } else {
                        //         //questions
                        //         setQuestions(payload.survey_template[0].ref_questions)

                        //         setAnswers(payload.survey_template[0].ref_answers)
                        //         // console.log(response.data.data.template[0].ref_answers)
                        //     // }
                        // }

                        setIsLoading(false);
                    } catch (error) {
                        setIsLoading(false);
                        console.error('There was an error!', error);
                        handleOKButton();
                    }
                };

                fetchData();

            }

        }
    }, [isOpen]);

    const getQuestion = (index) => {
        setSurveyQuestionDisplay(true)
        setSurveyTemplateDisplay(false)
        setSmsInvitationDisplay(false)
        setEmailInvitationDisplay(false)
        setQuestionIndex(index)
        setSelectedSmsEmail('')
        setInfoLangChange(false)
        let question_text = questions[index].question_text[language]
        setQuestion(question_text)
        setQuestionID(questions[index].question_id)
        let question_id = questions[index].question_id
        getAnswers(question_id)
    };

    const getAnswers = (question_id) => {
        setAnswers(result.data.template[0].ref_answers[question_id])
    };

    const getWECAPQuestion = (index) => {
        setSurveyQuestionDisplay(true)
        setSurveyTemplateDisplay(false)
        setSmsInvitationDisplay(false)
        setEmailInvitationDisplay(false)
        setQuestionIndex(index)
        setSelectedSmsEmail('')
        setInfoLangChange(false)
        let question_text = WECAPQuestions[index].question_text[language]
        setQuestion(question_text)
        setQuestionID(WECAPQuestions[index].question_id)
        let question_id = WECAPQuestions[index].question_id
        let indexValue = WECAPQuestions[index].index_value
        getWECAPAnswers(question_id, indexValue)
    };

    const getWECAPAnswers = (question_id, index) => {
        setAnswers(result.data.template[index].ref_answers[question_id])
    };

    const infoSave = () => {

        if(!surveyNameError){
            if (surveyArray.includes(surveyName)){
                setSurveyUpdateVariant(updateSurveyNameVariant["save_info"])
            }

            else {
                payload.survey_name = surveyName
                payload.survey_desc = surveyDesc

                setPayload(payload)
                console.log(payload)
                setSurveyNameFocus(false)
                setSurveyDescFocus(false)
                isUpdated(true)
                if (code && code != 'create_new' && isDraft) {
                    setIsLoading(true);
                    let apiurl = URL.UPDATE_SURVEY;
                    const body = {
                        "survey_code": surveyCode,
                        "element_order": !isWECAP ? 0 : 0,
                        "is_details": 1,
                        "survey_name": surveyName,
                        "survey_desc": surveyDesc,
                        "is_invite_reminder": 0,
                        "is_custom": 0,
                        "is_survey_update": 0,
                        "reference": reference,
                        "campaign_code": campaignCode,
                        "welcome_text": {
                            "en": payload.welcome_text["en"],
                            "es": payload.welcome_text["es"],
                            "vi": payload.welcome_text["vi"]
                        },
                    }
                    Apiservice.patchMethod(apiurl, body).then(response => {
                        setIsLoading(false);
                        localStorage.removeItem('surveyCreation');
                        localStorage.removeItem('Survey_reference');
                    })
                        .catch(error => {
                            setIsLoading(false);
                            console.error('There was an error!', error);
                        })
                }

                else{
                    setIsLoading(true);
                        setTimeout(() => {
                            setIsLoading(false);
                        }, 500);
                }
            }
        }
    }

    const questionSave = () => {
        let elemntOrder = 0
        setQuestionFocus(false)
        
        if (surveyArray.includes(surveyName)){
            setSurveyUpdateVariant(updateSurveyNameVariant["save_qn"])
        }

        else {
            setInfoLangChange(true)
            isUpdated(true)
            if (isWECAP) {
                WECAPQuestions[questionIndex].question_text[language] = question
                let blockNumber = WECAPQuestions[questionIndex].index_value
                elemntOrder = blockNumber
                const index = payload.survey_template[blockNumber].ref_questions.findIndex(question => question.question_id === questionID);
                payload.survey_template[blockNumber].ref_questions[index].question_text[language] = question
            } else {
                
                
                questions[questionIndex].question_text[language] = question
                payload.survey_template[0].ref_questions = questions
                // payload.survey_template[0].ref_answers = result.data.template[0].ref_answers
            }

            setPayload(payload)

            if (code && code != 'create_new' && isDraft) {
                isUpdated(true)
                setIsLoading(true);
                let apiurl = URL.UPDATE_SURVEY;
                const body = {
                    "survey_code": surveyCode,
                    "is_details": 1,
                    "survey_name": surveyName,
                    "survey_desc" : surveyDesc,
                    "is_custom": false,
                    "is_survey_update": 1,
                    "is_invite_reminder": 0,
                    "element_order": elemntOrder,
                    "reference": reference,
                    "campaign_code": campaignCode,
                    "template" : [
                        {
                            "ref_questions" : [
                                !isWECAP ? questions.filter(question => question.question_id === questionID)[0] : WECAPQuestions.filter(question => question.question_id === questionID).map(({ index_value, ...rest }) => rest)[elemntOrder]
                            ]
                        }
                    ],
                    // "ref_answers": result.data.template[0].ref_answers[questionID],
                    "welcome_text": {
                        "en": "",
                        "es": "",
                        "vi": ""
                    },
                }
                Apiservice.patchMethod(apiurl, body).then(response => {
                    setIsLoading(false);
                    localStorage.removeItem('surveyCreation');
                    localStorage.removeItem('Survey_reference');

                })
                    .catch(error => {
                        setIsLoading(false);
                        console.error('There was an error!', error);
                    })
            }
            else{
                setIsLoading(true);
                    setTimeout(() => {
                        setIsLoading(false);
                    }, 500);
            }
        }

    }

    const smsEmailSave = () => {
        
        if (surveyArray.includes(surveyName)){
            setSurveyUpdateVariant(updateSurveyNameVariant["save_inv"])
        }

        else {
            setInfoLangChange(true)
            isUpdated(true)
            if (selectedSmsEmail === 'sms_invitation') {
                payload.invite_reminder_list[0].sms_text[language] = messageText
            } else if (selectedSmsEmail === 'email_invitation') {
                payload.invite_reminder_list[1].email_subject[language] = emailSubject
                payload.invite_reminder_list[1].email_body[language] = stateToHTML(emailBody.getCurrentContent())
            } else if (selectedSmsEmail === 'first_reminder_sms') {
                payload.invite_reminder_list[2].sms_text[language] = firstmessageText
            } else if (selectedSmsEmail === 'first_reminder_email') {
                payload.invite_reminder_list[3].email_subject[language] = firstemailSubject
                payload.invite_reminder_list[3].email_body[language] = stateToHTML(firstemailBody.getCurrentContent())
            } else if (selectedSmsEmail === 'final_reminder_sms') {
                payload.invite_reminder_list[4].sms_text[language] = finalmessageText
            } else if (selectedSmsEmail === 'final_reminder_email') {
                payload.invite_reminder_list[5].email_subject[language] = finalemailSubject
                payload.invite_reminder_list[5].email_body[language] = stateToHTML(finalemailBody.getCurrentContent())
            }
            setPayload(payload)

            if (code && code != 'create_new' && isDraft) {
                // if (surveyName == initialSurveyName) {
                //     setChildModalOpen(true)
                // } else {
                    var invite_reminder_list
                    if (selectedSmsEmail === 'sms_invitation') {
                        invite_reminder_list = payload.invite_reminder_list[0]
                    } else if (selectedSmsEmail === 'email_invitation') {
                        invite_reminder_list = payload.invite_reminder_list[1]
                    } else if (selectedSmsEmail === 'first_reminder_sms') {
                        invite_reminder_list = payload.invite_reminder_list[2]
                    } else if (selectedSmsEmail === 'first_reminder_email') {
                        invite_reminder_list = payload.invite_reminder_list[3]
                    } else if (selectedSmsEmail === 'final_reminder_sms') {
                        invite_reminder_list = payload.invite_reminder_list[4]
                    } else if (selectedSmsEmail === 'final_reminder_email') {
                        invite_reminder_list = payload.invite_reminder_list[5]
                    }
                    setIsLoading(true);
                    let apiurl = URL.UPDATE_SURVEY;
                    const body = {
                        "survey_code": surveyCode,
                        "element_order": !isWECAP ? 0 : 0,
                        "is_details": 1,
                        "survey_name": surveyName,
                        "survey_desc": surveyDesc,
                        "is_custom": false,
                        "reference": reference,
                        "campaign_code": campaignCode,
                        "is_survey_update": 0,
                        "is_invite_reminder": 1,
                        "invite_reminder_list": [invite_reminder_list]
                    }
                    Apiservice.patchMethod(apiurl, body).then(response => {
                        setIsLoading(false);
                        localStorage.removeItem('surveyCreation');
                        localStorage.removeItem('Survey_reference');

                    })
                        .catch(error => {
                            setIsLoading(false);
                            console.error('There was an error!', error);
                        })
                // }
            }
            else{
                setIsLoading(true);
                    setTimeout(() => {
                        setIsLoading(false);
                    }, 500);
            }
        }

    }

    useEffect(() => {
        if (isFirstRender.current) {
            // Skip the effect on the initial render
            isFirstRender.current = false;
        } else {
            // This will run only when surveyUpdateVariant changes, not on initial load
            console.log("From useEffect useEffect");
            console.log(surveyUpdateVariant,"setSurveyUpdateVariant");

            setChildModalOpen(true);
        }
    }, [surveyUpdateVariant]);

    const surveyTemplate = () => {
        setSurveyTemplateDisplay(true)
        setSurveyQuestionDisplay(false)
        setSmsInvitationDisplay(false)
        setEmailInvitationDisplay(false)
        setQuestionIndex(null)
        setSelectedSmsEmail('')
        setInfoLangChange(false)
    };

    const getInvitationReminder = (data) => {
        setBodyTextError('')
        setSubjectError('')
        setMessageTextError('')
        setSelectedSmsEmail(data)
        findDescriptionById(data)
        setInfoLangChange(false)
        if (data === 'sms_invitation' || data === 'first_reminder_sms' || data === 'final_reminder_sms') {
            // setMessageText(result.data.invitations_reminders[data].body[language])
            setSmsInvitationDisplay(true)
            setSurveyTemplateDisplay(false)
            setSurveyQuestionDisplay(false)
            setEmailInvitationDisplay(false)
            setQuestionIndex(null)
        } else if (data === 'email_invitation' || data === 'first_reminder_email' || data === 'final_reminder_email') {

            // setEmailSubject(result.data.invitations_reminders[data].subject[language])
            // console.log(result.data.invitations_reminders[data].body[language])
            // setEmailBody(result.data.invitations_reminders[data].body[language])
            setEmailInvitationDisplay(true)
            setSmsInvitationDisplay(false)
            setSurveyTemplateDisplay(false)
            setSurveyQuestionDisplay(false)
            setQuestionIndex(null)
        }
        if (data === 'sms_invitation') {
            if (!localStorage.getItem('surveyCreation') && result?.data?.invitations_reminders?.[data]?.body?.[language]) {
                setMessageText(result.data.invitations_reminders[data].body[language])
            }
            else if (payload.invite_reminder_list[0].sms_text[language]) {
                setMessageText(payload.invite_reminder_list[0].sms_text[language])
            }

            else {
                setMessageText('')
            }
        } else if (data === 'first_reminder_sms') {
            if (!localStorage.getItem('surveyCreation') && result?.data?.invitations_reminders?.[data]?.body?.[language]) {
                setFirstMessageText(result.data.invitations_reminders[data].body[language])
            }
            else if (payload.invite_reminder_list[2].sms_text[language]) {
                setFirstMessageText((payload.invite_reminder_list[2].sms_text[language]))
            }

            else {
                setFirstMessageText('')
            }
        } else if (data === 'final_reminder_sms') {
            if (!localStorage.getItem('surveyCreation') && result?.data?.invitations_reminders?.[data]?.body?.[language]) {
                setFinalMessageText(result.data.invitations_reminders[data].body[language])
            }
            else if (payload.invite_reminder_list[4].sms_text[language]) {
                setFinalMessageText((payload.invite_reminder_list[4].sms_text[language]))
            }

            else {
                setFinalMessageText('')
            }
        } else if (data === 'email_invitation') {
            if (!localStorage.getItem('surveyCreation') && result?.data?.invitations_reminders?.[data]?.subject?.[language]) {
                setEmailSubject(result.data.invitations_reminders[data].subject[language])
            }
            else if (payload.invite_reminder_list[1].email_subject[language]) {
                setEmailSubject(payload.invite_reminder_list[1].email_subject[language])
            }

            else {
                setEmailSubject('')
            }
            if (!localStorage.getItem('surveyCreation') && result?.data?.invitations_reminders?.[data]?.body?.[language]) {
                setEmailBody(convertHtmlToEditorState(result.data.invitations_reminders[data].body[language]))
            }
            else if (payload.invite_reminder_list[1].email_body[language]) {
                setEmailBody(convertHtmlToEditorState(payload.invite_reminder_list[1].email_body[language]))
            }

            else {
                setEmailBody('')
            }
        } else if (data === 'first_reminder_email') {
            if (!localStorage.getItem('surveyCreation') && result?.data?.invitations_reminders?.[data]?.subject?.[language]) {
                setFirstEmailSubject(result.data.invitations_reminders[data].subject[language])
            }
            else if (payload.invite_reminder_list[3].email_subject[language]) {
                setFirstEmailSubject(payload.invite_reminder_list[3].email_subject[language])
            }

            else {
                setFirstEmailSubject('')
            }
            if (!localStorage.getItem('surveyCreation') && result?.data?.invitations_reminders?.[data]?.body?.[language]) {
                setFirstEmailBody(convertHtmlToEditorState(result.data.invitations_reminders[data].body[language]))
            }
            else if (payload.invite_reminder_list[3].email_body[language]) {
                setFirstEmailBody(convertHtmlToEditorState(payload.invite_reminder_list[3].email_body[language]))
            }

            else {
                setFirstEmailBody('')
            }
        } else if (data === 'final_reminder_email') {
            if (!localStorage.getItem('surveyCreation') && result?.data?.invitations_reminders?.[data]?.subject?.[language]) {
                setFinalEmailSubject(result.data.invitations_reminders[data].subject[language])
            }
            else if (payload.invite_reminder_list[5].email_subject[language]) {
                setFinalEmailSubject(payload.invite_reminder_list[5].email_subject[language])
            }

            else {
                setFinalEmailSubject('')
            }
            if (!localStorage.getItem('surveyCreation') && result?.data?.invitations_reminders?.[data]?.body?.[language]) {
                setFinalEmailBody(convertHtmlToEditorState(result.data.invitations_reminders[data].body[language]))
            }
            else if (payload.invite_reminder_list[5].email_body[language]) {
                setFinalEmailBody(convertHtmlToEditorState(payload.invite_reminder_list[5].email_body[language]))
            }

            else {
                setFinalEmailBody('')
            }
        }


    }

    const prevQuestion = () => {
        if (questionIndex > 0) {
            if (isWECAP) {
                getWECAPQuestion(questionIndex - 1)
            } else {
                getQuestion(questionIndex - 1)
            }
            setQuestionIndex(questionIndex - 1)
        }
        if (containerRef.current) {
            const element = containerRef.current.children[questionIndex - 1];
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    };

    const nextQuestion = () => {
        if (isWECAP) {
            getWECAPQuestion(questionIndex + 1)
        } else {
            getQuestion(questionIndex + 1)
        }
        setQuestionIndex(questionIndex + 1)
        if (containerRef.current) {
            const element = containerRef.current.children[questionIndex + 1];
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    };

    const findDescriptionById = (id) => {
        const foundItem = invitations_reminders.find(item => item.id === id);
        if (foundItem) {
            setSelectedDescription(foundItem.description);
        } else {
            setSelectedDescription('');
        }
    };

    return (
        <div >
            <Modal
                open={isOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">


                <Box className={"surveyPreviewPopupcontainer"}>
                    {isLoading &&
                        <CommoncircularProgress></CommoncircularProgress>
                    }
                    <Grid container  >
                        <Grid item xs={12} className={"GridIcon"} onClick={handleClickcancel}>
                            <img src="/asset/Member/cancelicon.png" alt="cancelIcon" width="15px" height="15px" />
                        </Grid>

                        <Grid item xs={12} className={"Gridtext"}>
                            <Grid container className={"accordian"}>
                                <Grid item xs={3}>
                                    <button className={surveyTemplateDisplay ? 'surveyBtn active' : 'surveyBtn'} onClick={() => surveyTemplate()}>
                                        Survey Details
                                    </button>
                                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                        <AccordionSummary className="accordianSummary" expandIcon={<img src="/asset/Agent/accordianIcon.png" className='accordianIcon' alt="upward Icon" />}>Survey Question</AccordionSummary>
                                        <AccordionDetails variant="soft" className="accordianDetails">

                                            <div className=' scrollable-accordian' ref={containerRef}>
                                                {!isWECAP && questions && questions.map((item, index) => (
                                                    <button key={index} className={index == questionIndex ? 'questions active' : 'questions'} onClick={() => getQuestion(index)}>
                                                        {index + 1}. {item.question_text.en}
                                                    </button>
                                                ))}
                                                {isWECAP && WECAPQuestions && WECAPQuestions.map((item, index) => (
                                                    <button key={index} className={index == questionIndex ? 'questions active' : 'questions'} onClick={() => getWECAPQuestion(index)}>
                                                        {index + 1}. {item.question_text.en}
                                                    </button>
                                                ))}
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                        <AccordionSummary className="accordianSummary" expandIcon={<img src="/asset/Agent/accordianIcon.png" className='accordianIcon' alt="upward Icon" />}>Invitations & Reminders</AccordionSummary>
                                        <AccordionDetails variant="soft" className="accordianDetails">
                                            <div className='scrollable-accordian'>
                                                {invitations_reminders.map((item, index) => (
                                                    <button
                                                        key={item.id}
                                                        className={
                                                            (selectedSmsEmail === item.id)
                                                                ? 'questions active'
                                                                : 'questions'
                                                        } onClick={() => getInvitationReminder(item.id)}>
                                                        {item.title}
                                                    </button>
                                                ))}
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>

                                </Grid>
                                <Grid item xs={9} >
                                    {surveyTemplateDisplay && (
                                        <Grid container spacing={2} alignItems="center" style={{ position: 'relative' }}>
                                            <Grid item xs={12} className='surveyDiv'><Grid item xs={12} style={{ position: 'relative' }} ref={inputRef}>
                                                <div className="label_text">Survey Name</div>
                                                <TextField
                                                    className={"textField"}
                                                    value={surveyName}
                                                    onFocus={() => handleFocus('surveyName', surveyName)}
                                                    onBlur={() => handleBlur('surveyName')}
                                                    onChange={handleSurveyNameChange}
                                                    inputProps={{ maxLength: 64 }}
                                                    id="outlined-basic"
                                                    variant="outlined"
                                                    fullWidth
                                                    placeholder="Type your survey name..." 
                                                    disabled={disabledflag}/>
                                                {surveyNameError && <span className='errorMsg'>{surveyNameError}</span>}
                                                {surveyNameFocus && (
                                                    <div style={{ position: 'absolute', bottom: '-28px', right: '5%', display: 'flex', alignItems: 'center' }}>
                                                        <div className='tickBox' onClick={infoSave}>
                                                            <img className='iconTickClose' src="/asset/Agent/tick.png" alt="update" />
                                                        </div>

                                                        <div className='closeBox' onClick={() => handleTextFieldCancel('surveyName')}>
                                                            <img className='iconTickClose' src="/asset/Agent/close.png" alt="cancel" />
                                                        </div>
                                                    </div>
                                                )}
                                            </Grid>
                                                <Grid item xs={12} className='descDiv'>
                                                    <div className="label_text">Description</div>
                                                    <TextField
                                                        className={"textField"}
                                                        id="outlined-multiline-flexible"
                                                        multiline
                                                        value={surveyDesc}
                                                        rows={3}
                                                        inputProps={{ maxLength: 255 }}
                                                        onFocus={() => handleFocus('surveyDescription', surveyDesc)}
                                                        onBlur={() => handleBlur('surveyDescription')}
                                                        onChange={handleSurveyDescChange}
                                                        fullWidth
                                                        variant="outlined"
                                                        placeholder="Write a description here..."
                                                        disabled={disabledflag} />
                                                    {surveyDescError && <span className='errorMsg'>{surveyDescError}</span>}
                                                    {surveyDescFocus && (
                                                        <div style={{ position: 'absolute', bottom: '-28px', right: '5%', display: 'flex', alignItems: 'center' }}>
                                                            <div className='tickBox' onClick={infoSave}><img className='iconTickClose' src="/asset/Agent/tick.png" alt="update" /></div>

                                                            <div className='closeBox' onClick={() => handleTextFieldCancel('surveyDescription')}><img className='iconTickClose' src="/asset/Agent/close.png" alt="cancel" /></div>
                                                        </div>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}

                                    {surveyQuestionDisplay && (
                                        <Grid container spacing={2} alignItems="center" style={{ position: 'relative' }}>
                                            {questionIndex != 0 && (
                                                <img className="arrow arrowLeft" src="/asset/Agent/arrowdowncampaign.png" alt="Left Icon" onClick={() => prevQuestion()} />
                                            )}
                                            {!isWECAP && questionIndex != result.data.template[0].ref_questions.length - 1 && (
                                                <img className="arrow arrowRight" src="/asset/Agent/arrowdowncampaign.png" alt="Right Icon" onClick={() => nextQuestion()} />
                                            )}

                                            {isWECAP && questionIndex != WECAPQuestions.length - 1 && (
                                                <img className="arrow arrowRight" src="/asset/Agent/arrowdowncampaign.png" alt="Right Icon" onClick={() => nextQuestion()} />
                                            )}

                                            <Grid container xs={12} className='surveyQuestionDiv'>
                                                <Grid item xs={10} >
                                                    <h3 className='surveyText'>{surveyName}</h3>
                                                </Grid>
                                                <Grid item xs={2}>

                                                    <TextField
                                                        className={"textField"}
                                                        fullWidth
                                                        select

                                                        variant="outlined"
                                                        value={language}
                                                        onChange={languageChange}
                                                    >
                                                        <MenuItem value="en">English</MenuItem>
                                                        <MenuItem value="es">Spanish</MenuItem>
                                                        <MenuItem value="vi">Vietnamese</MenuItem>
                                                    </TextField>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} className='scrollbar-space '>
                                                <div className='questionBox scrollable-box' >
                                                    <div style={{ position: 'relative' }}>
                                                        <TextField
                                                            className={"textField"}
                                                            id="outlined-basic"
                                                            value={question}
                                                            onFocus={() => handleFocus('question', question)}
                                                            onBlur={() => handleBlur('question')}
                                                            onChange={questionChange}
                                                            variant="outlined"
                                                            fullWidth
                                                            rows={2}
                                                            multiline
                                                            placeholder="Question"
                                                            disabled={disabledflag}
                                                        />
                                                        {questionError && <span className='errorMsg'>{questionError}</span>}
                                                        {questionFocus && (
                                                            <div style={{ position: 'absolute', bottom: '-28px', right: '5%', display: 'flex', alignItems: 'center' }}>
                                                                <div className='tickBox' onClick={questionSave}><img className='iconTickClose' src="/asset/Agent/tick.png" alt="update" /></div>

                                                                <div className='closeBox' onClick={() => handleTextFieldCancel('question')}><img className='iconTickClose' src="/asset/Agent/close.png" alt="cancel" /></div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    {answers && answers.map((item, index) => (

                                                        <div className='answerBox'>
                                                            <FormControlLabel disabled
                                                                control={<Checkbox checked={checked} />}
                                                                label={item.answer_text[language]}
                                                            />
                                                        </div>
                                                    ))}

                                                    {/* <Button
                                                        className='nextBtn'
                                                        variant="contained"
                                                        color="primary"
                                                        disabled
                                                    >
                                                        Next
                                                    </Button> */}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    )}

                                    {smsInvitationDisplay && (
                                        <Grid container xs={12} className='surveyQuestionDiv'>
                                            <Grid item xs={10} >
                                                <h3 className='surveyText'>{surveyName}</h3>
                                                <p className='smsDesc'>{selectedDescription}</p>
                                            </Grid>
                                            <Grid item xs={2}>

                                                <TextField
                                                    className={"textField"}
                                                    fullWidth
                                                    select

                                                    variant="outlined"
                                                    value={language}
                                                    onChange={languageChange}
                                                >
                                                    <MenuItem value="en">English</MenuItem>
                                                    <MenuItem value="es">Spanish</MenuItem>
                                                    <MenuItem value="vi">Vietnamese</MenuItem>
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12} className='descDiv'>
                                                <div className="label_text">Message Text</div>
                                                <TextField
                                                    className={"textField"}
                                                    id="outlined-multiline-flexible"
                                                    multiline
                                                    value={selectedSmsEmail === 'sms_invitation' ? messageText : selectedSmsEmail === 'first_reminder_sms' ? firstmessageText : finalmessageText}
                                                    rows={3}
                                                    onChange={messageTextChange}
                                                    onFocus={handleFocus}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    variant="outlined"
                                                    placeholder="Type message here..." 
                                                    disabled={disabledflag}/>
                                                {messageTextError && <span className='errorMsg'>{messageTextError}</span>}
                                            </Grid>
                                            <Grid item xs={12} spacing={2} className='saveCancelDiv'>

                                                <Button
                                                    variant="contained"
                                                    aria-label="Save"
                                                    type="submit"
                                                    disabled={messageTextError || disabledflag}
                                                    onClick={smsEmailSave}
                                                    className="Savebtn">
                                                    Save
                                                </Button>

                                            </Grid>
                                        </Grid>
                                    )}

                                    {emailInvitationDisplay && (
                                        <Grid container xs={12} className='surveyQuestionDiv'>
                                            <Grid item xs={10} >
                                                <h3 className='surveyText'>{surveyName}</h3>
                                                <p className='smsDesc'>{selectedDescription}</p>
                                            </Grid>
                                            <Grid item xs={2}>

                                                <TextField
                                                    className={"textField"}
                                                    fullWidth
                                                    select
                                                    variant="outlined"
                                                    value={language}
                                                    onChange={languageChange}
                                                >
                                                    <MenuItem value="en">English</MenuItem>
                                                    <MenuItem value="es">Spanish</MenuItem>
                                                    <MenuItem value="vi">Vietnamese</MenuItem>
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12} className='descDiv'>
                                                <div className="label_text">Subject</div>
                                                <TextField
                                                    className={"textField"}
                                                    value={selectedSmsEmail === 'email_invitation' ? emailSubject : selectedSmsEmail === 'first_reminder_email' ? firstemailSubject : finalemailSubject}
                                                    onChange={emailSubjectChange}
                                                    onFocus={handleFocus}
                                                    onBlur={handleBlur}
                                                    inputProps={{ maxLength: 255 }}
                                                    id="outlined-basic"
                                                    variant="outlined"
                                                    fullWidth
                                                    placeholder="Enter subject line..." 
                                                    disabled={disabledflag}/>
                                                {subjectError && <span className='errorMsg'>{subjectError}</span>}

                                            </Grid>
                                            <div className="body_text_label" >Body Text</div>
                                            <Grid xs={12} className='richTextEditor'
                                                sx = {{
                                                        opacity: disabledflag ? 0.7 : 1,
                                                        pointerEvents: disabledflag ? 'none' : 'auto'
                                                    }}>


                                                <Editor
                                                    editorState={selectedSmsEmail === 'email_invitation' ? emailBody : selectedSmsEmail === 'first_reminder_email' ? firstemailBody : finalemailBody}
                                                    onEditorStateChange={handleEmailBodyTextChange}
                                                    wrapperClassName="demo-wrapper"
                                                    editorClassName="demo-editor"
                                                    placeholder="Type welcome text here..."
                                                    toolbar={{
                                                        options: ['history', 'inline', 'blockType', 'fontSize', 'list', 'textAlign', 'link'],
                                                        inline: { inDropdown: true },
                                                        list: { inDropdown: false },
                                                        textAlign: { inDropdown: false },
                                                        link: { inDropdown: false },
                                                        history: { inDropdown: false },
                                                    }}
                                                    
                                                    ref={editorRef}
                                                    // readOnly={true} 
                                                />
                                            </Grid>
                                            <Grid xs={12} spacing={2} className='saveCancelDiv'>

                                                <Button
                                                    variant="contained"
                                                    aria-label="Save"
                                                    type="submit"
                                                    disabled={subjectError || disabledflag}
                                                    onClick={smsEmailSave}
                                                    className="Savebtn">
                                                    Save
                                                </Button>

                                            </Grid>
                                        </Grid>
                                    )}
                                    
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <UpdateSurveyName 
                        childModalopen={childModalopen} 
                        setChildModalOpen={setChildModalOpen} 
                        surveyName={surveyName} 
                        updatedSurveyName={handleupdatedSurveyName} 
                        campaignType="survey" 
                        surveyUpdateVariant={surveyUpdateVariant} 
                        namesArray={surveyArray}/>
                </Box>

            </Modal>
        </div>
    )

}

export default SurveyPreviewPopup