import React, { useState, useRef, useEffect } from "react";
import "./AutoResponsePopup.css";
import Modal from "@mui/material/Modal";

import { TextField, MenuItem, Box, Button, Grid } from "@mui/material";

import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
// import { convertToRaw } from 'draft-js';
import { stateToHTML } from "draft-js-export-html";

function AutoResponsePopup({
	isOpen,
	handleOKButton,
	campaignCode,
	autoresponseapidata,
}) {
	const [focusStates, setFocusStates] = useState({
		promotionName: false,
		promotionDescription: false,
		question: false,
	});

	const [language, setLanguage] = useState("en");
	const [emailSubject, setEmailSubject] = useState("");
	const [emailBody, setEmailBody] = useState("");
	const [messageText, setMessageText] = useState("");

	const [result, setResult] = useState({
		sms: {
			body: {},
		},
		email: {
			subject: {},
			body: {},
		},
	});

	const [previousValue, setPreviousValue] = useState("");

	const [messageTextError, setMessageTextError] = useState("");
	const [subjectError, setSubjectError] = useState("");
	const [bodyTextError, setBodyTextError] = useState("");

	const editorRef = useRef(null);

	// Event handler for focus
	const handleFocus = (fieldName, value) => {
		setPreviousValue(value);
		setFocusStates({ ...focusStates, [fieldName]: true });
	};

	// Event handler for blur
	const handleBlur = (fieldName) => {
		console.log("Previous value:", previousValue);

		setFocusStates({ ...focusStates, [fieldName]: false });
	};

	const messageTextChange = (event) => {
		setMessageText(event.target.value);
		if (event.target.value === "")
			return setMessageTextError("SMS cannot be empty");
		setMessageTextError("");
	};

	const emailSubjectChange = (event) => {
		setEmailSubject(event.target.value);
		if (event.target.value === "")
			return setSubjectError("Subject cannot be empty");
		setSubjectError("");
	};

	function handleClickcancel(data) {
		// Pass the value to the handleOK function of main component from wheere the popup was called
		handleOKButton(result);
	}

	const languageChange = (event) => {
		setLanguage(event.target.value);
		setMessageText(result.sms.body[event.target.value]);
		setEmailSubject(result.email.subject[event.target.value]);
		setEmailBody(
			convertHtmlToEditorState(result.email.body[event.target.value])
		);
	};

	const handleEmailBodyTextChange = (value) => {
		setEmailBody(value);
		const currentContent = value.getCurrentContent();
		if (currentContent.hasText() === false)
			return setBodyTextError("Body cannot be empty");

		setBodyTextError("");
	};

	const convertHtmlToEditorState = (html) => {
		const blocksFromHTML = convertFromHTML(html);
		const contentState = ContentState.createFromBlockArray(
			blocksFromHTML.contentBlocks,
			blocksFromHTML.entityMap
		);
		return EditorState.createWithContent(contentState);
	};

	useEffect(() => {
		if (isOpen && autoresponseapidata) {
			const jsonString = JSON.stringify(autoresponseapidata);
			const response = JSON.parse(jsonString);
			setResult(response);
			setMessageText(response.sms.body[language]);
			setEmailSubject(response.email.subject[language]);
			setEmailBody(
				convertHtmlToEditorState(response.email.body[language])
			);
		}
	}, [isOpen, autoresponseapidata, language]);

	const handleClicksave = () => {
		result.sms.body[language] = messageText;
		result.email.subject[language] = emailSubject;
		result.email.body[language] = stateToHTML(
			emailBody.getCurrentContent()
		);
		handleClickcancel();
	};

	return (
		<div>
			<Modal
				open={isOpen}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box className={"autoResponsePopupcontainer"}>
					<Grid container>
						<Grid item xs={12} className={"GridIcon"}>
							<img
								src="/asset/Member/cancelicon.png"
								alt="cancelIcon"
								width="15px"
								height="15px"
								onClick={handleClickcancel}
							/>
						</Grid>

						<Grid item xs={12} className={"Gridtext"}>
							<Grid container className={"accordian"}>
								<Grid item xs={12}>
									<Grid
										item
										xs={12}
										className="surveyQuestionDiv"
									>
										<Grid item xs={10}>
											<h3 className="surveyText">
												Auto-response
											</h3>
										</Grid>
										<Grid item xs={2}>
											<TextField
												className={"textField"}
												fullWidth
												select
												variant="outlined"
												value={language}
												onChange={languageChange}
											>
												<MenuItem value="en">
													English
												</MenuItem>
												{/* <MenuItem value="es">
													Spanish
												</MenuItem>
												<MenuItem value="vi">
													Vietnamese
												</MenuItem> */}
											</TextField>
										</Grid>
										<Grid item xs={12} className="descDiv">
											<div className="label_text">
												SMS
											</div>
											<TextField
												className={"textField"}
												id="outlined-multiline-flexible"
												multiline
												value={messageText}
												rows={2}
												onChange={messageTextChange}
												onFocus={handleFocus}
												onBlur={handleBlur}
												fullWidth
												variant="outlined"
												placeholder="Enter your text here..."
											/>
											{messageTextError && (
												<span className="errorMsg">
													{messageTextError}
												</span>
											)}
										</Grid>

										<Grid item xs={12} className="descDiv">
											<div className="label_text_email">
												Email
											</div>
											<div className="sub_label_text">
												Subject
											</div>
											<TextField
												className={"textField"}
												value={emailSubject}
												onChange={emailSubjectChange}
												onFocus={handleFocus}
												onBlur={handleBlur}
												id="outlined-basic"
												variant="outlined"
												fullWidth
												placeholder="Subject"
											/>
											{subjectError && (
												<span className="errorMsg">
													{subjectError}
												</span>
											)}
										</Grid>
										<div className="body_text_label">
											Body Text
										</div>
										<Grid
											item
											xs={12}
											className="richTextEditor"
										>
											<Editor
												editorState={emailBody}
												onEditorStateChange={
													handleEmailBodyTextChange
												}
												wrapperClassName="demo-wrapper"
												editorClassName="demo-editor"
												placeholder="Type welcome text here..."
												toolbar={{
													options: [
														"history",
														"inline",
														"blockType",
														"fontSize",
														"list",
														"textAlign",
														"link",
													],
													inline: {
														inDropdown: true,
													},
													list: { inDropdown: false },
													textAlign: {
														inDropdown: false,
													},
													link: { inDropdown: false },
													history: {
														inDropdown: false,
													},
												}}
												ref={editorRef}
											/>
										</Grid>
										{bodyTextError && (
											<span className="errorMsg">
												{bodyTextError}
											</span>
										)}
									</Grid>
									<Grid
										item
										xs={12}
										className="saveCancelDiv"
									>
										<Button
											variant="contained"
											aria-label="Cancel"
											type="submit"
											className="Cancelbtn"
											onClick={handleClickcancel}
										>
											Cancel
										</Button>
										<Button
											onClick={handleClicksave}
											variant="contained"
											aria-label="Save"
											type="submit"
											className={
												emailBody === "" ||
												emailSubject === "" ||
												messageText === ""
													? "disablesavebn"
													: "Savebtn"
											}
										>
											Save
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Modal>
		</div>
	);
}

export default AutoResponsePopup;
