import React from "react";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import SingleSelectionTemplate from "./SingleSelectionTemplate/SingleSelectionTemplate";
import MultiSelectionTemplate from "./MultiSelectionTemplate/MultiSelectionTemplate";
import Apiservice from "../../../Services/Apiservice";
import { URL } from "../../../Constants/apiUrl";
import { laguageCodes, languageId, surveyOptionType } from "../../../Constants/static";
import CustomScreen from "../../../Components/BlankScreenHandle/BlankScreen";
import CommoncircularProgress from "../../../Shared/CommoncircularProgress/CommoncircularProgress";
import FinalQuestionTemplate from "./FinalQuestionTemplate/FinalQuestionTemplate";
import FinalQuestionTemplatePromotion from "./FinalQuestionTemplatePromotion/FinalQuestionTemplatePromotion";
import InputOnlyTemplate from "./InputOnlyTemplate/InputOnlyTemplate";
import EmptyTemplate from "./EmptyTemplate/EmptyTemplate";
import InputwithcalenderTemplate from "./InputwithcalenderTemplate/InputwithcalenderTemplate";
import CustomLanguageservice from "../../../Services/CustomLanguageservice";


function SurveyTemplate() {
	const params = useParams();
	const Navigate = useNavigate();
	const [SurveyquestionData, setSurveyquestionData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [surveyname, setSurveyname] = useState("");
	const [autheticateddata, setautheticateddata] = useState(true);

	const isBrowser = () => typeof window !== "undefined";
	const member_id = localStorage.getItem("Survey_member_id")
	const campaign_id = localStorage.getItem("Survey_campaign_id")
	const language_code = localStorage.getItem("Survey_language_code")
	const Survey_reference = localStorage.getItem("Survey_reference")
	const Survey_reference_order = localStorage.getItem("Survey_reference_order")
	let member_data = localStorage.getItem("member_data");

	let language

	if (isBrowser() === true) {
		language = languageId[language_code]
	}


	let Next = CustomLanguageservice.getMethod("Next", language)
	let Done = CustomLanguageservice.getMethod("Done", language)
	let IDontKnow = CustomLanguageservice.getMethod("I Don't Know", language)
	let the = CustomLanguageservice.getMethod("the", language)
	let The = CustomLanguageservice.getMethod("The", language)

	let agent = CustomLanguageservice.getMethod("agent", language)

	let yourprimarycarephysician = CustomLanguageservice.getMethod("your primary care physician", language)
	let Yourprimarycarephysician = CustomLanguageservice.getMethod("Your primary care physician", language)
	let clinic = CustomLanguageservice.getMethod("the clinic or hospital", language)
	let yourhealthplanbroker = CustomLanguageservice.getMethod("your health-plan broker", language)
	let yourhealthplan = CustomLanguageservice.getMethod("your health plan", language)
	let Yourhealthplan = CustomLanguageservice.getMethod("Your health plan", language)

	const replacingVarfunction = () => {
		let member_data_parsed = JSON.parse(member_data)
		let data = {
			"[member_name]": member_data_parsed.first_name,
			"Dr. [doctor_name]": "Dr. " + member_data_parsed.doctor_name,
			"[doctor_name]": yourprimarycarephysician,
			"[Doctor_name]": Yourprimarycarephysician,
			"[agent_name]": member_data_parsed.agent_first_name !== "" ? the + " " + agent + " " + member_data_parsed.agent_first_name : yourhealthplanbroker,
			"[Agent_name]": member_data_parsed.agent_first_name !== "" ? The + " " + agent + " " + member_data_parsed.agent_first_name : yourhealthplanbroker,
			"a [payer_name]": yourhealthplan,
			"[payer]": yourhealthplan,
			"[payer_name]": yourhealthplan,
			"[Payer_name]": Yourhealthplan,
			"[start_date]": member_data_parsed.start_date,
			"[plan_name] plan": member_data_parsed.plan,
			"[plan_name]": member_data_parsed.plan,
			"[facility_name]": member_data_parsed.provider_name,
			"[provider_name]": member_data_parsed.provider_name !== "" ? member_data_parsed.provider_name : clinic,
			"[appointment_date]": member_data_parsed.appointment_date
		}
		return data;
	};



	useEffect(() => {
		// if already completed survey redirect to welcome screen
		let surveycompleted = localStorage.getItem("surveycompleted");
		if (surveycompleted === "true") {
			let survey_pathname = localStorage.getItem("survey_pathname")
			Navigate(survey_pathname)
		}

		else {
			// if data not there show error screen
			if (member_data === "" || member_data === undefined || member_data === null) {
				setautheticateddata(false)
			}
			else {
				setautheticateddata(true)

				setIsLoading(true)
				let FirstQnPayload = {
					"member_id": member_id,
					"language_code": language_code,
					"campaign_id": campaign_id,
					"reference": Survey_reference,
					"reference_order": Survey_reference_order
				}


				Apiservice.postMethod(URL.SURVEY_FIRST_QUESTION, FirstQnPayload).then((response) => {
					setIsLoading(false);

					if(response.data.data.answer_type_id ===surveyOptionType.PROMOTION_TYPE){
						let replacingVar = replacingVarfunction();
					for (const [key, value] of Object.entries(replacingVar)) {
						laguageCodes.forEach(code => {
							if (response.data.data.promotion_header[code].includes(key)) {
								response.data.data.promotion_header[code] = response.data.data.promotion_header[code].replace(key, value);
							}
							if (response.data.data.promotion_information[code].includes(key)) {
								response.data.data.promotion_information[code] = response.data.data.promotion_information[code].replace(key, value);
							}
						});
					}
					}

					else{
					let replacingVar = replacingVarfunction();
					for (const [key, value] of Object.entries(replacingVar)) {
						laguageCodes.forEach(code => {
							if (response.data.data.question[code].includes(key)) {
								response.data.data.question[code] = response.data.data.question[code].replace(key, value);
							}
						});
					}
				}

					console.log(response.data.data, "Data");
					setSurveyquestionData(response.data.data)
				})
					.catch(error => {
						setIsLoading(false);
					})
			}

		}
	}, []);

	const NextQuestion = (NextQnPayload) => {
		setIsLoading(true)
		Apiservice.postMethod(URL.SURVEY_NEXT_QUESTION, NextQnPayload).then((response) => {
			setIsLoading(false)
			setSurveyname("")
			let replacingVar = replacingVarfunction();
			for (const [key, value] of Object.entries(replacingVar)) {
				laguageCodes.forEach(code => {
					if (response.data.data.question[code].includes(key)) {
						response.data.data.question[code] = response.data.data.question[code].replace(key, value);
					}
				});
			}
			setSurveyquestionData(response.data.data)
		})
			.catch(error => {
				setIsLoading(false);
			})
	};

	const BackTracking = (PreviousQnPayload) => {

		setIsLoading(true)
		Apiservice.postMethod(URL.SURVEY_BACK_QUESTION, PreviousQnPayload).then((response) => {
			setIsLoading(false)

			setSurveyname("")

			const replacingVar = replacingVarfunction();
			for (const [key, value] of Object.entries(replacingVar)) {
				laguageCodes.forEach(code => {
					if (response.data.data.question[code].includes(key)) {
						response.data.data.question[code] = response.data.data.question[code].replace(key, value);
					}
				});
			}
			setSurveyquestionData(response.data.data)
		})
			.catch(error => {
				setIsLoading(false);
			})
	}

	return (
		<div>
			{autheticateddata === true ?


				<div>
					{
						isLoading &&
						<CommoncircularProgress></CommoncircularProgress>
					}
					{
						(
							SurveyquestionData.answer_type_id === surveyOptionType.YES_NO ||
							SurveyquestionData.answer_type_id === surveyOptionType.YES_NO_INPUT ||
							SurveyquestionData.answer_type_id === surveyOptionType.SINGLE_SELECTION ||
							SurveyquestionData.answer_type_id === surveyOptionType.SINGLE_SELECTION_INPUT
						) &&
						<SingleSelectionTemplate data={SurveyquestionData} NextQuestion={NextQuestion} BackTracking={BackTracking} surveyname={surveyname} Next={Next} />
					}

					{
						(
							SurveyquestionData.answer_type_id === surveyOptionType.CHECK_ALL_THAT_APPLY ||
							SurveyquestionData.answer_type_id === surveyOptionType.CHECK_ALL_THAT_APPLY_INPUT ||
							SurveyquestionData.answer_type_id === surveyOptionType.CHECK_ALL_THAT_APPLY_COUNT
						) &&
						<MultiSelectionTemplate data={SurveyquestionData} NextQuestion={NextQuestion} BackTracking={BackTracking} surveyname={surveyname} Next={Next} />

					}
					{
						SurveyquestionData.answer_type_id === surveyOptionType.FINAL &&
						<FinalQuestionTemplate data={SurveyquestionData} surveyname={surveyname} />
					}
					{
						SurveyquestionData.answer_type_id === surveyOptionType.INPUT_ONLY &&
						<InputOnlyTemplate data={SurveyquestionData} NextQuestion={NextQuestion} BackTracking={BackTracking} surveyname={surveyname} Done={Done} IDontKnow={IDontKnow} />
					}

					{
						SurveyquestionData.answer_type_id === surveyOptionType.EDITABLE_FORM &&
						<InputwithcalenderTemplate data={SurveyquestionData} NextQuestion={NextQuestion} BackTracking={BackTracking} surveyname={surveyname} Done={Done} />
					}

					{
						SurveyquestionData.answer_type_id === surveyOptionType.EMPTY_ANSWER &&
						<EmptyTemplate data={SurveyquestionData} NextQuestion={NextQuestion} BackTracking={BackTracking} surveyname={surveyname} Next={Next} />
					}

					{
						SurveyquestionData.answer_type_id === surveyOptionType.PROMOTION_TYPE &&
						<FinalQuestionTemplatePromotion data={SurveyquestionData} NextQuestion={NextQuestion} BackTracking={BackTracking} surveyname={surveyname} Next={Next} />
					}


				</div>

				:

				<CustomScreen />
			}
		</div>
	)
}

export default SurveyTemplate;
