// import React from 'react';
import React, { useState, useRef, useEffect } from 'react';
import "./CampaignCreatorTracker.css";
import ReactDOM from 'react-dom';
import { useNavigate } from "react-router-dom";
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import HeaderAgent from "../../../Shared/HeaderAgent/HeaderAgent";
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { DataGrid } from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Bargraph from "./Bargraph/Bargraph";
import CountdownTimer from "./CountdownTimer/CountdownTimer";
import { Tooltip } from '@mui/material';
import CommoncircularProgress from "../../../Shared/CommoncircularProgress/CommoncircularProgress";
import GlobalAlertPopup from "../../../Shared/Popups/GlobalAlertPopup/GlobalAlertPopup";
import SurveyPreviewPopup from '../../../Shared/Popups/SurveyPreviewPopup/SurveyPreviewPopup';
import AutoResponsePopup from '../../../Shared/Popups/AutoResponsePopup/AutoResponsePopup';
import CreateSurveyPopup from '../../../Shared/Popups/CreateSurveyPopup/CreateSurveyPopup';
import CreatePromotionPopup from '../../../Shared/Popups/CreatePromotionPopup/CreatePromotionPopup';
import GlobalConfirmationPopup from "../../../Shared/Popups/GlobalConfirmationPopup/GlobalConfirmationPopup";
import GlobalConfirmationDeletePopup from "../../../Shared/Popups/GlobalConfirmationDeletePopup/GlobalConfirmationDeletePopup";
import GlobalSucessPopup from "../../../Shared/Popups/GlobalSucessPopup/GlobalSucessPopup";
import MemberSnapshotPopup from "../../../Shared/Popups/MemberSnapshotPopup/MemberSnapshotPopup";
import Publish_ErrorPopup from "../../../Shared/Popups/Publish_ErrorPopup/Publish_ErrorPopup";
import GlobalAlertPopup2 from "../../../Shared/Popups/GlobalAlertPopup2/GlobalAlertPopup2";
import UploadmemberListPopup from "../../../Shared/Popups/UploadmemberListPopup/UploadmemberListPopup";
import GlobalConfirmationPopuptab_change from "../../../Shared/Popups/GlobalConfirmationPopuptab_change/GlobalConfirmationPopuptab_change";
import { URL } from "../../../Constants/apiUrl";
import Apiservice from "../../../Services/Apiservice";

import { ROUTES_AGENT_ADMINISTRATORNOTIFICATIONS, ROUTES_AGENT_REPORTS } from "../../../Constants/routes";
import CampaignHistoryPopup from "../../../Shared/Popups/CampaignHistoryPopup/CampaignHistoryPopup";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";



const CustomHeader = ({ colDef, CommonAPi_tabledata, name, disabledflagdata }) => {
	const [sortingOrder, setsortingOrder] = useState("ASC");


	const handleSortClick = (order_sort) => {
		let update_sort_order;
		if (order_sort === "ASC") {
			update_sort_order = "DESC"
		}
		else {
			update_sort_order = "ASC"
		}
		setsortingOrder(update_sort_order)


		if (name === "DraftPublishedcampaign") {
			let data = sessionStorage.getItem("filterdata")
			let filterdata = JSON.parse(data);

			let api_filterdata = {
				"campaign_code": filterdata.campaign_code,
				"page_no": filterdata.page_no,
				"page_size": filterdata.page_size,
				"sort_by": colDef.field,
				"sort_order": update_sort_order,
				"campaign_filter": filterdata.campaign_filter
			}

			CommonAPi_tabledata(api_filterdata);
		}
		else {
			var storedParams = sessionStorage.getItem("filterdata");
			if (storedParams) {
				var paramsget = new URLSearchParams(storedParams);
				var params = new URLSearchParams();
				if (paramsget.get("member_name_id") !== null) {
					params.append("member_name_id", paramsget.get("member_name_id"));
				}
				params.append("campaign_code", paramsget.get("campaign_code"))
				params.append("page_size", paramsget.get("page_size"))
				params.append("page_no", paramsget.get("page_no"))
				params.append("sort_by", colDef.field)
				params.append("sort_order", update_sort_order)
				CommonAPi_tabledata(params);
			}
		};
	}

	return (
		<div className="columnclassname">
			<span className="header-content" > {colDef.headerName} &nbsp;</span>

			{/* <img src="/asset/Line 1.png" alt="Line" style={{ height: '24px' }} onClick={handleSortClick} /> */}

			<img src="/asset/Agent/sortingarrow.png" alt="Sorting" style={{
				height: '10px', cursor: "pointer",
				pointerEvents: disabledflagdata ? 'none' : 'auto',
			}} onClick={() => handleSortClick(sortingOrder)} />

		</div>
	);
};

function CampaignCreatorTracker() {
	const Navigate = useNavigate();
	const [defaultcheckedboxSelection, setdefaultcheckedboxSelection] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isPublished, setisPublished] = useState(false);
	const [isModalOpenConfirmationPopup, setisModalOpenConfirmationPopup] = useState(false);
	const [isOpen_customdrodown, setisOpen_customdrodown] = useState(false);
	const [isOpen_customdrodown_value, setisOpen_customdrodown_value] = useState("ALL");
	const [customdrodown_array, setcustomdrodown_array] = useState([]);
	const [divs, setDivs] = useState([]);
	const [isOpenoverlay_div, setisOpenoverlay_div] = useState(false);
	const [campaigncreationgCount, setcampaigncreationgCount] = useState(1);
	const [selectedTab, setSelectedTab] = useState(null);
	const divRefs = useRef([]);
	const containerRef = useRef(null);
	const [expanded, setExpanded] = useState({ panel1: true });
	const [CampaignType, setCampaignType] = React.useState('');
	const [startDob, setstartDob] = React.useState("");
	const [startTime, setstartTime] = React.useState("");
	const [endDob, setendDob] = React.useState("");
	const [endTime, setendTime] = React.useState("");
	const [AutomaticReminder, setAutomaticReminder] = React.useState('');
	const [Rows, setRows] = useState([]);
	const [Rows1, setRows1] = useState([]);
	const [Column, setColumns] = useState([]);
	const [Column1, setColumns1] = useState([]);
	const [createCampaign, setcreateCampaign] = useState(false);
	const [deleteCampaign, setdeleteCampaign] = useState(false);
	const [Campaignselectordata, setCampaignselectordata] = useState([]);
	const [Campaignselector, setCampaignselector] = React.useState("");
	const [CriticallyLevelval, setCriticallyLevelval] = React.useState("");
	const [surveyPromotionDescription, setsurveyPromotionDescription] = React.useState("");

	const [checkedSwitch1, setCheckedSwitch1] = useState(false);
	const [checkedSwitch2, setCheckedSwitch2] = useState(false);
	const [checkedSwitch3, setCheckedSwitch3] = useState(false);
	const [checkedSwitch4, setCheckedSwitch4] = useState(false);
	const [filterPlanname_array, setfilterPlanname_array] = useState([]);
	const [filterPlantype_array, setfilterPlantype_array] = useState([]);
	const [filterDesiredFrequency_array, setfilterDesiredFrequency_array] = useState([]);
	const [filtergender_array, setfiltergender_array] = useState([]);


	const [Planname_filter, setPlanname_filter] = useState("");
	const [Plantype_filter, setPlantype_filter] = useState("");
	const [DesiredFrequency_filter, setDesiredFrequency_filter] = useState("");
	const [Startdate_start_filter, setStartdate_start_filter] = useState("");
	const [Gender_filter, setGender_filter] = useState("");
	const [Zipcode_filter, setZipcode_filter] = useState("");
	const [Startdate_end_filter, setStartdate_end_filter] = useState("");
	const [DateofBirth_start_filter, setDateofBirth_start_filter] = useState("");
	const [DateofBirth_end_filter, setDateofBirth_end_filter] = useState("");
	const [MemberID_filter, setMemberID_filter] = useState("");
	const [checkbox_selectionarray, setcheckbox_selectionarray] = useState([]);
	const [is_pinned, setis_pinned] = useState([]);
	const [is_checked, setis_checked] = useState([]);

	const [isSurveyPreviewModalOpen, setIsSurveyPreviewModalOpen] = useState(false);

	const [isAutoResponseModalOpen, setIsAutoResponseModalOpen] = useState(false);
	const [isCreateSurveyModalOpen, setIsCreateSurveyModalOpen] = useState(false);
	const [isCreatePromotionModalOpen, setIsCreatePromotionModalOpen] = useState(false);

	const [currentDateTimenow, setcurrentDateTimenow] = useState("");
	const [campaignStartDateTime, setcampaignStartDateTime] = useState("");
	const [campaignEndDateTime, setcampaignEndDateTime] = useState("");
	const [notoficationoverlaydiv, setnotoficationoverlaydiv] = useState(false);

	const [page, setPage] = useState(1);
	const [totalpagecount, settotalpagecount] = useState(0);
	const [PaginationRow_items, setPaginationRow_items] = useState("10");

	const [OverlayDiv_Resolvebtnclicked, setOverlayDiv_Resolvebtnclicked] = useState(false);
	const [OverlayDiv_typecomment, setOverlayDiv_typecomment] = useState("");
	const [Dup_div, setDup_div] = useState([]);
	const [isMemberSnapshotPopupModalOpen, setisMemberSnapshotPopupModalOpen] = useState(false);

	const [storedapiresponsesarray, setstoredapiresponsesarray] = useState([]);

	const [CampaignType_Published, setCampaignType_Published] = React.useState("");
	const [startdate_Published, setstartdate_Published] = React.useState("");
	const [starttime_Published, setstarttime_Published] = React.useState("");
	const [enddate_Published, setenddate_Published] = React.useState("");
	const [endtime_Published, setendtime_Published] = React.useState("");
	const [campaignSelected_Published, setcampaignSelected_Published] = React.useState("");
	const [Description_Published, setDescription_Published] = React.useState("");
	const [Criticalllevel_Published, setCriticalllevel_Published] = React.useState("");
	const [barchartdata_Published, setbarchartdata_Published] = React.useState("");

	const [isDraft, setisDraft] = useState(false);
	const [isPreviewClicked, setisPreviewClicked] = useState(false);
	const [campaignCode, setCampaignCode] = useState('');
	const [surveyName, setSurveyName] = useState('');
	const [promotionName, setPromotionName] = useState('');
	const [reference, setReference] = useState('');
	const [referenceOrder, setReferenceOrder] = useState(0);
	const [Publish_ErrorPopupmodal, setPublish_ErrorPopupmodal] = useState(false);
	const [enddate_warningmsg, setenddate_warningmsg] = useState(false);
	const [startdate_warningmsg, setstartdate_warningmsg] = useState(false);
	const [UploadmemberListPopupmodal, setUploadmemberListPopupmodal] = useState(false);
	const [NotificationCount, setNotificationCount] = useState("");
	const [Call_apidata, seCall_apidata] = useState([]);
	const [resolvecommentid, setresolvecommentid] = useState("");

	const [getmemberID, setgetmemberID] = useState("");
	const [isModalOpenSuccessPopup, setisModalOpenSuccessPopup] = useState(false);
	const [isModalOpendeletePopup, setisModalOpendeletePopup] = useState(false);
	const [isModalOpenGlobalAlertPopup2, setisModalOpenGlobalAlertPopup2] = useState(false);
	const [isModalOpenGlobalAlertPopup2heading, setisModalOpenGlobalAlertPopup2heading] = useState("");
	const [isModalOpenGlobaltabchnage, setisModalOpenGlobaltabchnage] = useState(false);
	const [indexvalue, setindexvalue] = useState("");
	const [changesmade_afterDraft, setchangesmade_afterDraft] = useState(false);
	const [surveyarraydata, setsurveyarraydata] = useState([]);
	const [disabledflag, setdisabledflag] = useState(false);
	const [CampaignHistoryPopupmodal, setCampaignHistoryPopupmodal] = useState(false);
	const [isEditedFlag, setIsEditedFlag] = useState(false);
	const [data2publish_disableFlag, setdata2publish_disableFlag] = useState(false);
	const [apicalledgetdetails, setapicalledgetdetails] = useState(false);
	const [cancelpopup, setcancelpopup] = useState(false);
	const [deletepopup, setdeletepopup] = useState(false);
	const [autoresponseapidata, setautoresponseapidata] = useState("");
	const [tempsearchdata_value, settempsearchdata_value] = useState("");
	const [allCampaignNames, setallCampaignNames] = useState([]);

	// table data
	const columns_staticdata = [
		{ field: 'member_id', headerName: 'Member ID', flex: 1, minWidth: 140, sortable: false, renderCell: (params) => <span className="cell-content_type1" >{params.value}</span>, renderHeader: (params) => <CustomHeader {...params} CommonAPi_tabledata={CommonAPi_tabledata} name="DraftPublishedcampaign" disabledflagdata={disabledflag} /> },
		{ field: 'first_name', headerName: 'First Name', flex: 1, minWidth: 160, sortable: false, renderCell: (params) => <span className="cell-content_type1">{params.value}</span>, renderHeader: (params) => <CustomHeader {...params} CommonAPi_tabledata={CommonAPi_tabledata} name="DraftPublishedcampaign" disabledflagdata={disabledflag} /> },
		{ field: 'last_name', headerName: 'Last Name', flex: 1, minWidth: 160, sortable: false, renderCell: (params) => <span className="cell-content_type1">{params.value}</span>, renderHeader: (params) => <CustomHeader {...params} CommonAPi_tabledata={CommonAPi_tabledata} name="DraftPublishedcampaign" disabledflagdata={disabledflag} /> },
		{
			field: 'gender_code', headerName: 'Gender', flex: 1, minWidth: 130, sortable: false,
			renderCell: (params) => (
				<span>
					{
						params.row.gender_code === "MALE" &&
						<span className="cell-content">Male
						</span>
					}
					{
						params.row.gender_code === "FEMAL" &&
						<span className="cell-content">Female
						</span>
					}

					{
						params.row.gender_code === "TRANS" &&
						<span className="cell-content">Transgender
						</span>
					}
				</span>
			),
			renderHeader: (params) => <CustomHeader {...params} CommonAPi_tabledata={CommonAPi_tabledata} name="DraftPublishedcampaign" disabledflagdata={disabledflag} />
		},
		{ field: 'insurance_plan_type', headerName: 'Plan Type', flex: 1, minWidth: 130, sortable: false, renderCell: (params) => <span className="cell-content">{params.value}</span>, renderHeader: (params) => <CustomHeader {...params} CommonAPi_tabledata={CommonAPi_tabledata} name="DraftPublishedcampaign" disabledflagdata={disabledflag} /> },
		{ field: 'insurance_plan_name', headerName: 'Plan Name', flex: 1, minWidth: 180, sortable: false, renderCell: (params) => <span className="cell-content">{params.value}</span>, renderHeader: (params) => <CustomHeader {...params} CommonAPi_tabledata={CommonAPi_tabledata} name="DraftPublishedcampaign" disabledflagdata={disabledflag} /> },
		{
			field: 'frequency', headerName: 'Frequency level', width: 130, sortable: false,
			renderCell: (params) => (
				<span>
					{
						params.row.frequency === "0" &&
						<span className="cell-content">Often
						</span>
					}
					{
						params.row.frequency === "1" &&
						<span className="cell-content">Periodically
						</span>
					}

					{
						params.row.frequency === "2" &&
						<span className="cell-content">Seldom
						</span>
					}
				</span>
			),
			renderHeader: (params) => <CustomHeader {...params} CommonAPi_tabledata={CommonAPi_tabledata} name="DraftPublishedcampaign" disabledflagdata={disabledflag} />
		},
		{ field: 'active_campaign_count', headerName: 'Other active campaign', width: 140, sortable: false, renderCell: (params) => <span className="cell-content">{params.value}</span>, renderHeader: (params) => <CustomHeader {...params} CommonAPi_tabledata={CommonAPi_tabledata} name="DraftPublishedcampaign" disabledflagdata={disabledflag} /> },
		{ field: 'zipcode', headerName: 'Zipcode', flex: 1, minWidth: 130, sortable: false, renderCell: (params) => <span className="cell-content">{params.value}</span>, renderHeader: (params) => <CustomHeader {...params} CommonAPi_tabledata={CommonAPi_tabledata} name="DraftPublishedcampaign" disabledflagdata={disabledflag} /> },
	];

	const columns_staticdata1 = [
		{ field: 'member_id', headerName: 'Member ID', flex: 1, minWidth: 130, sortable: false, renderCell: (params) => <span className="cell-content_type1">{params.value}</span>, renderHeader: (params) => <CustomHeader {...params} CommonAPi_tabledata={CommonAPi_tabledata_published} name="Publishedcampaign" /> },
		{ field: 'first_name', headerName: 'First Name', flex: 1, minWidth: 160, sortable: false, renderCell: (params) => <span className="cell-content_type1">{params.value}</span>, renderHeader: (params) => <CustomHeader {...params} CommonAPi_tabledata={CommonAPi_tabledata_published} name="Publishedcampaign" /> },
		{ field: 'last_name', headerName: 'Last Name', flex: 1, minWidth: 160, sortable: false, renderCell: (params) => <span className="cell-content_type1">{params.value}</span>, renderHeader: (params) => <CustomHeader {...params} CommonAPi_tabledata={CommonAPi_tabledata_published} name="Publishedcampaign" /> },
		{ field: 'dob', headerName: 'Dob', flex: 1, minWidth: 160, sortable: false, renderCell: (params) => <span className="cell-content">{params.value}</span>, renderHeader: (params) => <CustomHeader {...params} CommonAPi_tabledata={CommonAPi_tabledata_published} name="Publishedcampaign" /> },
		{ field: 'zipcode', headerName: 'Zipcode', flex: 1, minWidth: 160, sortable: false, renderCell: (params) => <span className="cell-content">{params.value}</span>, renderHeader: (params) => <CustomHeader {...params} CommonAPi_tabledata={CommonAPi_tabledata_published} name="Publishedcampaign" /> },
		{
			field: 'status', headerName: 'Status', flex: 1, minWidth: 160, sortable: false,
			renderCell: (params) => (
				<span className="cell-content" >
					{params.row.status === 1 &&
						<div className="statuscampaignTrackertext">
							Invitation received
						</div>

					}

					{params.row.status === 2 &&
						<div className="statuscampaignTrackertext">
							Invite link followed
						</div>

					}

					{params.row.status === 3 &&
						<div className="statuscampaignTrackertext">
							Survey completed
						</div>
					}
					{params.row.status === 0 &&


						<div className="statuscampaignTrackertext">
							Link not Shared
						</div>
					}
				</span>

			),
			renderHeader: (params) => <CustomHeader {...params} CommonAPi_tabledata={CommonAPi_tabledata_published} />
		},
		{ field: 'invitation_reminder', headerName: 'Reminders sent', flex: 1, minWidth: 160, sortable: false, renderCell: (params) => <span className="cell-content">{params.value}</span>, renderHeader: (params) => <CustomHeader {...params} CommonAPi_tabledata={CommonAPi_tabledata_published} /> },

	]

	let paginationitem = [
		{
			id: 10,
			value: "10",
		},
		{
			id: 25,
			value: "25",
		},
		{
			id: 50,
			value: "50",
		},
		{
			id: 75,
			value: "75",
		},
		{
			id: 100,
			value: "100",
		},
	]
	const [PaginationRowsarray, setPaginationRowsarray] = useState(paginationitem);

	const handlecancelUploadmemberListButton = (value) => {
		setUploadmemberListPopupmodal(false);
		if (value !== "cancel") {
			window.location.reload();
		}

	}


	const handlecancelButton = (value) => {
		setPublish_ErrorPopupmodal(false);
	};

	const handleEditButton = (value) => {
		setPublish_ErrorPopupmodal(false);
		handlePreviewClick();
	};

	const handleOKButton_GlobalAlertPopup2 = (value) => {
		setisModalOpenGlobalAlertPopup2(false);
	}



	const handleOKButton = (value) => {
		setIsModalOpen(false);
		setenddate_warningmsg(false);
		setstartdate_warningmsg(false);
	};

	const handleOKButton_successPopup = (value) => {
		setisModalOpenSuccessPopup(false)
		window.location.reload();
	}

	const handleMemberSnapshotOKButton = (value) => {
		setisMemberSnapshotPopupModalOpen(false);
	}

	const handleSurveyPreviewOKButton = (data1, data2, code, manualPayload) => {
		sessionStorage.setItem("setCampaignselector", code)

		const hasTrueValue = Object.values(data2).includes(true);
		if (hasTrueValue) {
			setdata2publish_disableFlag(true)
		}
		else {
			setdata2publish_disableFlag(false)
		}

		let surveyCreationData = manualPayload
		let payload

		payload = JSON.parse(surveyCreationData);
		let is_draft;
		let is_edited;
		let is_master;
		let modified_date = dayjs().format('MM/DD/YYYY hh:mm A');
		if (surveyarraydata.length > 0) {
			is_draft = surveyarraydata[0].is_draft;
			is_edited = surveyarraydata[0].is_edited;
			is_master = surveyarraydata[0].is_master;
			modified_date = surveyarraydata[0].modified_date;
		}

		if (payload.survey_name != "") {

			const newSurvey = {
				"campaign_code": "Abc_survey",
				"is_draft": is_draft,
				"is_edited": is_edited,
				"is_master": is_master,
				"modified_date": modified_date, // Set current date and time
				"reference": "", // Set appropriate reference if needed
				"reference_order": 0, // Set the appropriate order
				"survey_code": Date.now().toString(), // Generate or set a survey_code if needed
				"survey_desc": payload.survey_desc, // Set a description if needed
				"survey_name": payload.survey_name
			};

			// Filter out all items with the campaign_code "Abc_pro"
			const filteredSurveyArray = Campaignselectordata.filter(
				item => item.campaign_code !== "Abc_survey"
			);

			// Append the new survey to the existing array
			const updatedSurveyArray = [...filteredSurveyArray, newSurvey];
			// Update the state with the new array
			setCampaignselectordata(updatedSurveyArray);
			setCampaignselector(newSurvey.survey_code);
			setReference(payload.reference);
			setsurveyPromotionDescription(newSurvey.survey_desc)

			// change the tab name if survey_name is changed
			setDivs((prevDivs) => {
				const newDivs = prevDivs.map((div, i) =>
					i === selectedTab ? { ...div, campaign_name: payload.survey_name } : div
				);
				return newDivs;
			});
		}

		setIsSurveyPreviewModalOpen(false);


	};

	const handleAutoResponseOKButton = (data) => {
		setIsAutoResponseModalOpen(false)
		const jsonString = JSON.stringify(data);
		const data1 = JSON.parse(jsonString);
		setautoresponseapidata(data1)
	};

	const handleCreateSurveyOKButton = (data1, data2, code, manualPayload) => {

		if (code === "create_new"){
			sessionStorage.setItem("setCampaignselector", "")
		}
		else{
			sessionStorage.setItem("setCampaignselector", code)
		}

		const hasTrueValue = Object.values(data2).includes(true);
		if (hasTrueValue) {
			setdata2publish_disableFlag(true)
		}
		else {
			setdata2publish_disableFlag(false)
		}

		let surveyCreationData = manualPayload
		let payload

		payload = JSON.parse(surveyCreationData)
		setIsCreateSurveyModalOpen(false);
		if (payload.survey_name != "") {

			const newSurvey = {
				"campaign_code": "Abc_survey",
				"is_draft": true,
				"is_master": false,
				"modified_date": dayjs().format('MM/DD/YYYY hh:mm A'), // Set current date and time
				"reference": "", // Set appropriate reference if needed
				"reference_order": 0, // Set the appropriate order
				"survey_code": Date.now(), // Generate or set a survey_code if needed
				"survey_desc": payload.survey_desc, // Set a description if needed
				"survey_name": payload.survey_name
			};

			// Filter out all items with the campaign_code "Abc_pro"
			const filteredSurveyArray = Campaignselectordata.filter(
				item => item.campaign_code !== "Abc_survey"
			);

			// Append the new survey to the existing array
			const updatedSurveyArray = [...filteredSurveyArray, newSurvey];
			// Update the state with the new array
			setCampaignselectordata(updatedSurveyArray);
			setCampaignselector(newSurvey.survey_code);
			setsurveyPromotionDescription(newSurvey.survey_desc)
			setReference(payload.reference);

			setCriticallyLevelval("1");

			// change the tab name if survey_name is changed
			setDivs((prevDivs) => {
				const newDivs = prevDivs.map((div, i) =>
					i === selectedTab ? { ...div, campaign_name: payload.survey_name } : div
				);
				return newDivs;
			});

		}
		else {
			setCampaignselector("");
		}


	};

	const handleCreatePromotionOKButton = (data1, data2, code, manualPayload) => {
		sessionStorage.setItem("setCampaignselector", code)

		const hasTrueValue = Object.values(data2).includes(true);
		if (hasTrueValue) {
			setdata2publish_disableFlag(true)
		}
		else {
			setdata2publish_disableFlag(false)
		}
		let promotionCreationData = manualPayload
		let payload

		payload = JSON.parse(promotionCreationData);
		setIsCreatePromotionModalOpen(false)
		let is_draft;
		let is_edited;
		let is_master;
		let modified_date = dayjs().format('MM/DD/YYYY hh:mm A');
		if (surveyarraydata.length > 0) {
			is_draft = surveyarraydata[0].is_draft;
			is_edited = surveyarraydata[0].is_edited;
			is_master = surveyarraydata[0].is_master;
			modified_date = surveyarraydata[0].modified_date;
		}

		if (payload.promotion_name != "") {
			const newSurvey =
			{
				"campaign_code": "Abc_pro",
				"is_draft": is_draft,
				"is_edited": is_edited,
				"is_master": is_master,
				"modified_date": modified_date,
				"promotion_code": Date.now().toString(),
				"promotion_desc": payload.promotion_desc,
				"promotion_name": payload.promotion_name
			}

			// Filter out all items with the campaign_code "Abc_pro"
			const filteredSurveyArray = Campaignselectordata.filter(
				item => item.campaign_code !== "Abc_pro"
			);


			// Append the new survey to the existing array
			const updatedSurveyArray = [...filteredSurveyArray, newSurvey];
			// Update the state with the new array
			setCampaignselectordata(updatedSurveyArray);
			setCampaignselector(newSurvey.promotion_code);

			setReference(payload.reference);
			setsurveyPromotionDescription(newSurvey.promotion_desc);
			setCriticallyLevelval("1");

			// change the tab name if survey_name is changed
			setDivs((prevDivs) => {
				const newDivs = prevDivs.map((div, i) =>
					i === selectedTab ? { ...div, campaign_name: payload.promotion_name } : div
				);
				return newDivs;
			});
		}
		else {
			setCampaignselector("");
		}

	};

	const handleIsUpdated = (value) => {
		setisPreviewClicked(value)
	};

	const handlePreviewClick = () => {
		if (CampaignType === "Survey") {
			if (isDraft && !campaignCode) {
				setIsCreateSurveyModalOpen(true)
			} else {
				setIsSurveyPreviewModalOpen(true);

			}
		} else if (CampaignType === "Promotion") {
			setIsCreatePromotionModalOpen(true)
		}

		if (divs[selectedTab].is_draft === "") {
			setdisabledflag(false);
		}
		else if (divs[selectedTab].is_draft === true) {
			if (disabledflag === true) {
				setchangesmade_afterDraft(false);
			}
			else {
				setchangesmade_afterDraft(true);
			}

		}
		else {
			setdisabledflag(true);
		}



	}

	const handleAutoResponseClick = () => {
		setchangesmade_afterDraft(true);
		setIsAutoResponseModalOpen(true)

	}
	const handleYesButton = (value) => {
		setisModalOpenConfirmationPopup(false);
		apicallDraft_Published("publish", selectedTab);
	};

	const handleNoButton = (value) => {
		setisModalOpenConfirmationPopup(false);

	};

	const handleYesButton_tabchange = (value) => {
		setisModalOpenGlobaltabchnage(false);
		// if new campaign is clicked and then on refresh, setselected ito previous since, on data refresh new data is gone
		if (divs[indexvalue].is_draft === "") {
			handle_Draft_btn(selectedTab)
		}
		else {
			handle_Draft_btn(indexvalue)
		}

	};

	const handleNoButton_tabchange = (value) => {
		setisModalOpenGlobaltabchnage(false);
		commonfunction_loadcampaign(indexvalue, divs);
		setchangesmade_afterDraft(false);

	};



	const handleYesButton_delete = (value) => {
		setisModalOpendeletePopup(false);
		Deletefunction();
	};

	const handleNoButton_delete = (value) => {
		setisModalOpendeletePopup(false);
		setdeletepopup(false);
	};

	const handleYesButton_cancel = (value) => {
		setisModalOpendeletePopup(false);
		cancelfunction();
	};

	const handleNoButton_cancel = (value) => {
		setisModalOpendeletePopup(false);
		setcancelpopup(false);

	};


	useEffect(() => {
		localStorage.setItem("Preferredlanguage", "1")
		localStorage.removeItem('surveyCreation');
		localStorage.removeItem('promotionCreation');
		localStorage.removeItem('Survey_reference');
		sessionStorage.removeItem('from_history_to_adminnotifications');
		sessionStorage.removeItem("from_history_to_generate_report");


		onloadFunction()
	}, [])


	const onloadFunction = () => {
		// get api data on load
		CommonAPi_getfilterdropdownvalues();
		setIsLoading(true);
		let apiurl = URL.AGENT_CAMPAIGNS;

		Apiservice.getMethod(apiurl).then(response => {
			setIsLoading(false);
			let newDiv = response.data.data.map(item => ({ ...item, original_name: item.campaign_name, isEditing: false }));
			setallCampaignNames(response.data.all_campaign_names)

			// if no data in DB by default open one tab
			if (newDiv.length === 0) {
				handle_addNewCampaign();
			}
			else {
				if (newDiv.length >= 19) {
					setIsModalOpen(true);
				}
				else {
					setDivs(newDiv);
					divRefs.current.push(React.createRef());

					let tab_campaigncode = sessionStorage.getItem("tabvalue_campaign_code");
					if (tab_campaigncode === "" || tab_campaigncode === null || tab_campaigncode === undefined) {
						setSelectedTab(0)
						handleSingleClick(0, newDiv);
					}
					else {
						// Find the index where the campaign_code matches
						const matchedIndex = newDiv.findIndex(item => item.campaign_code === tab_campaigncode);
						if (matchedIndex !== -1) {
							// If a match is found, set the tab and handle the click
							setSelectedTab(matchedIndex);
							handleSingleClick(matchedIndex, newDiv);
						} else {
							// You can set a default tab or return early if needed
							setSelectedTab(0);
							handleSingleClick(0, newDiv);
						}
					}

				}
			}
		})
			.catch(error => {
				setIsLoading(false);
				console.error('There was an error!', error);
			})
	}


	// on btn click add a new campaign
	const handle_addNewCampaign = () => {
		localStorage.removeItem('surveyCreation');
		localStorage.removeItem('promotionCreation');
		localStorage.removeItem('Survey_reference');

		const hasEmptyDraft = divs.some(item => item.is_draft === "");
		if (hasEmptyDraft === true) {
			setisModalOpenGlobalAlertPopup2(true);
			setisModalOpenGlobalAlertPopup2heading("You cannot create a new campaign until the current campaign has been successfully created")
		}
		else {


			// limit the user to create only 18 tabs
			if (divs.length >= 18) {
				setIsModalOpen(true);
			}
			else {
				let Agent_Email = localStorage.getItem("Agent_Email");
				const newDiv = {
					campaign_code: Date.now(),
					campaign_name: 'Campaign Name ' + campaigncreationgCount.toString().padStart(2, "0"),
					campaign_type: false,
					is_draft: "",
					isEditing: false,
					agent_email: Agent_Email,
					original_name: 'Campaign Name ' + (divs.length + 1).toString().padStart(2, "0"),

				};
				setcampaigncreationgCount(campaigncreationgCount + 1);
				const newDivs = [...divs, newDiv];
				setDivs(newDivs);
				divRefs.current.push(React.createRef());
				handleSingleClick(newDivs.length - 1, newDivs); // Select the new tab
			}
		}
	};



	// on double click edit the text
	const handleDoubleClick = (index) => {
		if (isPublished === false && disabledflag === false) {
			setDivs(divs.map((div, i) => (i === index ? { ...div, isEditing: true } : div)));

			// Use setTimeout to ensure the input field is rendered before focusing
			setTimeout(() => {
				const inputField = document.getElementById("editingtextID");
				if (inputField) {
					inputField.focus();
					inputField.setSelectionRange(inputField.value.length, inputField.value.length);
				}
			}, 0);
		}


	};


	const handleInputChange = (index, event) => {
		const newText = event.target.value;
		const regex = /^[a-zA-Z0-9-_.,\s]*$/;
		if (newText.length <= 64 && regex.test(newText)) {
			setDivs(divs.map((div, i) => (i === index ? { ...div, campaign_name: newText } : div)));
			if (event.key === 'Enter') {
				setDivs(divs.map((div, i) => (i === index ? { ...div, isEditing: false } : div)));
			}
		}
	};


	const handleOutsideClick = (event, index) => {
		const overlayDiv = document.getElementById("OverlayDivID");
		if (overlayDiv && overlayDiv.contains(event.target) || event.target.id === "OverlayDivID") {
			setisOpenoverlay_div(true)
		}
		else {
			if (isOpenoverlay_div === true) {
				setisOpen_customdrodown_value("ALL");
				setisOpenoverlay_div(false)
			}
		}


		if (divRefs.current[index] && !divRefs.current[index].current.contains(event.target)) {
			setDivs(divs.map((div, i) => (i === index ? { ...div, isEditing: false } : div)));
		}
	};

	// on click open the tab, call api and load values
	const handleSingleClick = (index, apidata) => {
		let customdropdowndta = [
			{
				id: "All",
				value: "All",
			},
			{
				id: "Campaign Creator",
				value: "Campaign Creator",
			},
			{
				id: "Campaign tracker",
				value: "Campaign tracker",
			},
		]
		setcustomdrodown_array(customdropdowndta)
		// check if clicked same or different tab
		if (selectedTab !== index) {
			// for initial load set selected tab = 0;
			let tabindex;
			if (selectedTab === null) {
				tabindex = 0;
			}
			else {
				tabindex = selectedTab;
			}
			// check if previously selected tab was draft or published
			if (apidata[tabindex].is_draft === true && changesmade_afterDraft === true) {
				setindexvalue(index)
				setisModalOpenGlobaltabchnage(true)
			}
			else {
				setchangesmade_afterDraft(false);
				commonfunction_loadcampaign(index, apidata)


			}
		}


	};


	const commonfunction_loadcampaign = (index, apidata) => {
		setsurveyarraydata([])
		localStorage.removeItem('Survey_reference');
		localStorage.removeItem('surveyCreation');
		localStorage.removeItem('promotionCreation');

		setapicalledgetdetails(true);
		// if first time clicked (not draft or published)

		// set to oriinal campaign name if not save
		if (apidata[index].is_draft === "") {
			setDivs((prevDivs) => {
				const newDivs = prevDivs.map((div, i) =>
					i === index ? { ...div, campaign_name: div.original_name } : div
				);
				return newDivs;
			});
			setCampaignCode("")
			commonautoresponseapicall(URL.GET_AUTORESPONSE);


			setIsEditedFlag(false);
			setdisabledflag(false);
			// campaign not created so display the create cancel button
			setisPublished(false)
			setdeleteCampaign(false);
			setcreateCampaign(false);
			setExpanded({ panel1: true })


			handleChange_CampaignType("", "Survey", "");
			setstartDob(dayjs().add(1, 'day').format('MM/DD/YYYY'));
			setendDob(dayjs().add(4, 'week').format('MM/DD/YYYY'));
			let apistarttime = '09:00 AM';
			let apiendtime = '09:00 AM';

			// Format the parsed time to extract only the time part
			const parsedstartTime = dayjs(`05/22/1961 ${apistarttime}`, 'MM-DD-YYYY hh:mm A');
			const formattedstartTime = parsedstartTime.format('hh:mm A');

			const parsedendTime = dayjs(`05/22/1961 ${apiendtime}`, 'MM-DD-YYYY hh:mm A');
			const formattedendTime = parsedendTime.format('hh:mm A');

			setstartTime(formattedstartTime)
			setendTime(formattedendTime)
			setCriticallyLevelval("");
			setAutomaticReminder("Off");

			setCheckedSwitch1(true);
			setCheckedSwitch2(false);
			setCheckedSwitch3(false);
			setCheckedSwitch4(true);

			setPlanname_filter("All");
			setPlantype_filter("All");
			setGender_filter("All");
			setDesiredFrequency_filter("All");
			setZipcode_filter("");

			setStartdate_start_filter(dayjs().startOf('year').format('MM/DD/YYYY'))
			setStartdate_end_filter(dayjs().add(1, 'year').startOf('year').format('MM/DD/YYYY'));
			setDateofBirth_start_filter(dayjs().subtract(120, 'year').format('MM/DD/YYYY'));
			setDateofBirth_end_filter(dayjs().subtract(65, 'year').format('MM/DD/YYYY'));
		}
		else {
			sessionStorage.setItem("tabvalue_campaign_code", apidata[index].campaign_code)
			setPage(1);
			setPaginationRow_items("10");
			// call get api to get details only if its draft or published
			setIsLoading(true);
			let apiurl = URL.CAMPAIGN_DETAILS + "/" + apidata[index].campaign_code;

			if (apidata[index].is_draft === true) {

				// check the agent specific click or edit mode
				let Agent_Email = localStorage.getItem("Agent_Email");
				let api_Agent_Email = apidata[index].agent_email;
				if (Agent_Email.toLowerCase() !== api_Agent_Email.toLowerCase()) {
					setdisabledflag(true);
				}
				else {
					setdisabledflag(false);
				}

				setisPublished(false)
				setdeleteCampaign(false);
				setcreateCampaign(true);
				setExpanded({ panel1: true, panel2: true })


				Apiservice.getMethod(apiurl).then(response => {

					setIsLoading(false);
					let surveyapi_data;
					let surveyarray
					if (response.data.data.campaign_type === false) {
						surveyapi_data = "Survey";
						surveyarray = response.data.data.survey;
						setsurveyarraydata(response.data.data.survey)
						setSurveyName(response.data.data.survey[0].survey_name)

						// FIXME: for propmotion take referenece


						setReference(response.data.data.survey[0].reference)
						setReferenceOrder(response.data.data.survey[0].reference_order)
						setCampaignCode(response.data.data.survey[0].campaign_code)
						setIsEditedFlag(response.data.data.survey[0].is_edited)
						// for genericsurvey
						setisDraft(!response.data.data.survey[0].is_master && !response.data.data.survey[0].reference)
						commonautoresponseapicall(URL.GET_AUTORESPONSE + "/" + response.data.data.survey[0].campaign_code);
					}
					else {
						surveyarray = response.data.data.promotion;
						setsurveyarraydata(response.data.data.promotion)
						surveyapi_data = "Promotion";
						// for genericsurvey
						setisDraft(!response.data.data.promotion[0].is_master && !response.data.data.promotion[0].reference)
						setPromotionName(response.data.data.promotion[0].promotion_name)
						setReference(response.data.data.promotion[0].reference)

						setIsEditedFlag(response.data.data.promotion[0].is_edited)
						commonautoresponseapicall(URL.GET_AUTORESPONSE + "/" + response.data.data.promotion[0].campaign_code);
					}

					handleChange_CampaignType("", surveyapi_data, surveyarray)
					setCriticallyLevelval(response.data.data.criticality_level);

					if (response.data.data.is_auto_reminder === false) {
						setAutomaticReminder("Off");
					}
					else {
						setAutomaticReminder("On");
					}

					setstartDob(response.data.data.start_date);
					setendDob(response.data.data.end_date);

					// Format the parsed time to extract only the time part
					const parsedstartTime = dayjs(`05/22/1961 ${response.data.data.start_time}`, 'MM-DD-YYYY hh:mm A');

					const formattedstartTime = parsedstartTime.format('hh:mm A');

					const parsedendTime = dayjs(`05/22/1961 ${response.data.data.end_time}`, 'MM-DD-YYYY hh:mm A');
					const formattedendTime = parsedendTime.format('hh:mm A');

					setstartTime(formattedstartTime)
					setendTime(formattedendTime);


					// table filter load data (panel 2)

					setCheckedSwitch1(response.data.data.campaign_filter.allow_is_completed_in_six_month);
					setCheckedSwitch2(response.data.data.campaign_filter.contact_by_phone_only);
					setCheckedSwitch3(response.data.data.campaign_filter.hide_members_active_on_other);
					setCheckedSwitch4(response.data.data.campaign_filter.hide_member_had_mammogram_within_six_month);

					setPlanname_filter(response.data.data.campaign_filter.plan_name_code);

					setPlantype_filter(response.data.data.campaign_filter.plan_type_code);

					setGender_filter(response.data.data.campaign_filter.gender_code);


					setDesiredFrequency_filter(response.data.data.campaign_filter.frequency);
					setZipcode_filter(response.data.data.campaign_filter.zipcode);


					setStartdate_start_filter(response.data.data.campaign_filter.start_date)
					setStartdate_end_filter(response.data.data.campaign_filter.end_date);

					setDateofBirth_start_filter(response.data.data.campaign_filter.start_dob);
					setDateofBirth_end_filter(response.data.data.campaign_filter.end_dob);

					setis_pinned(response.data.data.campaign_filter.is_pinned);
					setis_checked(response.data.data.campaign_filter.is_checked);


					// only one tab can be provided for draft option
					localStorage.removeItem('surveyCreation')
					localStorage.removeItem('promotionCreation')

					// check flags for hastrue values (preview all question are filled or not)
					const hasTrueValue = Object.values(response.data.data.flags).includes(true);
					if (hasTrueValue) {
						setdata2publish_disableFlag(true)
					}
					else {
						setdata2publish_disableFlag(false)
					}


					let api_filterdata = {
						"campaign_code": apidata[index].campaign_code,
						"page_no": 1,
						"page_size": "10",
						"sort_by": "",
						"sort_order": "ASC",
						"campaign_filter": {
							"allow_is_completed_in_six_month": response.data.data.campaign_filter.allow_is_completed_in_six_month,
							"contact_by_phone_only": response.data.data.campaign_filter.contact_by_phone_only,
							"hide_members_active_on_other": response.data.data.campaign_filter.hide_members_active_on_other,
							"hide_member_had_mammogram_within_six_month": response.data.data.campaign_filter.hide_member_had_mammogram_within_six_month,
							"plan_name_code": response.data.data.campaign_filter.plan_name_code,
							"plan_type_code": response.data.data.campaign_filter.plan_type_code,
							"frequency": response.data.data.campaign_filter.frequency,
							"gender_code": response.data.data.campaign_filter.gender_code,
							"zipcode": response.data.data.campaign_filter.zipcode,
							"start_date": dayjs(response.data.data.campaign_filter.start_date).format('YYYY-MM-DD'),
							"end_date": dayjs(response.data.data.campaign_filter.end_date).format('YYYY-MM-DD'),
							"start_dob": dayjs(response.data.data.campaign_filter.start_dob).format('YYYY-MM-DD'),
							"end_dob": dayjs(response.data.data.campaign_filter.end_dob).format('YYYY-MM-DD'),
							"is_pinned": response.data.data.campaign_filter.is_pinned,
							"is_checked": response.data.data.campaign_filter.is_checked
						}
					}

					sessionStorage.setItem("filterdata", JSON.stringify(api_filterdata))
					sessionStorage.setItem("campaign_code", apidata[index].campaign_code)

					CommonAPi_tabledata(api_filterdata)

					setTimeout(() => {
						setIsLoading(false);
					}, 1000);

				})
					.catch(error => {
						setIsLoading(false);
						console.error('There was an error!', error);
					})
			}

			else {
				setisPublished(true);
				setdeleteCampaign(false);
				setcreateCampaign(true);
				setExpanded({ panel1: true, panel2: true })


				// setIsLoading(false);
				sessionStorage.setItem("campaign_code", apidata[index].campaign_code)
				setIsLoading(true);
				let apiurl = URL.PUBLISHED_CAMPAIGN_STATUS + "/" + apidata[index].campaign_code;
				Apiservice.getMethod(apiurl).then(response => {
					setIsLoading(false);
					let surveyapi_data;
					if (response.data.data.campaign_type === false) {
						surveyapi_data = "Survey";
						setCampaignCode(response.data.data.survey[0].campaign_code);
						setCampaignselector(response.data.data.survey[0].survey_code);
						sessionStorage.setItem("setCampaignselector", response.data.data.survey[0].survey_code)
						setSurveyName(response.data.data.survey[0].survey_name);
						setReference(response.data.data.survey[0].reference);
						setcampaignSelected_Published(response.data.data.survey[0].survey_name)
						setDescription_Published(response.data.data.survey[0].survey_desc)
					}
					else {
						surveyapi_data = "Promotion";

						setCampaignCode(response.data.data.promotion[0].campaign_code);
						setCampaignselector(response.data.data.promotion[0].promotion_code);
						sessionStorage.setItem("setCampaignselector", response.data.data.promotion[0].promotion_code)
						setSurveyName(response.data.data.promotion[0].promotion_name);
						setReference(response.data.data.promotion[0].reference);
						setcampaignSelected_Published(response.data.data.promotion[0].promotion_name)
						setDescription_Published(response.data.data.promotion[0].promotion_desc)
					}

					setCampaignType(surveyapi_data);

					setCampaignType_Published(surveyapi_data)
					setstartdate_Published(response.data.data.start_date)
					setstarttime_Published(response.data.data.start_time)
					setenddate_Published(response.data.data.end_date)
					setendtime_Published(response.data.data.end_time)

					setCriticalllevel_Published(response.data.data.criticality_level)

					// data for the campaign tracker
					let chartdta = {
						labels: "sample chart",
						datasets: [
							{
								label: "Number of Invitation",
								data: response.data.data.total_members,
							},
							{
								label: "Number of Invitation Started",
								data: response.data.data.started_members,
							},
							{
								label: "Number of Completed",
								data: response.data.data.completed_members,
							}
						]
					};


					setbarchartdata_Published(chartdta)
					setcampaignStartDateTime(new Date(response.data.data.start_date + " " + response.data.data.start_time).getTime());
					setcampaignEndDateTime(new Date(response.data.data.end_date + " " + response.data.data.end_time).getTime());
					setcurrentDateTimenow(new Date().getTime());


					var params = new URLSearchParams();
					params.append("campaign_code", apidata[index].campaign_code)
					params.append("page_size", "10")
					params.append("page_no", 1)
					params.append("sort_by", '')
					params.append("sort_order", "ASC")

					sessionStorage.setItem("filterdata", params.toString())
					CommonAPi_tabledata_published(params)

				})
					.catch(error => {
						setIsLoading(false);
						console.error('There was an error!', error);
					})



			}
		}
		setSelectedTab(index);

	}


	const commonautoresponseapicall = (url) => {
		setIsLoading(true);
		Apiservice.getMethod(url).then(response => {
			setIsLoading(false);
			setautoresponseapidata(response.data.data)

		})
			.catch(error => {
				setIsLoading(false);
				console.error('There was an error!', error);
				setautoresponseapidata(JSON.stringify({}))
			})
	}


	useEffect(() => {
		const handleClickOutside = (event) => {
			divs.forEach((div, index) => handleOutsideClick(event, index));
		};

		document.addEventListener('click', handleClickOutside);
		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, [divs]);


	useEffect(() => {
		divs.forEach((div, index) => {
			if (div.isEditing && divRefs.current[index]) {
				divRefs.current[index].current.focus();
			}

		});
		//double
	}, [divs]);


	// const scrollRight = () => {
	// 	if (containerRef.current) {
	// 		containerRef.current.scrollBy({ left: 200, behavior: 'smooth' });
	// 	}
	// };


	// over lay div code start arrow down button click ..................

	const handle_ListoftabsActive_arrowbtn = (event) => {
		setisOpenoverlay_div(true);
		setisOpen_customdrodown(false);
		setDup_div(divs);
	}

	const handleoverlay_div_ItemClick = (data, index) => {
		const foundIndex = divs.findIndex(campaign =>
			campaign.campaign_code === data.campaign_code
		);
		handleSingleClick(foundIndex, divs);
	}



	const handlechange_isOpenoverlay_div_searchonkeyup = (event) => {
		if (event.target.value.length >= 3) {
			const filteredCampaigns = divs.filter(campaign =>
				campaign.campaign_name.toLowerCase().includes(event.target.value.toLowerCase())
			);
			setDup_div(filteredCampaigns);
		}
		else if (event.target.value.length >= 0) {
			setDup_div(divs);
		}

	}

	const toggleDropdown = () => {
		setisOpen_customdrodown(!isOpen_customdrodown);
	};

	const handleSelect_customdropdown = (selectedValue) => {
		setisOpen_customdrodown(false);
		setisOpen_customdrodown_value(selectedValue);
		if (selectedValue === "All") {
			setDup_div(divs);
		}
		else if (selectedValue === "Campaign Creator") {
			const filteredCampaigns = divs.filter(campaign => campaign.is_draft === true);
			setDup_div(filteredCampaigns);
		}


		else if (selectedValue === "Campaign tracker") {
			const filteredCampaigns = divs.filter(campaign => campaign.is_draft === false);
			setDup_div(filteredCampaigns);
		}

	}



	// accordian inside item functionality

	const handleChange_accordion = (panel) => (event, isExpanded) => {
		if (divs[selectedTab].is_draft !== "") {
			setExpanded((prev) => ({
				...prev,
				[panel]: isExpanded,
			}));
		}
		// setExpanded(isExpanded ? panel : false);
	};

	const handleChange_CampaignType = (event, newCampaignType, surveyarray) => {
		if (newCampaignType !== null) {
			// let cam_data;
			setCampaignType(newCampaignType);

			// get api values
			if (newCampaignType === "Survey") {
				setIsLoading(true);
				let apiurl = URL.AGENT_SURVEYS;

				Apiservice.getMethod(apiurl).then(response => {
					setIsLoading(false);

					const surveyData = response.data.data
					let surveyArray = []
					surveyData.forEach((eachSurvey) => {
						if (eachSurvey.is_draft === false) {
							surveyArray.push(eachSurvey.survey_name.trim())
						}

					});
					sessionStorage.setItem("surveyArray", surveyArray)


					if (surveyarray === "" || surveyarray === undefined) {
						setCampaignselectordata(response.data.data);
						setCampaignselector("");
						sessionStorage.setItem("setCampaignselector", "")
						setsurveyPromotionDescription("");
					}
					else {
						setCampaignselectordata(surveyarray);
						setCampaignselector(surveyarray[0].survey_code);
						sessionStorage.setItem("setCampaignselector", surveyarray[0].survey_code)
						setsurveyPromotionDescription(surveyarray[0].survey_desc)
					}
				})
					.catch(error => {
						setIsLoading(false);
						console.error('There was an error!', error);

					})
			}
			else {
				setIsLoading(true);
				let apiurl = URL.AGENT_PROMOTIONS;

				Apiservice.getMethod(apiurl).then(response => {
					setIsLoading(false);

					const promotionData = response.data.data
					let promotionArray = []
					promotionData.forEach((eachPromotion) => {
						if (eachPromotion.is_draft === false) {
							promotionArray.push(eachPromotion.promotion_name.trim())
						}

					});
					sessionStorage.setItem("promotionArray", promotionArray)


					if (surveyarray === "" || surveyarray === undefined) {
						setCampaignselectordata(response.data.data);
						setCampaignselector("");
						sessionStorage.setItem("setCampaignselector", "")
						setsurveyPromotionDescription("");
					}
					else {
						setCampaignselectordata(surveyarray);
						setCampaignselector(surveyarray[0].promotion_code);
						sessionStorage.setItem("setCampaignselector", surveyarray[0].promotion_code)
						setsurveyPromotionDescription(surveyarray[0].promotion_desc)
					}
				})
					.catch(error => {
						setIsLoading(false);
						console.error('There was an error!', error);

					})


			}

		}
	};

	const handle_clicknewSurvey_Promotion = (data) => {
		if (data === 'Survey') {
			sessionStorage.setItem("setCampaignselector", "create_new");
			setIsCreateSurveyModalOpen(true)
			setisDraft(true);
		} else {
			sessionStorage.setItem("setCampaignselector", "create_new");
			setIsCreatePromotionModalOpen(true)
		}

	}



	const handleChange_AutomaticReminderToggle = (event, data) => {
		setchangesmade_afterDraft(true);
		if (data !== null) {
			setAutomaticReminder(data);
		}
	};


	// dropdown selector onchange
	const handleChange_Campaignselector = (event) => {
		setCampaignselector(event.target.value);
		//get api values
		setCriticallyLevelval("1");
		localStorage.removeItem('surveyCreation')
		localStorage.removeItem('promotionCreation');

		setisPreviewClicked(false)
		if (CampaignType === "Survey") {
			const selectedCampaign = Campaignselectordata.find(campaign => campaign.survey_code === event.target.value);

			if (selectedCampaign && selectedTab !== null) {
				if (selectedCampaign.campaign_code == null) {
					selectedCampaign.campaign_code = ''
				}
				sessionStorage.setItem("setCampaignselector", selectedCampaign.survey_code)
				setCampaignCode(selectedCampaign.campaign_code)

				// setisDraft(!selectedCampaign.is_master && !selectedCampaign.reference)
				setisDraft(false)

				setSurveyName(selectedCampaign.survey_name)
				setReference(selectedCampaign.reference)
				setReferenceOrder(selectedCampaign.reference_order ? selectedCampaign.reference_order : 0)
				setsurveyPromotionDescription(selectedCampaign.survey_desc)
				setDivs((prevDivs) => {
					const newDivs = prevDivs.map((div, index) =>
						index === selectedTab ? { ...div, campaign_name: selectedCampaign.survey_name } : div
					);
					return newDivs;
				});
			}
		}
		else {
			// load the promotion description
			const selectedCampaign = Campaignselectordata.find(campaign => campaign.promotion_code === event.target.value);

			if (selectedCampaign && selectedTab !== null) {
				sessionStorage.setItem("setCampaignselector", selectedCampaign.promotion_code)
				setisDraft(selectedCampaign.is_draft)
				setPromotionName(selectedCampaign.promotion_name)
				setReference(selectedCampaign.reference)
				setsurveyPromotionDescription(selectedCampaign.promotion_desc)
				setDivs((prevDivs) => {
					const newDivs = prevDivs.map((div, index) =>
						index === selectedTab ? { ...div, campaign_name: selectedCampaign.promotion_name } : div
					);
					return newDivs;
				});
			}
		}

		setCampaignselectordata(prevData =>
			prevData.filter(item => item.campaign_code !== "Abc_pro" && item.campaign_code !== "Abc_survey")
		);
	}

	const handleCriticallyLevelval = (event) => {
		setchangesmade_afterDraft(true);
		if (event.target.value === "" || (/^\d$/.test(event.target.value) && parseInt(event.target.value) <= 3)) {
			setCriticallyLevelval(event.target.value);
		}
	}

	const handleStartDateChange = (date) => {
		setchangesmade_afterDraft(true);
		let changeddate = dayjs(date).format('MM/DD/YYYY');
		setstartDob(changeddate);
	};

	const handleStartTimeChange = (time) => {
		setchangesmade_afterDraft(true);
		let changedtime = dayjs(time).format('hh:mm A');
		setstartTime(changedtime);
	};


	const handleendDateChange = (date) => {
		setchangesmade_afterDraft(true);
		let changeddate = dayjs(date).format('MM/DD/YYYY');
		setendDob(changeddate);
	};

	const handleendTimeChange = (time) => {
		setchangesmade_afterDraft(true);
		let changedtime = dayjs(time).format('hh:mm A');
		setendTime(changedtime);
	};


	// filter functions

	const handleChange_Switch1 = (event) => {
		setchangesmade_afterDraft(true);
		setCheckedSwitch1(event.target.checked);
	};

	const handleChange_Switch2 = (event) => {
		setchangesmade_afterDraft(true);
		setCheckedSwitch2(event.target.checked);
	};
	const handleChange_Switch3 = (event) => {
		setchangesmade_afterDraft(true);
		setCheckedSwitch3(event.target.checked);
	};

	const handleChange_Switch4 = (event) => {
		setchangesmade_afterDraft(true);
		setCheckedSwitch4(event.target.checked);
	};

	const handleChange_filter_PlanName = (event) => {
		setchangesmade_afterDraft(true);
		setPlanname_filter(event.target.value);
	};

	const handleChange_filter_PlanType = (event) => {
		setchangesmade_afterDraft(true);
		setPlantype_filter(event.target.value);
	};

	const handleChange_filter_DesiredFrequency = (event) => {
		setchangesmade_afterDraft(true);
		setDesiredFrequency_filter(event.target.value);
	};

	const handleChange_filter_Gender = (event) => {
		setchangesmade_afterDraft(true);
		setGender_filter(event.target.value);
	}

	const handleChange_filter_zipcode = (event) => {
		setchangesmade_afterDraft(true);
		setZipcode_filter(event.target.value)
	}

	const handleChange_filter_Start_Date_Start = (date) => {
		setchangesmade_afterDraft(true);
		let changeddate = dayjs(date).format('MM/DD/YYYY');
		setStartdate_start_filter(changeddate);
	};

	const handleChange_filter_Start_Date_end = (date) => {
		setchangesmade_afterDraft(true);
		let changeddate = dayjs(date).format('MM/DD/YYYY');
		setStartdate_end_filter(changeddate);
	};

	const handleChange_filter_DateofBirth_filter_Start = (date) => {
		setchangesmade_afterDraft(true);
		let changeddate = dayjs(date).format('MM/DD/YYYY');
		setDateofBirth_start_filter(changeddate);
	};

	const handleChange_filter_DateofBirth_filter_end = (date) => {
		setchangesmade_afterDraft(true);
		let changeddate = dayjs(date).format('MM/DD/YYYY');
		setDateofBirth_end_filter(changeddate);
	}

	const clearMemberSearch = () => {
		setchangesmade_afterDraft(true);
		settempsearchdata_value("");
	}

	const handlechange_MemberIDText_Filter = (event) => {
		setchangesmade_afterDraft(true);
		settempsearchdata_value(event.target.value);
	}

	// const handleSearchFilter = (event) => {
	// 	console.log(MemberID_filter)
	// }

	const handle_Search_btn = (type) => {
		let campaign_code = sessionStorage.getItem("campaign_code");
		var params = new URLSearchParams();
		if (type === "DraftCampaign") {
			setMemberID_filter(tempsearchdata_value);
			params.append("variant", "target_list");
			let campaign_code = sessionStorage.getItem("campaign_code");
			setPage(1);

			let api_filterdata = {
				"campaign_code": campaign_code,
				"page_no": 1,
				"page_size": PaginationRow_items,
				"sort_by": "",
				"sort_order": "ASC",
				"campaign_filter": {
					"member_name_id": tempsearchdata_value,
					"allow_is_completed_in_six_month": checkedSwitch1,
					"contact_by_phone_only": checkedSwitch2,
					"hide_members_active_on_other": checkedSwitch3,
					"hide_member_had_mammogram_within_six_month": checkedSwitch4,
					"plan_name_code": Planname_filter,
					"plan_type_code": Plantype_filter,
					"frequency": DesiredFrequency_filter,
					"gender_code": Gender_filter,
					"zipcode": Zipcode_filter,
					"start_date": dayjs(Startdate_start_filter).format('YYYY-MM-DD'),
					"end_date": dayjs(Startdate_end_filter).format('YYYY-MM-DD'),
					"start_dob": dayjs(DateofBirth_start_filter).format('YYYY-MM-DD'),
					"end_dob": dayjs(DateofBirth_end_filter).format('YYYY-MM-DD'),
					"is_pinned": is_pinned,
					"is_checked": is_checked
				}

			}

			sessionStorage.setItem("filterdata", JSON.stringify(api_filterdata))
			CommonAPi_tabledata(api_filterdata)
		}
		else {
			setMemberID_filter(tempsearchdata_value);
			var params = new URLSearchParams();
			params.append("member_name_id", tempsearchdata_value);
			params.append("campaign_code", campaign_code)
			params.append("page_size", PaginationRow_items)
			params.append("page_no", 1)
			params.append("sort_by", "")
			params.append("sort_order", "ASC")
			sessionStorage.setItem("filterdata", params.toString())
			CommonAPi_tabledata_published(params);
		}
	}


	const handle_Upload_Member_List_btn = (event) => {
		setchangesmade_afterDraft(true);
		setUploadmemberListPopupmodal(true);
	}

	const handle_clear_filters = () => {
		setPlanname_filter("All");
		setPlantype_filter("All");
		setGender_filter("All");
		setDesiredFrequency_filter("All");
		setZipcode_filter("");

		setStartdate_start_filter(dayjs().startOf('year').format('MM/DD/YYYY'))
		setStartdate_end_filter(dayjs().add(1, 'year').startOf('year').format('MM/DD/YYYY'));
		setDateofBirth_start_filter(dayjs().subtract(120, 'year').format('MM/DD/YYYY'));
		setDateofBirth_end_filter(dayjs().subtract(65, 'year').format('MM/DD/YYYY'));

		handle_Applyfilters()
	}

	const handle_Applyfilters = () => {
		settempsearchdata_value("");
		setMemberID_filter("");
		let campaign_code = sessionStorage.getItem("campaign_code");
		setPage(1);

		let api_filterdata = {
			"campaign_code": campaign_code,
			"page_no": 1,
			"page_size": PaginationRow_items,
			"sort_by": "",
			"sort_order": "ASC",
			"campaign_filter": {
				"member_name_id": "",
				"allow_is_completed_in_six_month": checkedSwitch1,
				"contact_by_phone_only": checkedSwitch2,
				"hide_members_active_on_other": checkedSwitch3,
				"hide_member_had_mammogram_within_six_month": checkedSwitch4,
				"plan_name_code": Planname_filter,
				"plan_type_code": Plantype_filter,
				"frequency": DesiredFrequency_filter,
				"gender_code": Gender_filter,
				"zipcode": Zipcode_filter,
				"start_date": dayjs(Startdate_start_filter).format('YYYY-MM-DD'),
				"end_date": dayjs(Startdate_end_filter).format('YYYY-MM-DD'),
				"start_dob": dayjs(DateofBirth_start_filter).format('YYYY-MM-DD'),
				"end_dob": dayjs(DateofBirth_end_filter).format('YYYY-MM-DD'),
				"is_pinned": is_pinned,
				"is_checked": is_checked
			}

		}

		sessionStorage.setItem("filterdata", JSON.stringify(api_filterdata))
		CommonAPi_tabledata(api_filterdata)

	}

	// table functionality start here ......

	const handleSelectionRowChange = (newSelection) => {
		if (disabledflag === false) {
			setchangesmade_afterDraft(true);
			const alwaysSelected = Rows.filter(row => row.is_pinned === true).map(row => row.id);
			const updatedSelection = Array.from(new Set([...newSelection, ...alwaysSelected]));

			let a;
			let Data2;
			// on select ALL
			// setis_checked -  to get total count
			// setdefaultcheckedboxSelection -  for checking the checkbox value on default, gets updated on api call
			// setcheckbox_selectionarray -  for frontend functionality

			// only filter out value of present screen selection,  not the existing selection
			let memberIds = Rows.map(row => row.member_id);
			let filteredB = newSelection.filter(value => memberIds.includes(value));


			if (filteredB.length === Rows.length) {

				a = [...new Set([...is_checked, ...updatedSelection])];
				setis_checked(a)
				setdefaultcheckedboxSelection(a);
				//update the setcheckbox_selectionarray array too
				Data2 = storedapiresponsesarray.filter((data) => a.includes(data.member_id));
				setcheckbox_selectionarray(Data2)

			}
			// on deselect All
			else if (filteredB.length === 0) {

				// Filter ischecked based on member IDs in rows
				let a = is_checked.filter(value => !memberIds.includes(value));
				// Add alwaysSelected values to the filtered array, ensuring no duplicates
				a = [...new Set([...a, ...alwaysSelected])];
				setis_checked(a)
				setdefaultcheckedboxSelection(a);
				Data2 = storedapiresponsesarray.filter((data) => a.includes(data.member_id));
				setcheckbox_selectionarray(Data2)
			}
		}
	};

	const handleCellClick = (params, event) => {


		let selectedRowData = [];

		// html code for selected item checked
		if (params.field === '__check__') {
			// if unchecked
			if (disabledflag === false) {
				setchangesmade_afterDraft(true);
				if (params.value === true) {
					// dont filter the data out which have ispinned true, since its auto selected and cannot be unchecked
					if (checkbox_selectionarray.length > 0) {
						selectedRowData = checkbox_selectionarray.filter((row) => row.member_id !== params.row.member_id || row.is_pinned === true);
					}
					else {
						let Data2 = storedapiresponsesarray.filter((data) => is_checked.includes(data.member_id));
						selectedRowData = Data2.filter((row) => row.member_id !== params.row.member_id || row.is_pinned === true);

					}
				}
				// if checked
				else if (params.value === false) {
					const newData = Rows.find((data) => data.member_id === params.row.member_id);
					let defaultMemberRows;
					defaultMemberRows = storedapiresponsesarray.filter((data) => data.is_pinned === true || is_checked.includes(data.member_id));


					if (newData) {
						// load the previous value selected
						let filteredSelectionArray = checkbox_selectionarray.filter((data) => data.is_pinned !== true);
						// Create a new array with the filtered selection array and the new data row
						selectedRowData = [...filteredSelectionArray, newData];


						// If there are default member rows, add them to the selected row data
						if (defaultMemberRows.length > 0) {
							selectedRowData = [...selectedRowData, ...defaultMemberRows];
						}
					}
				}

				// Remove duplicates by filtering out entries with the same
				const idSet = new Set();
				let uniquedata = [];
				for (const item of selectedRowData) {
					if (!idSet.has(item.member_id)) {
						uniquedata.push(item);
						idSet.add(item.member_id);
					}
				}

				let uniquedatadata_dup = uniquedata.map(item => item.member_id)
				console.log(uniquedatadata_dup)
				setis_checked(uniquedatadata_dup)
				setdefaultcheckedboxSelection(uniquedatadata_dup);
				setcheckbox_selectionarray(uniquedata)
			}

		}

		else if (params.field === "member_id" || params.field === "first_name" || params.field === "last_name") {
			setisMemberSnapshotPopupModalOpen(true)
			setgetmemberID(params.id);

		}
	};



	const handleChange_Pagination = (event, value, type) => {
		setchangesmade_afterDraft(true);
		setPage(value);
		let campaign_code = sessionStorage.getItem("campaign_code");

		if (type === "Draftcampaign") {
			let api_filterdata = {
				"campaign_code": campaign_code,
				"page_no": value,
				"page_size": PaginationRow_items,
				"sort_by": "",
				"sort_order": "ASC",
				"campaign_filter": {
					"member_name_id": MemberID_filter,
					"allow_is_completed_in_six_month": checkedSwitch1,
					"contact_by_phone_only": checkedSwitch2,
					"hide_members_active_on_other": checkedSwitch3,
					"hide_member_had_mammogram_within_six_month": checkedSwitch4,
					"plan_name_code": Planname_filter,
					"plan_type_code": Plantype_filter,
					"frequency": DesiredFrequency_filter,
					"gender_code": Gender_filter,
					"zipcode": Zipcode_filter,
					"start_date": dayjs(Startdate_start_filter).format('YYYY-MM-DD'),
					"end_date": dayjs(Startdate_end_filter).format('YYYY-MM-DD'),
					"start_dob": dayjs(DateofBirth_start_filter).format('YYYY-MM-DD'),
					"end_dob": dayjs(DateofBirth_end_filter).format('YYYY-MM-DD'),
					"is_pinned": is_pinned,
					"is_checked": is_checked
				}
			}

			CommonAPi_tabledata(api_filterdata);
		}
		else {
			var params = new URLSearchParams();
			params.append("member_name_id", MemberID_filter);
			params.append("campaign_code", campaign_code)
			params.append("page_size", PaginationRow_items)
			params.append("page_no", value)
			params.append("sort_by", "")
			params.append("sort_order", "ASC")
			CommonAPi_tabledata_published(params);
		}
	}





	const handleChange_PaginationRow_itemschange = (event, type) => {
		setchangesmade_afterDraft(true);
		setPage(1);
		setPaginationRow_items(event.target.value);
		let campaign_code = sessionStorage.getItem("campaign_code");
		if (type === "Draftcampaign") {
			let api_filterdata = {
				"campaign_code": campaign_code,
				"page_no": 1,
				"page_size": event.target.value,
				"sort_by": "",
				"sort_order": "ASC",
				"campaign_filter": {
					"member_name_id": MemberID_filter,
					"allow_is_completed_in_six_month": checkedSwitch1,
					"contact_by_phone_only": checkedSwitch2,
					"hide_members_active_on_other": checkedSwitch3,
					"hide_member_had_mammogram_within_six_month": checkedSwitch4,
					"plan_name_code": Planname_filter,
					"plan_type_code": Plantype_filter,
					"frequency": DesiredFrequency_filter,
					"gender_code": Gender_filter,
					"zipcode": Zipcode_filter,
					"start_date": dayjs(Startdate_start_filter).format('YYYY-MM-DD'),
					"end_date": dayjs(Startdate_end_filter).format('YYYY-MM-DD'),
					"start_dob": dayjs(DateofBirth_start_filter).format('YYYY-MM-DD'),
					"end_dob": dayjs(DateofBirth_end_filter).format('YYYY-MM-DD'),
					"is_pinned": is_pinned,
					"is_checked": is_checked
				}
			}
			CommonAPi_tabledata(api_filterdata);
		}
		else {
			var params = new URLSearchParams();
			params.append("member_name_id", MemberID_filter);
			params.append("campaign_code", campaign_code)
			params.append("page_size", event.target.value)
			params.append("page_no", 1)
			params.append("sort_by", "")
			params.append("sort_order", "ASC")
			CommonAPi_tabledata_published(params);
		}
	};

	const CommonAPi_tabledata = (body) => {
		// call common api to get table details
		setIsLoading(true);
		let apiurl = URL.UPDATE_CAMPAIGN_FILTER;
		Apiservice.patchMethod(apiurl, body).then(response => {
			setIsLoading(false);
			let apirow_data = response.data.data.map(item => ({ id: item.member_id, ...item }));
			setColumns(columns_staticdata)
			setRows(apirow_data);

			let data = response.data.data.filter(row => row.is_pinned === true || row.is_checked === true).map(row => row.member_id);
			setdefaultcheckedboxSelection(data);
			setis_checked(response.data.is_checked)

			let calc = response.data.total_count / body.page_size;
			// Use Math.ceil to round up the value
			// console.log(calc)
			let datacount = Math.ceil(calc);
			settotalpagecount(datacount)


			// store all repsponces
			setstoredapiresponsesarray(prevRows => {
				// Create a map to merge existing rows with new data
				// Ensure prevRows is an array
				prevRows = Array.isArray(prevRows) ? prevRows : [];
				// Create a map to merge existing rows with new data
				const rowsMap = new Map();
				// Add previous rows to the map
				prevRows.forEach(row => rowsMap.set(row.id, row));
				// Update the map with new data, this will overwrite duplicates
				apirow_data.forEach(item => rowsMap.set(item.id, item));
				// Convert the map back to an array
				return Array.from(rowsMap.values());
			});

		})
			.catch(error => {
				setIsLoading(false);
				console.error('There was an error!', error);
			})
	}



	const CommonAPi_tabledata_published = (params) => {

		// call common api to get table details
		setIsLoading(true);
		let apiurl = URL.PUBLISHED_CAMPAIGN_MEMBER_LIST;
		Apiservice.getMethodParams(apiurl, params).then(response => {
			setIsLoading(false);
			let apirow_data = response.data.data.map(item => ({ id: item.member_id, ...item }));
			setColumns1(columns_staticdata1)
			setRows1(apirow_data);
			if (parseInt(response.data.notification_count) > 99) {
				setNotificationCount("99+");
			}
			else {
				setNotificationCount(response.data.notification_count);
			}


			const pageSize = parseInt(params.get("page_size"), 10);

			let calc = response.data.total_count / pageSize;

			// Use Math.ceil to round up the value
			let datacount = Math.ceil(calc);
			settotalpagecount(datacount)

		})
			.catch(error => {
				setIsLoading(false);
				console.error('There was an error!', error);
			})
	}



	const CommonAPi_getfilterdropdownvalues = () => {
		setIsLoading(true);
		let apiurl = URL.CAMPAIGN_FILTERDROPDOWN;
		Apiservice.getMethod(apiurl).then(response => {
			setIsLoading(false);

			setfilterPlantype_array([...response.data.data.insurance_plan_types, { "plan_type_code": "All", "plan_type_name": "All" }]);
			setfiltergender_array([...response.data.data.gender_type, { "gender_code": "All", "gender_name": "All" }]);
			setfilterPlanname_array([...response.data.data.insurance_plans, { "plan_code": "All", "plan_name": "All" }]);

			let de_fqncy = [
				{
					id: "0",
					value: "Often",
				},
				{
					id: "1",
					value: "Periodically",
				},
				{
					id: "2",
					value: "Seldom",
				},
				{
					id: "All",
					value: "All",
				},
			]
			setfilterDesiredFrequency_array(de_fqncy);


		})
			.catch(error => {
				setIsLoading(false);
				console.error('There was an error!', error);
			})

	}






	// campaign tracker button functionality

	const handle_AdministratorNotifications = () => {
		Navigate(ROUTES_AGENT_ADMINISTRATORNOTIFICATIONS);
	}

	const handle_AdministratorCall = () => {
		setIsLoading(true);
		let campaign_code = sessionStorage.getItem("campaign_code");
		let apiurl = URL.CAMPAIGN_PHONECALLS + "/" + campaign_code;
		Apiservice.getMethod(apiurl).then(response => {
			setIsLoading(false);
			seCall_apidata(response.data.data);

			setnotoficationoverlaydiv(true)
			const overlay = document.getElementById('notoficationoverlaydivID');
			overlay.style.display = "block"
			setTimeout(() => {
				overlay.style.right = "0px";

			}, 500);

		})
			.catch(error => {
				setIsLoading(false);
				console.error('There was an error!', error);
			})




	}

	const handleclose_AdministratorNotifications = () => {
		setnotoficationoverlaydiv(false)

		const overlay = document.getElementById('notoficationoverlaydivID');
		overlay.style.right = "-600px";
		setTimeout(() => {
			overlay.style.display = "none"
			window.location.reload();
		}, 500);

		let campaign_code = sessionStorage.getItem("campaign_code");

		// var params = new URLSearchParams();
		// params.append("member_name_id", MemberID_filter);
		// params.append("campaign_code", campaign_code)
		// params.append("page_size", PaginationRow_items)
		// params.append("page_no", page)
		// params.append("sort_by", "")
		// params.append("sort_order", "ASC")
		// CommonAPi_tabledata_published(params);


	}

	const handle_OverlayDiv_Resolve = (data) => {
		setOverlayDiv_Resolvebtnclicked(true);
		setresolvecommentid(data)
	}

	const handlechange_OverlayDiv_comment = (event) => {
		setOverlayDiv_typecomment(event.target.value);
	}

	const handle_OverlayDiv_Submit = () => {
		let campaign_code = sessionStorage.getItem("campaign_code");
		setIsLoading(true);

		let body =
		{
			"member_id": resolvecommentid.toString(),
			"campaign_code": campaign_code,
			"agent_comment": OverlayDiv_typecomment,
			"variant": 1
		}

		let apiurl = URL.CAMPAIGN_TRACKERRESOLVINGISSUE;
		Apiservice.postMethod(apiurl, body).then(response => {
			setIsLoading(false);
			setOverlayDiv_Resolvebtnclicked(false);
			handle_AdministratorCall();
			setOverlayDiv_typecomment("");

		})
			.catch(error => {
				setIsLoading(false);
				console.error('There was an error!', error);
			})
	}

	const handleclick_notificationoverlay_item = (item) => {
		setisMemberSnapshotPopupModalOpen(true)
		setgetmemberID(item.member_id);
	}

	const generateReportbtn = () => {
		Navigate(ROUTES_AGENT_REPORTS);
	}
	// campaign tracker code for functionality ends here



	const handleCancel = () => {
		setcancelpopup(true);
		setisModalOpendeletePopup(true);

	};

	const cancelfunction = () => {
		if (divs.length != 1) {
			localStorage.removeItem('surveyCreation');
			localStorage.removeItem('promotionCreation');
			localStorage.removeItem('Survey_reference');
			if (selectedTab !== null) {
				setDivs((prevDivs) => prevDivs.filter((_, index) => index !== selectedTab));
				setdeleteCampaign(true);
				if (divs.length > 1) {
					handleSingleClick(divs.length - 2, divs);
				}
			}
		}
	}

	const handleCreate = () => {
		let setCampaignselector = sessionStorage.getItem("setCampaignselector")
		if (CampaignType === "Survey") {
			var payload
			if (isPreviewClicked) {
				const startDateTime = dayjs(`${startDob} ${startTime}`, 'MM/DD/YYYY hh:mm A');

				// Combine end date and time
				const endDateTime = dayjs(`${endDob} ${endTime}`, 'MM/DD/YYYY hh:mm A');

				// Create schedule_date array
				const schedule_date = [
					startDateTime.format('YYYY-MM-DD HH:mm:ss'),
					startDateTime.add(1, 'day').format('YYYY-MM-DD HH:mm:ss'), // start date + 1 day and start time
					endDateTime.subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss') // end date - 1 day and end time
				];

				payload = JSON.parse(localStorage.getItem('surveyCreation'));
				payload["invite_reminder_list"][0]["schedule_date"] = schedule_date[0]
				payload["invite_reminder_list"][1]["schedule_date"] = schedule_date[0]
				payload["invite_reminder_list"][2]["schedule_date"] = schedule_date[1]
				payload["invite_reminder_list"][3]["schedule_date"] = schedule_date[1]
				payload["invite_reminder_list"][4]["schedule_date"] = schedule_date[2]
				payload["invite_reminder_list"][5]["schedule_date"] = schedule_date[2]
				// Function to check if at least one `question_text` field is non-empty
				const isAnyFieldNonEmpty = (questionText) => {
					return Object.values(questionText).some(text => text.trim() !== "");
				};

				// Filter questions with at least one `question_text` field non-empty
				// const questionsWithAnyFieldNonEmpty = payload.survey_template[0].ref_questions.filter(question => {
				// 	return question.answer_type_id === 'FIN' || isAnyFieldNonEmpty(question.question_text);
				// });
				// payload.survey_template[0].ref_questions = questionsWithAnyFieldNonEmpty
				payload.reference = reference
				payload.reference_order = referenceOrder
				payload.campaign_code = campaignCode
				if (payload.is_custom == 0) {
					payload.is_previewed = 1
				} else {
					payload.is_previewed = 0
				}
			} else {
				// Combine start date and time
				const startDateTime = dayjs(`${startDob} ${startTime}`, 'MM/DD/YYYY hh:mm A');

				// Combine end date and time
				const endDateTime = dayjs(`${endDob} ${endTime}`, 'MM/DD/YYYY hh:mm A');

				// Create schedule_date array
				const schedule_date = [
					startDateTime.subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss'), // start date and time -1
					startDateTime.add(1, 'day').format('YYYY-MM-DD HH:mm:ss'), // start date + 1 day and start time
					endDateTime.subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss') // end date - 1 day and end time
				];

				var changeType = false
				if (typeof Campaignselector === 'string') {
					changeType = true
				} else {
					changeType = false
				}
				payload = {
					"schedule_date": schedule_date,
					"is_custom": 0,
					"is_previewed": 0,
					"survey_code": changeType ? [setCampaignselector] : setCampaignselector,
					"survey_name": surveyName,
					"survey_desc": surveyPromotionDescription,
					"reference": reference,
					"reference_order": referenceOrder,
					"campaign_code": campaignCode
				}
			}
			handlecreateSurvey(payload)
		} else {
			var payload
			if (isPreviewClicked) {
				payload = JSON.parse(localStorage.getItem('promotionCreation'));
				payload.reference = reference
				const startDateTime = dayjs(`${startDob} ${startTime}`, 'MM/DD/YYYY hh:mm A');

				// Combine end date and time
				const endDateTime = dayjs(`${endDob} ${endTime}`, 'MM/DD/YYYY hh:mm A');

				// Create schedule_date array
				const schedule_date = [
					startDateTime.subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss'), // start date and time -1
					startDateTime.add(1, 'day').format('YYYY-MM-DD HH:mm:ss'), // start date + 1 day and start time
					endDateTime.subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss') // end date - 1 day and end time
				];

				payload["invite_reminder_list"][0]["schedule_date"] = schedule_date[0]
				payload["invite_reminder_list"][1]["schedule_date"] = schedule_date[0]
				payload["invite_reminder_list"][2]["schedule_date"] = schedule_date[1]
				payload["invite_reminder_list"][3]["schedule_date"] = schedule_date[1]
				payload["invite_reminder_list"][4]["schedule_date"] = schedule_date[2]
				payload["invite_reminder_list"][5]["schedule_date"] = schedule_date[2]

				if (payload.is_custom == 0) {
					payload.is_previewed = 1
				} else {
					payload.is_previewed = 0
				}
			} else {
				// Combine start date and time
				const startDateTime = dayjs(`${startDob} ${startTime}`, 'MM/DD/YYYY hh:mm A');

				// Combine end date and time
				const endDateTime = dayjs(`${endDob} ${endTime}`, 'MM/DD/YYYY hh:mm A');

				// Create schedule_date array
				const schedule_date = [
					startDateTime.format('YYYY-MM-DD HH:mm:ss'),
					startDateTime.add(1, 'day').format('YYYY-MM-DD HH:mm:ss'), // start date + 1 day and start time
					endDateTime.subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss') // end date - 1 day and end time
				];
				payload = {
					"schedule_date": schedule_date,
					"is_custom": 0,
					"is_previewed": 0,
					"reference": reference,
					"promotion_code": setCampaignselector,
					"promotion_name": promotionName,
					"promotion_desc": surveyPromotionDescription,
					"promotion_header": {
						"en": "",
						"es": "",
						"vi": ""
					},
					"promotion_information": {
						"en": "",
						"es": "",
						"vi": ""
					},
				}
			}
			handlecreatePromotion(payload)
		}

	}


	const handlecreatePromotion = (payload) => {

		setIsLoading(true);
		let apiurl = URL.CREATE_PROMOTION;
		let body = payload

		Apiservice.postMethod(apiurl, body).then(response => {
			setIsLoading(false);
			localStorage.removeItem('promotionCreation');
			handlecreateCampaign(response.data.data.promotion_code)
		})
			.catch(error => {
				setIsLoading(false);
				console.error('There was an error!', error);
			})
	}

	const handlecreateSurvey = (payload) => {

		setIsLoading(true);
		let apiurl = URL.CREATE_SURVEY;
		let body = payload

		Apiservice.postMethod(apiurl, body).then(response => {
			setIsLoading(false);
			localStorage.removeItem('surveyCreation');
			localStorage.removeItem('Survey_reference');
			setReference('')
			handlecreateCampaign(response.data.data.survey_code)

		})
			.catch(error => {
				setIsLoading(false);
				console.error('There was an error!', error);
			})
	}


	const handleDelete = () => {
		if (selectedTab !== null) {
			setdeletepopup(true);
			setisModalOpendeletePopup(true);

		}
	}

	const Deletefunction = () => {
		let campaign_code = sessionStorage.getItem("campaign_code");
		let apiurl = URL.DELETE_CAMPAIGN + "/" + campaign_code;
		setIsLoading(true);
		Apiservice.deleteMethod(apiurl).then(response => {
			sessionStorage.removeItem("tabvalue_campaign_code")
			window.location.reload();



		})
			.catch(error => {
				setIsLoading(false);
				console.error('There was an error!', error);
			})
	}

	const handlecreateCampaign = (code) => {
		// Check if campaign_name already exists in other divs
		const isNamePresent = allCampaignNames.some((div, index) => index !== selectedTab && div.toLowerCase() === divs[selectedTab].campaign_name.toLowerCase());

		if (isNamePresent) {
			setisModalOpenGlobalAlertPopup2(true);
			setisModalOpenGlobalAlertPopup2heading("Duplicate Campaign Name Detected! Please edit the campaign tab title with a unique name")
		} else {
			let date_end = dayjs(endDob)
				.set('hour', dayjs(endTime, 'hh:mm A').hour())
				.set('minute', dayjs(endTime, 'hh:mm A').minute());

			let date_start = dayjs(startDob)
				.set('hour', dayjs(startTime, 'hh:mm A').hour())
				.set('minute', dayjs(startTime, 'hh:mm A').minute());

			let today = dayjs();

			// Compare the given date with date_start's date
			if (date_start.isBefore(today)) {
				setIsModalOpen(true);
				setstartdate_warningmsg(true);
			}
			else {
				if (date_end.isAfter(date_start)) {
					// call api...since condition satisfied
					let surveyapi_data;
					let survey_selectedcode;
					let promotion_selectedcode;
					if (CampaignType === "Survey") {
						surveyapi_data = false;
						survey_selectedcode = code;
						promotion_selectedcode = ""
					}
					else {
						surveyapi_data = true;
						survey_selectedcode = [];
						promotion_selectedcode = code
					}

					let autoreminder;
					if (AutomaticReminder === "Off") {
						autoreminder = false;
					}
					else {
						autoreminder = true;
					}

					setIsLoading(true);
					let apiurl = URL.CREATE_CAMPAIGN;

					// Combine start date and time
					const startDateTime = dayjs(`${startDob} ${startTime}`, 'MM/DD/YYYY hh:mm A');

					// Combine end date and time
					const endDateTime = dayjs(`${endDob} ${endTime}`, 'MM/DD/YYYY hh:mm A');

					// Create schedule_date array
					const schedule_date = [
						startDateTime.format('YYYY-MM-DD HH:mm:ss'), // start date and time -1
						startDateTime.add(1, 'day').format('YYYY-MM-DD HH:mm:ss'), // start date + 1 day and start time
						endDateTime.subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss') // end date - 1 day and end time
					];

					let autoresponse = autoresponseapidata;



					let body = {
						"campaign_name": divs[selectedTab].campaign_name,
						"campaign_type": surveyapi_data,
						"criticality_level": CriticallyLevelval,
						"is_auto_reminder": autoreminder,
						"start_date": dayjs(startDob).format('YYYY-MM-DD'),
						"start_time": dayjs(startTime, 'hh:mm A').format('HH:mm:ss'),
						"end_date": dayjs(endDob).format('YYYY-MM-DD'),
						"end_time": dayjs(endTime, 'hh:mm A').format('HH:mm:ss'),
						"promotion_code": promotion_selectedcode,
						"survey_code": survey_selectedcode,
						"is_custom": false,
						"schedule_date": schedule_date,
						"auto_response": autoresponse
					}

					Apiservice.postMethod(apiurl, body).then(response => {
						// setIsLoading(false);
						setCampaignCode(response.data.data.campaign_code)
						setcreateCampaign(true);
						setExpanded({ panel1: true, panel2: true })
						setDivs(prevDiv => {
							const newDiv = [...prevDiv];
							newDiv[selectedTab] = {
								...newDiv[selectedTab],
								campaign_code: response.data.data.campaign_code
							};

							// Log the updated array to the console
							return newDiv;
						});
						sessionStorage.setItem("tabvalue_campaign_code", response.data.data.campaign_code)
						window.location.reload();



					})
						.catch(error => {
							setIsLoading(false);
							console.error('There was an error!', error);
						})
				}
				else {
					setIsModalOpen(true);
					setenddate_warningmsg(true);
				}
			}
		}


	}


	const handle_Draft_btn = (Tabdata) => {
			let date_end = dayjs(endDob)
				.set('hour', dayjs(endTime, 'hh:mm A').hour())
				.set('minute', dayjs(endTime, 'hh:mm A').minute());

			let date_start = dayjs(startDob)
				.set('hour', dayjs(startTime, 'hh:mm A').hour())
				.set('minute', dayjs(startTime, 'hh:mm A').minute());

			let today = dayjs();

			// Compare the given date with date_start's date
			if (date_start.isBefore(today)) {
				setIsModalOpen(true);
				setstartdate_warningmsg(true);
			}
			else {
				if (date_end.isAfter(date_start)) {
					// call api...since condition satisfied
					apicallDraft_Published("Draft", Tabdata);
				}
				else {
					setIsModalOpen(true);
					setenddate_warningmsg(true);
				}
			}
	}



	const handle_Publish_btn = () => {
			let date_end = dayjs(endDob)
				.set('hour', dayjs(endTime, 'hh:mm A').hour())
				.set('minute', dayjs(endTime, 'hh:mm A').minute());

			let date_start = dayjs(startDob)
				.set('hour', dayjs(startTime, 'hh:mm A').hour())
				.set('minute', dayjs(startTime, 'hh:mm A').minute());

			let today = dayjs();

			if (is_checked.length === 0) {
				setisModalOpenGlobalAlertPopup2(true);
				setisModalOpenGlobalAlertPopup2heading("You cannot publish because no members have been selected")
			}

			else {

				if (date_start.isBefore(today)) {
					setIsModalOpen(true);
					setstartdate_warningmsg(true);
				}
				else {
					if (date_end.isAfter(date_start)) {
						if (data2publish_disableFlag === true) {
							setPublish_ErrorPopupmodal(true)
						}
						else {
							setisModalOpenConfirmationPopup(true);
						}
					}
					else {
						setIsModalOpen(true);
						setenddate_warningmsg(true);
					}
				}
			}
	}

	const apicallDraft_Published = (type, dup_selectedTab) => {
		let is_published;
		if (type === "publish") {
			is_published = true
		}
		else {
			is_published = false;
		}

		let autoreminder;
		if (AutomaticReminder === "Off") {
			autoreminder = false;
		}
		else {
			autoreminder = true;
		}

		let autoresponse = autoresponseapidata;

		// Get the current date and time in UTC
		const nowUTC = new Date().toISOString();
		const utcDate = nowUTC.split('T')[0]; // Extract YYYY-MM-DD
		const utcTime = nowUTC.split('T')[1].substring(0, 5); // Extract HH:MM

		// Get the system's current date and time
		const nowSystem = new Date();
		const systemDate = nowSystem.toISOString().split('T')[0]; // Extract YYYY-MM-DD
		const systemTime = nowSystem.toTimeString().substring(0, 5); // Extract HH:MM

		// Calculate the difference in time between UTC and system time
		const timeDifference = nowSystem.getTimezoneOffset(); // in minutes
		const hoursDifference = Math.floor(Math.abs(timeDifference) / 60);
		const minutesDifference = Math.abs(timeDifference) % 60;
		const differenceSign = timeDifference > 0 ? "-" : "+";
		const differenceSignbolean = timeDifference > 0 ? false : true;
		const timeDifferenceFormatted = `${differenceSign}${String(hoursDifference).padStart(2, '0')}:${String(minutesDifference).padStart(2, '0')}`;

		const startDateTime = dayjs(`${startDob} ${startTime}`, 'MM/DD/YYYY hh:mm A');

		// Combine end date and time
		const endDateTime = dayjs(`${endDob} ${endTime}`, 'MM/DD/YYYY hh:mm A');

		// Create schedule_date array
		const schedule_date = [
			startDateTime.format('YYYY-MM-DD HH:mm:ss'), // start date and time -1
			startDateTime.add(1, 'day').format('YYYY-MM-DD HH:mm:ss'), // start date + 1 day and start time
			endDateTime.subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss') // end date - 1 day and end time
		];

		let setCampaignselectorgetvalue = sessionStorage.getItem("setCampaignselector")

		let body = {
			"campaign_code": divs[selectedTab].campaign_code,
			"campaign_name": divs[selectedTab].campaign_name,
			"criticality_level": CriticallyLevelval,
			"is_auto_reminder": autoreminder,
			"start_date": dayjs(startDob).format('YYYY-MM-DD'),
			"start_time": dayjs(startTime, 'hh:mm A').format('HH:mm:ss'),
			"end_date": dayjs(endDob).format('YYYY-MM-DD'),
			"end_time": dayjs(endTime, 'hh:mm A').format('HH:mm:ss'),
			"is_published": is_published,
			"auto_response": autoresponse,
			"page_no": 1,
			"page_size": PaginationRow_items,
			"sort_by": "first_name",
			"sort_order": "ASC",
			"utc_datetime": utcDate + " " + utcTime,
			"system_datetime": systemDate + " " + systemTime,
			"time_diff_bool": differenceSignbolean,
			"time_diff": timeDifferenceFormatted,
			"campaign_filter": {
				"allow_is_completed_in_six_month": checkedSwitch1,
				"contact_by_phone_only": checkedSwitch2,
				"hide_members_active_on_other": checkedSwitch3,
				"hide_member_had_mammogram_within_six_month": checkedSwitch4,
				"plan_name_code": Planname_filter,
				"plan_type_code": Plantype_filter,
				"frequency": DesiredFrequency_filter,
				"gender_code": Gender_filter,
				"zipcode": Zipcode_filter,
				"start_date": dayjs(Startdate_start_filter).format('YYYY-MM-DD'),
				"end_date": dayjs(Startdate_end_filter).format('YYYY-MM-DD'),
				"start_dob": dayjs(DateofBirth_start_filter).format('YYYY-MM-DD'),
				"end_dob": dayjs(DateofBirth_end_filter).format('YYYY-MM-DD'),
				"is_pinned": is_pinned,
				"is_checked": is_checked
			},
			"survey_promotion_code": setCampaignselectorgetvalue,
			"schedule_date": schedule_date

		}

		setIsLoading(true);
		let apiurl = URL.UPDATE_CAMPAIGN;
		Apiservice.patchMethod(apiurl, body).then(response => {
			setIsLoading(false);
			sessionStorage.setItem("tabvalue_campaign_code", divs[dup_selectedTab].campaign_code)
			if (type === "publish") {
				setisModalOpenSuccessPopup(true)
			}
			else {
				window.location.reload();
			}

		})
			.catch(error => {
				setIsLoading(false);
				console.error('There was an error!', error);
			})
	}

	const handle_campaignHistory = () => {
		setCampaignHistoryPopupmodal(true);
	}

	const handleCampaignHistoryPopupmodalButton = () => {
		setCampaignHistoryPopupmodal(false);
	}



	return (
		<Box className={"CampaignDetailsContainer"}>
			<HeaderAgent />
			{isLoading &&
				<CommoncircularProgress></CommoncircularProgress>
			}
			<Grid container className={"bodygrid_CampaignDetails"}>
				<Grid xs={8}>

				</Grid>

				<Grid xs={4}><br></br>
					<Grid container xs={12} spacing={2} className="topgridlayout" style={{ flexWrap: 'nowrap' }}>
						<Grid>
							<Button
								variant="contained"
								aria-label="Campaign History"
								type="submit"
								className="CampaignHistorynbtn"
								onClick={() => handle_campaignHistory()}
							>
								Campaign History
							</Button>
						</Grid>
						<Grid>
							<Button
								variant="contained"
								aria-label="New Campaign"
								type="submit"
								className="Newcampaignbtn"
								onClick={() => handle_addNewCampaign()}
							>
								<img src="/asset/Agent/plusicon.png" alt="Icon" className="image" />&nbsp;
								New Campaign
							</Button>
						</Grid>
					</Grid>

				</Grid>

				{deleteCampaign === false &&
					<Grid xs={12}>

						<div className={"tabmaindiv"}>
							<img src="/asset/Agent/overlaydivicon.png" alt="Up Icon" style={{ cursor: "pointer", height: "25px", marginTop: "20px" }} onClick={() => handle_ListoftabsActive_arrowbtn()} />

							{isOpenoverlay_div === true && (
								<div className="overlayDiv">
									<Grid container xs={12} spacing={1} id="OverlayDivID">
										<Grid xs={8}>
											<div className="textfield-container" style={{ backgroundColor: "white" }}>
												<TextField id="outlined-basic" placeholder="Search" variant="outlined" className="searchinput"
													onKeyUp={(event) => handlechange_isOpenoverlay_div_searchonkeyup(event)} />
												<img src="/asset/Agent/searchgrey.png" alt="searchicon" className="searchicon" />
											</div>
										</Grid>

										<Grid xs={4}>

											<div className="dropdown-header" id="OverlayDivID" onClick={toggleDropdown}>
												<span className='dropdown-headertext' id="OverlayDivID">
													{isOpen_customdrodown_value}
												</span>
												<img src="/asset/Agent/keyboard_arrow_down.png" alt="keyboard_arrow_down" className="keyboard_arrow_down" />


											</div>
											{isOpen_customdrodown && (
												<ul className="dropdown-list" id="OverlayDivID">
													{customdrodown_array.map((data, index) => (
														<li id="OverlayDivID" key={index} onClick={() => handleSelect_customdropdown(data.value)}>
															{data.value}
														</li>
													))}
												</ul>
											)}

										</Grid>

									</Grid>
									{Dup_div.map((item, index) => (
										<div
											key={index}
											onClick={() => handleoverlay_div_ItemClick(item, index)}
											className={selectedTab === index ? "overlay_div_Itemtext_active" : "overlay_div_Itemtext"}
										>
											{item.campaign_name}
										</div>
									))}
								</div>
							)}


							<div ref={containerRef} className={"tabboxdiv"}>
								{divs.map((div, index) => (
									<div
										key={div.campaign_code}
										ref={divRefs.current[index]}
										onClick={() => handleSingleClick(index, divs)}
										onDoubleClick={() => handleDoubleClick(index)}
										className={
											(div.is_draft === "" || div.is_draft === true ? "Draft_tab " : "Published_tab ") +
											(selectedTab === index ? "Tab_selected" : "Tab_unselected")
										}
									>
										{div.isEditing ? (
											<input
												id="editingtextID"
												type="text"
												value={div.campaign_name}
												onChange={(e) => handleInputChange(index, e)}
												onKeyDown={(e) => handleInputChange(index, e)}
												onBlur={() => setDivs(divs.map((d, i) => (i === index ? { ...d, isEditing: false } : d)))}
												style={{ width: '200px' }}
											/>
										) : (
											<div className="tabdivtext">{div.campaign_name}</div>
										)}
									</div>
								))}
							</div>
							{/* <button onClick={scrollRight} style={{ marginLeft: '10px' }}> &gt; </button> */}
						</div>


						{selectedTab !== null && (
							<div>
								{isPublished === false ?
									<div>
										<Accordion expanded={expanded.panel1} onChange={handleChange_accordion('panel1')}>
											<AccordionSummary className={"accordion_header"}
												expandIcon={expanded.panel1 ? <img src="/asset/Agent/arrowdowncampaign.png" alt="Up Icon" /> : <img src="/asset/Agent/arrowdowncampaign.png" alt="Down Icon" />}
												aria-controls="panel1-content"
												id="panel1-header"
											>
												<Typography className="text1_css">Campaign Details</Typography>
											</AccordionSummary>
											<AccordionDetails>
												<Grid container spacing={2}>
													<Grid xs={6} className={"grid-CampaignType"}>
														<br></br><br></br><br></br><br></br>
														<div className="label_text">
															Campaign Type &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
														</div>

														<div className={"togglebuttonouter_div"}>
															<ToggleButtonGroup
																value={CampaignType}
																exclusive
																onChange={handleChange_CampaignType}
																aria-label="CampaignType"
																disabled={isPublished ? true : false}
															>
																<ToggleButton value="Survey" className={CampaignType === 'Survey' ? 'togglebtn_selected' : 'togglebtn_default'}>
																	Survey</ToggleButton>
																<ToggleButton value="Promotion" className={CampaignType === 'Promotion' ? 'togglebtn_selected' : 'togglebtn_default'}>
																	Promotion</ToggleButton>
															</ToggleButtonGroup>
														</div>
													</Grid>

													<Grid xs={6} >
														{disabledflag === true &&
															<div className='information-css'><br></br>
																You can't edit this campaign because you're not the owner
															</div>
														}
													</Grid>

													<Grid container xs={12}>

														<Grid container xs={6} >

															<Grid xs={9} className={"grid-left"}>
																{CampaignType === "Survey" &&
																	<div className="label_text">Survey Selector</div>
																}

																{CampaignType === "Promotion" &&
																	<div className="label_text">Promotion Selector</div>
																}

																<FormControl className={"Drpdownselectsurvey_promotion"}>
																	{/* {Campaignselector === "" &&
																		<InputLabel id="demo-simple-select-label" style={{ marginTop: '-5px' }}>Select a {CampaignType}...</InputLabel>
																	} */}

																	{CampaignType === "Survey" &&
																		<Select displayEmpty
																			className={createCampaign ? "disabledropdown" : "enabledropdown"}
																			labelId="demo-simple-select-label"
																			id="demo-simple-select"
																			value={Campaignselector}
																			onChange={handleChange_Campaignselector}
																			renderValue={(selected) => {
																				if (selected.length === 0) {
																					return <InputLabel id="demo-simple-select-label">Select a {CampaignType}...</InputLabel>
																				}
																				const selectedItem = Campaignselectordata.find(data => data.survey_code === selected);
																				return selectedItem &&
																					//  selectedItem.survey_name
																					// 	:
																					<div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
																						<div style={{ textAlign: 'left', fontSize: "13px", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
																							{selectedItem && selectedItem.survey_name} &nbsp;

																						</div>

																						{selectedItem && selectedItem.is_edited === true && selectedItem.is_draft === true &&
																							<div style={{ color: "red", fontSize: "12px", textAlign: 'left', }}>&lt;Draft&gt;</div>
																						}

																						{selectedItem && selectedItem.is_edited === true &&
																							<div style={{ color: "red", textAlign: 'right', flex: 'auto', fontSize: "12px", marginTop: "3px" }}>
																								{/* Created on {dayjs(selectedItem.modified_date, 'MM/D/YYYY hh:mm A').format('D MMMM, YYYY')} */}
																								{selectedItem.modified_date}
																							</div>}


																					</div>
																			}}
																		>
																			{Campaignselectordata.map((data, index) => (

																				<MenuItem key={index} value={data.survey_code} sx={{ height: "60px" }}>

																					<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
																						<div style={{ flex: '1 1 auto', textAlign: 'left' }}>
																							{data.survey_name} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
																						</div>

																						{data.modified_date !== "" &&
																							<div style={{ color: "red", textAlign: 'right', fontSize: "14px" }}>
																								Created on {dayjs(data.modified_date, 'MM/D/YYYY hh:mm A').format('D MMMM, YYYY')}
																							</div>
																						}

																					</div>

																				</MenuItem>


																			))}
																			<MenuItem value="create_new" sx={{ height: "60px", fontWeight: 'bold' }} onClick={() => handle_clicknewSurvey_Promotion(CampaignType)}>
																				<div >
																					+ Create a new {CampaignType}
																				</div>

																			</MenuItem>
																		</Select>
																	}


																	{CampaignType === "Promotion" &&
																		<Select displayEmpty
																			className={createCampaign ? "disabledropdown" : "enabledropdown"}
																			labelId="demo-simple-select-label"
																			id="demo-simple-select"
																			value={Campaignselector}
																			onChange={handleChange_Campaignselector}
																			renderValue={(selected) => {
																				if (selected.length === 0) {
																					return <InputLabel id="demo-simple-select-label">Select a {CampaignType}...</InputLabel>
																				}
																				const selectedItem = Campaignselectordata.find(data => data.promotion_code === selected);
																				return selectedItem &&

																					// ? selectedItem.promotion_name
																					// 	:

																					<div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
																						<div style={{ textAlign: 'left', fontSize: "13px", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
																							{selectedItem && selectedItem.promotion_name} &nbsp;

																						</div>

																						{selectedItem && selectedItem.is_edited === true && selectedItem.is_draft === true &&
																							<div style={{ color: "red", fontSize: "12px", textAlign: 'left', }}>&lt;Draft&gt;</div>
																						}

																						{selectedItem && selectedItem.is_edited === true &&
																							<div style={{ color: "red", textAlign: 'right', flex: 'auto', fontSize: "12px" }}>
																								{/* Created on {dayjs(selectedItem.modified_date, 'MM/D/YYYY hh:mm A').format('D MMMM, YYYY')} */}
																								{selectedItem.modified_date}
																							</div>}

																					</div>
																			}}
																		>
																			{Campaignselectordata.map((data, index) => (

																				<MenuItem key={index} value={data.promotion_code} sx={{ height: "60px" }}>

																					<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
																						<div style={{ flex: '1 1 auto', textAlign: 'left' }}>
																							{data.promotion_name} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
																						</div>

																						{data.modified_date !== "" &&

																							<div style={{ color: "red", textAlign: 'right', fontSize: "14px" }}>
																								Created on {dayjs(data.modified_date, 'MM/D/YYYY hh:mm A').format('D MMMM, YYYY')}
																							</div>
																						}

																					</div>

																				</MenuItem>


																			))}
																			<MenuItem value="create_new" sx={{ height: "60px", fontWeight: 'bold' }}
																				onClick={() => handle_clicknewSurvey_Promotion(CampaignType)}>
																				<div >
																					+ Create a new {CampaignType}
																				</div>

																			</MenuItem>
																		</Select>
																	}

																</FormControl>
															</Grid>

															<Grid xs={3}>
																<Button
																	variant="contained"
																	aria-label="Preview"
																	type="submit"
																	className={Campaignselector === "" ? "DisablePreviwbtn" : "Previewbtn"}
																	onClick={handlePreviewClick}
																>

																	Preview
																</Button>
															</Grid>

															<Grid xs={9}>
																<div className={"textlabel_description_outerdiv"}>
																	<div className={"textlabel_description_outerdivscroll"}>
																		<div className={"textlabel_description"}>
																			<span style={{ fontWeight: "bold" }}>Description</span>
																		</div>

																		<div className={"textlabel_description"}>
																			<span>{surveyPromotionDescription}</span>
																		</div>
																	</div>
																</div>

															</Grid >

															<Grid xs={3}>
																<div className={"textlabel_CriticallyLevel_outerdiv"}>
																	<div className={"textlabel_CriticallyLevel"}>Critically Level</div>
																	<TextField id="outlined-basic" disabled={disabledflag}
																		type='text'
																		value={CriticallyLevelval}
																		onChange={handleCriticallyLevelval}
																		variant="outlined"
																		className={"CriticallyLevel_inputbox"} />
																</div >

															</Grid >


														</Grid>

														<Grid xs={0.5}>
															<img src="/asset/Agent/Linebig.png" alt="Icon" className={"Lineimage"} />
														</Grid>


														<Grid container xs={3}>

															<Grid xs={6} className={"grid-left"}>
																<div className="label_text">Start Date & Time</div>
																<LocalizationProvider dateAdapter={AdapterDayjs}>
																	<DesktopDatePicker className="customDatePicker"
																		disabled={disabledflag}
																		minDate={dayjs()}
																		value={dayjs(startDob)}
																		onChange={handleStartDateChange} />
																</LocalizationProvider>
															</Grid>

															<Grid xs={6}>
																<LocalizationProvider dateAdapter={AdapterDayjs}>
																	<DesktopTimePicker className="customDateTimePicker"
																		disabled={disabledflag}
																		value={dayjs(startTime, 'h:mm A')}
																		onChange={handleStartTimeChange} />
																</LocalizationProvider>
															</Grid>

															<Grid xs={6} className={"grid-left"} style={{ marginTop: "10px" }}>

																<div className="label_text">End Date & Time</div>
																<LocalizationProvider dateAdapter={AdapterDayjs}>
																	<DesktopDatePicker className="customDatePicker"
																		disabled={disabledflag}
																		minDate={dayjs(startDob).add(1, 'day')}
																		value={dayjs(endDob)}
																		onChange={handleendDateChange} />
																</LocalizationProvider>
															</Grid>

															<Grid xs={6} style={{ marginTop: "10px" }}>

																<LocalizationProvider dateAdapter={AdapterDayjs}>
																	<DesktopTimePicker className="customDateTimePicker"
																		disabled={disabledflag}
																		value={dayjs(endTime, 'h:mm A')}
																		onChange={handleendTimeChange} />
																</LocalizationProvider>
															</Grid>

														</Grid>

														<Grid xs={0.5}>
															<img src="/asset/Agent/Linebig.png" alt="Icon" className={"Lineimage"} />
														</Grid>


														<Grid container xs={2}>

															<Grid xs={12} style={{ textAlign: "center" }}>

																<div className="label_text" >
																	Automatic Reminder
																</div>

																<div className={"toggleAutomaticReminder_div"}>

																	<ToggleButtonGroup
																		disabled={disabledflag}
																		// color="primary"
																		value={AutomaticReminder}
																		exclusive
																		onChange={handleChange_AutomaticReminderToggle}
																		aria-label="Automatic Reminder"
																	>
																		<ToggleButton value="Off" className={AutomaticReminder === 'Off' ? 'togglebtnAutomaticReminder_selected' : 'togglebtnAutomaticReminder_default'}>
																			Off</ToggleButton>
																		<ToggleButton value="On" className={AutomaticReminder === 'On' ? 'togglebtnAutomaticReminder_selected' : 'togglebtnAutomaticReminder_default'}>
																			On</ToggleButton>
																	</ToggleButtonGroup>
																</div>
															</Grid>

															<Grid xs={12}>
																<Button
																	disabled={disabledflag}
																	variant="contained"
																	aria-label="Preview"
																	type="submit"
																	className={disabledflag === true ? "disable_AutoResponsebtn" : "AutoResponsebtn"}
																	onClick={handleAutoResponseClick}
																>

																	Auto-Response
																</Button>
															</Grid>

														</Grid>

													</Grid>
												</Grid>
											</AccordionDetails>
										</Accordion>

										{createCampaign === true &&
											<Accordion expanded={expanded.panel2} onChange={handleChange_accordion('panel2')}>
												<AccordionSummary className={"accordion_header"}
													expandIcon={expanded.panel2 ? <img src="/asset/Agent/arrowdowncampaign.png" alt="Up Icon" /> : <img src="/asset/Agent/arrowdowncampaign.png" alt="Down Icon" />}
													aria-controls="panel2-content"
													id="panel2-header"
												>
													<Typography className="text1_css">Targets List</Typography>
												</AccordionSummary>
												<AccordionDetails>
													<Grid container spacing={2} className={"filter_GridContainer"}>
														<Grid xs={2.5} style={{ marginLeft: "30px" }}><br></br>
															<FormGroup>
																<FormControlLabel
																	control={
																		<Switch
																			disabled={disabledflag}
																			checked={checkedSwitch1}
																			onChange={handleChange_Switch1}
																		/>
																	}
																	className="switchlabel"
																	label="Hide Member who completed this campaign within the last 6 months" />
															</FormGroup>

														</Grid>

														<Grid xs={0.5}><br></br>
															<img src="/asset/Line 1.png" alt="Line" style={{ height: '60px' }} />
														</Grid>

														<Grid xs={2.5}><br></br>

															<FormGroup style={{ marginTop: "10px" }}>
																<FormControlLabel control={<Switch
																	disabled={disabledflag}
																	checked={checkedSwitch2}
																	onChange={handleChange_Switch2}
																/>
																}
																	className="switchlabel"
																	label="Display contact by phone member only" />
															</FormGroup>

														</Grid>

														<Grid xs={0.5}><br></br>
															<img src="/asset/Line 1.png" alt="Line" style={{ height: '60px' }} />
														</Grid>

														<Grid xs={2.5}>
															<br></br>
															<FormGroup style={{ marginTop: "10px" }}>
																<FormControlLabel control={<Switch
																	disabled={disabledflag}
																	checked={checkedSwitch3}
																	onChange={handleChange_Switch3}
																/>
																}
																	className="switchlabel"
																	label="Hide Member active on other campaign" />
															</FormGroup>

														</Grid>

														<Grid xs={0.5}><br></br>
															<img src="/asset/Line 1.png" alt="Line" style={{ height: '60px' }} />
														</Grid>

														<Grid xs={2.5}>
															<br></br>
															<FormGroup>
																<FormControlLabel control={<Switch
																	disabled={disabledflag}
																	checked={checkedSwitch4}
																	onChange={handleChange_Switch4}
																/>
																}
																	className="switchlabel"
																	label="Hide Member who had Mammogram within the last 6 months" />
															</FormGroup>

														</Grid>


														<Grid xs={5} className={"grid-left"}>
															<br></br>
															<div className="label_text1">Plan Name</div>
															<FormControl className={"Drpdownselect"}>
																<Select
																	disabled={disabledflag}
																	labelId="demo-simple-select-label"
																	id="demo-simple-select"
																	value={Planname_filter}
																	onChange={handleChange_filter_PlanName}
																>
																	{filterPlanname_array.map((data, index) => (
																		<MenuItem key={index} value={data.plan_code} sx={{ height: "60px" }}>{data.plan_name}</MenuItem>
																	))}
																</Select>

															</FormControl>

														</Grid>

														<Grid xs={2.5} className={"grid-left"}>
															<br></br>
															<div className="label_text1">Plan Type</div>
															<FormControl className={"Drpdownselect"}>
																<Select
																	disabled={disabledflag}
																	labelId="demo-simple-select-label"
																	id="demo-simple-select"
																	value={Plantype_filter}
																	// label="Age"
																	onChange={handleChange_filter_PlanType}
																>
																	{filterPlantype_array.map((data, index) => (
																		<MenuItem key={index} value={data.plan_type_code} sx={{ height: "60px" }}>{data.plan_type_code}</MenuItem>
																	))}
																</Select>

															</FormControl>

														</Grid>

														<Grid xs={2.5} className={"grid-left"}>
															<br></br>
															<div className="label_text1">Desired Frequency</div>
															<FormControl className={"Drpdownselect"}>
																<Select
																	disabled={disabledflag}
																	labelId="demo-simple-select-label"
																	id="demo-simple-select"
																	value={DesiredFrequency_filter}
																	// label="Age"
																	onChange={handleChange_filter_DesiredFrequency}
																>
																	{filterDesiredFrequency_array.map((data, index) => (
																		<MenuItem key={index} value={data.id} sx={{ height: "60px" }}>{data.value}</MenuItem>
																	))}
																</Select>

															</FormControl>

														</Grid>

														<Grid xs={1} className={"grid-left"}>
															<br></br>
															<div className="label_text1">Gender</div>
															<FormControl className={"Drpdownselect"}>
																<Select
																	disabled={disabledflag}
																	labelId="demo-simple-select-label"
																	id="demo-simple-select"
																	value={Gender_filter}
																	// label="Age"
																	onChange={handleChange_filter_Gender}
																>
																	{filtergender_array.map((data, index) => (
																		<MenuItem key={index} value={data.gender_code} sx={{ height: "60px" }}>{data.gender_name}</MenuItem>
																	))}
																</Select>

															</FormControl>

														</Grid>

														<Grid xs={1} className={"grid-left"}>
															<br></br>
															<div className="label_text1">Zip Code</div>
															<TextField id="outlined-basic"
																disabled={disabledflag}
																type="number"
																value={Zipcode_filter}
																onChange={handleChange_filter_zipcode}
																variant="outlined"
																className={"zipcode_inputbox"} />

														</Grid>

														<Grid xs={2.5} className={"grid-left"}>
															<br></br>
															<div className="label_text">Start-Date/Renewal-Date</div>
															<LocalizationProvider dateAdapter={AdapterDayjs}>
																<DesktopDatePicker className="customDatePicker"
																	disabled={disabledflag}
																	value={dayjs(Startdate_start_filter)}
																	onChange={handleChange_filter_Start_Date_Start} />
															</LocalizationProvider>
														</Grid>

														<Grid xs={0.3} className="labelto">		<br></br><br></br><br></br>
															To
														</Grid>

														<Grid xs={2.2} className={"grid-left"}>
															<br></br><br></br>
															<LocalizationProvider dateAdapter={AdapterDayjs}>
																<DesktopDatePicker className="customDatePicker"
																	disabled={disabledflag}
																	value={dayjs(Startdate_end_filter)}
																	onChange={handleChange_filter_Start_Date_end}
																/>
															</LocalizationProvider>
														</Grid>

														<Grid xs={2.5} className={"grid-left"}>
															<br></br>
															<div className="label_text">Date of Birth</div>
															<LocalizationProvider dateAdapter={AdapterDayjs}>
																<DesktopDatePicker className="customDatePicker"
																	disabled={disabledflag}
																	value={dayjs(DateofBirth_start_filter)}
																	onChange={handleChange_filter_DateofBirth_filter_Start} />
															</LocalizationProvider>
														</Grid>

														<Grid xs={0.3} className="labelto">		<br></br><br></br><br></br>
															To
														</Grid>

														<Grid xs={2.2} className={"grid-left"}>
															<br></br><br></br>

															<LocalizationProvider dateAdapter={AdapterDayjs}>
																<DesktopDatePicker className="customDatePicker"
																	disabled={disabledflag}
																	value={dayjs(DateofBirth_end_filter)}
																	onChange={handleChange_filter_DateofBirth_filter_end} />
															</LocalizationProvider>
														</Grid>

														<Grid xs={2} className={"grid-left"}>
															<br></br>

															<Button
																disabled={disabledflag}
																variant="contained"
																aria-label="Apply Filters"
																type="submit"
																className={disabledflag === true ||
																	Startdate_start_filter === "Invalid Date" || DateofBirth_end_filter === "Invalid Date" ||
																	Startdate_end_filter === "Invalid Date" || DateofBirth_start_filter === "Invalid Date"
																	?
																	"disable_ApplyFiltersebtn" : "ApplyFiltersebtn"}
																onClick={() => handle_Applyfilters()}
															>

																Apply Filters
															</Button>
															<Button
																variant="text"
																className="ClearFiltersbtn"
																aria-label="Clear Filters"
																onClick={handle_clear_filters}
															>
																Clear Filters
															</Button>
														</Grid>
													</Grid>

													<Grid container spacing={2} className={"filter_GridContainer2"}>
														<Grid xs={3} className={"grid-left"}><br></br><br></br>
															<div className="textfield-container">
																<TextField id="outlined-basic" placeholder="Search by name or Member ID" variant="outlined" 	className="searchinput"
																	disabled={disabledflag} onChange={(event) => handlechange_MemberIDText_Filter(event)} value={tempsearchdata_value}
																	InputProps={{
																		endAdornment: (
																			<InputAdornment position="end">
																				{tempsearchdata_value.length > 0 ? (
																					<IconButton
																						onClick={() => clearMemberSearch()}
																					>
																						<CloseIcon />
																					</IconButton>
																				) : null}
																				<SearchIcon color="primary"/>
																			</InputAdornment>
																		)
																	}}
																/>
															</div>
														</Grid>

														<Grid xs={2} className={"grid-left"}><br></br><br></br>
															<Button
																disabled={disabledflag}
																variant="contained"
																aria-label="Search"
																type="submit"
																className={disabledflag === true ? "disable_SaveAsDraft_btn" : "Search_btn"}
																onClick={(event) => handle_Search_btn("DraftCampaign")}
															>

																Search
															</Button>
														</Grid>

														<Grid xs={5}><br></br>

														</Grid>

														<Grid xs={2} className={"grid-left"}><br></br><br></br>
															<Button
																disabled={disabledflag}
																variant="contained"
																aria-label="Upload Member List"
																type="submit"
																className={disabledflag === true ? "disable_SaveAsDraft_btn" : "Search_btn"}
																onClick={() => handle_Upload_Member_List_btn()}
															>

																Upload Member List
															</Button>
														</Grid>
													</Grid>


													<Grid container spacing={2}>
														<Grid xs={12}><br></br><br></br>
															<Box>
																<DataGrid sx={{
																	minHeight: '150px', height: 'auto', width: '100%', overflow: 'auto',
																}}
																	rows={Rows}
																	columns={Column}
																	hideFooter={true}
																	checkboxSelection
																	disableColumnMenu
																	rowSelectionModel={defaultcheckedboxSelection}
																	onRowSelectionModelChange={handleSelectionRowChange}
																	onCellClick={handleCellClick}
																	isRowSelectable={(params) => !disabledflag}  // Disable row selection based on flag
																	isCellSelectable={(params) => !disabledflag}
																	autoHeight
																	disableColumnResize={true}
																	components={{
																		Checkbox: (props) => (
																			<Checkbox {...props}
																				className="MuiCheckbox-root" />
																		),

																	}}

																/>
															</Box>
														</Grid>

														<Grid xs={4} sx={{ display: "flex", justifyContent: "flex-start" }}>
															<span className="Paginationlabel_text">Show &nbsp;&nbsp;</span>
															<FormControl className={"Drpdownselect_pagination"}>
																<Select
																	disabled={disabledflag}
																	labelId="demo-simple-select-label"
																	id="demo-simple-select"
																	value={PaginationRow_items}

																	onChange={(event) => handleChange_PaginationRow_itemschange(event, "Draftcampaign")}

																>
																	{PaginationRowsarray.map((data, index) => (
																		<MenuItem key={index} value={data.id} sx={{ height: "60px" }}>{data.value}</MenuItem>
																	))}
																</Select>

															</FormControl>
															<span className="Paginationlabel_text"> &nbsp; Entries </span>

														</Grid >

														<Grid xs={2}><br></br>
															<div className="Paginationlabel_MemberSelectedtext">{is_checked.length} Member Selected</div>

														</Grid>

														<Grid xs={6} sx={{ display: "grid", justifyContent: "flex-end" }}>
															<Stack spacing={2}>
																<Pagination
																	disabled={disabledflag}
																	count={totalpagecount} // Total number of pages
																	variant="outlined"
																	shape="rounded"
																	page={page} // Current page number
																	onChange={(event, value) => handleChange_Pagination(event, value, "Draftcampaign")}
																/>
															</Stack>
														</Grid>
													</Grid>
												</AccordionDetails>
											</Accordion>
										}

									</div>
									:
									<div>
										<Accordion expanded={expanded.panel1} onChange={handleChange_accordion('panel1')}>
											<AccordionSummary className={"accordion_header"}
												expandIcon={expanded.panel1 ? <img src="/asset/Agent/arrowdowncampaign.png" alt="Up Icon" /> : <img src="/asset/Agent/arrowdowncampaign.png" alt="Down Icon" />}
												aria-controls="panel1-content"
												id="panel1-header"
											>
												<Typography className="text1_css">Campaign Information</Typography>
											</AccordionSummary>
											<AccordionDetails>
												<Grid container spacing={2}>
													<Grid container xs={5.5} className="paddingleftright">
														<Grid xs={4} className={"grid-left"}><br></br>
															<div className="label_text1">
																Campaign Type
															</div>
															<div className="label_text">
																{CampaignType_Published}
															</div>
														</Grid>
														<Grid xs={4} className={"grid-left"}><br></br>
															<div className="label_text1">
																Start Date & Time:
															</div>
															<div className="label_text">
																{startdate_Published} {starttime_Published}
															</div>
														</Grid>
														<Grid xs={4} className={"grid-left"}><br></br>
															<div className="label_text1">
																End Date & Time:
															</div>
															<div className="label_text">
																{enddate_Published} {endtime_Published}
															</div>
														</Grid>

														<Grid xs={4} className={"grid-left"}><br></br>
															<div className="label_text1">
																Campaign Selected
															</div>
															<div className="label_text">
																{campaignSelected_Published}
															</div>
														</Grid>

														<Grid xs={4}><br></br>

														</Grid>

														<Grid xs={4} className={"grid-left"}>
															<Button
																variant="contained"
																aria-label="Preview"
																type="submit"
																className={"Previewbtn"}
																style={{ marginBottom: "15px" }}
																onClick={handlePreviewClick}
															>
																Preview
															</Button>

														</Grid>

														<Grid container xs={12}>
															<Grid xs={8.5} className={"textlabel_description_outerdiv_campaigntracker"}
																style={{ borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px" }}>
																<div className={"textlabel_description"}>
																	<span style={{ fontWeight: "bold" }}>Description</span>
																</div>

																<div className={"textlabel_description"}>
																	<div style={{ overflow: "auto", height: "150px" }}>{Description_Published}</div>
																</div>
															</Grid>

															<Grid xs={0.5} className={"textlabel_description_outerdiv_campaigntracker"}>
																<img src="/asset/Agent/Linebig.png" alt="Icon" className={"Lineimage"} />
															</Grid>

															<Grid xs={3} className={"textlabel_description_outerdiv_campaigntracker"}
																style={{ textAlign: "center", borderTopRightRadius: "5px", borderBottomRightRadius: "5px" }}>
																<div className="label_text1"><br></br><br></br><br></br><br></br><br></br>
																	Criticality Level
																</div>
																<div className="label_text">
																	{Criticalllevel_Published}
																</div>
															</Grid>

														</Grid>

													</Grid>

													<Grid xs={0.5} >
														<img src="/asset/Agent/Linebig.png" alt="Icon" style={{ height: "100%" }} />
													</Grid>

													<Grid xs={3} className="bargraphgrid"><br></br>
														<div className="ChartTitle">Chart Title</div><br></br>

														<Bargraph barchartData={barchartdata_Published} />

													</Grid>

													<Grid xs={0.5} >
														<img src="/asset/Agent/Linebig.png" alt="Icon" style={{ height: "100%" }} />
													</Grid>

													<Grid xs={2.5} className="bargraphgrid">
														<CountdownTimer
															startDateTime={campaignStartDateTime} endDateTime={campaignEndDateTime} currentDateTime={currentDateTimenow} />
													</Grid>
												</Grid>
											</AccordionDetails>
										</Accordion>

										<Accordion expanded={expanded.panel2} onChange={handleChange_accordion('panel2')}>
											<AccordionSummary className={"accordion_header"}
												expandIcon={expanded.panel2 ? <img src="/asset/Agent/arrowdowncampaign.png" alt="Up Icon" /> : <img src="/asset/Agent/arrowdowncampaign.png" alt="Down Icon" />}
												aria-controls="panel1-content"
												id="panel1-header"
											>
												<Typography className="text1_css">Controls</Typography>
											</AccordionSummary>
											<AccordionDetails>
												<Grid container spacing={2}>
													<Grid xs={3} className={"grid-left"}><br></br>
														<div className="textfield-container">
															<TextField id="outlined-basic" placeholder="Search by name or Member ID" variant="outlined" 	className="searchinput"
																disabled={disabledflag} onChange={(event) => handlechange_MemberIDText_Filter(event)} value={tempsearchdata_value}
																InputProps={{
																	endAdornment: (
																		<InputAdornment position="end">
																			{tempsearchdata_value.length > 0 ? (
																				<IconButton
																					onClick={() => clearMemberSearch()}
																				>
																					<CloseIcon />
																				</IconButton>
																			) : null}
																			<SearchIcon color="primary"/>
																		</InputAdornment>
																	)
																}}
															/>
														</div>
													</Grid>

													<Grid xs={2} className={"grid-left"}><br></br>
														<Button
															variant="contained"
															aria-label="Search"
															type="submit"
															className="Search_btn"
															onClick={(event) => handle_Search_btn("PublishedCampaign")}
														>

															Search
														</Button>
													</Grid>

													<Grid xs={1}><br></br>

													</Grid>

													<Grid xs={2} className={"grid-left"}><br></br>
														<Button
															variant="contained"
															aria-label="Generate report"
															type="submit"
															className={CampaignType === "Promotion" ? "disable_SaveAsDraft_btn" : "Search_btn"}
															// className="Search_btn"
															onClick={() => generateReportbtn()}
														>

															Generate report
														</Button>
													</Grid>

													<Grid xs={2} className={"grid-left"}><br></br>
														<Button
															variant="contained"
															aria-label="Administratortocall"
															type="submit"
															className="btnvarianttype2"
															onClick={() => handle_AdministratorCall()}
														>
															Administrator to call
														</Button>
													</Grid>

													<Grid xs={2} className={"grid-left"}><br></br>

														<Button
															variant="contained"
															aria-label="AdministratorNotifications"
															type="submit"
															className="btnvarianttype2"
															onClick={() => handle_AdministratorNotifications()}
														>

															Administrator Notifications
															<div className='notificationboxdiv'>{NotificationCount}</div>

														</Button>
													</Grid>
												</Grid>

												<Grid container spacing={2}>
													<Grid xs={12}><br></br><br></br>
														<Box >
															<DataGrid sx={{ minHeight: '150px', height: 'auto', width: '100%', overflow: 'auto' }}
																rows={Rows1}
																columns={Column1}
																hideFooter={true}
																onCellClick={handleCellClick}
																disableColumnMenu
																autoHeight
																disableColumnResize={true}

															/>
														</Box>
														{ }

														<Box className={notoficationoverlaydiv === true ? "notoficationoverlaydiv" : "notoficationoverlaydiv_close"} id="notoficationoverlaydivID">

															<img src="/asset/Agent/arrowright.png" alt="arrowright" className='notificationoverlaydivimg'
																onClick={handleclose_AdministratorNotifications} />
															<div className="notificationoverlaydiv-content">

																<div className='notification_tablecontent'>

																	<Grid container style={{ paddingLeft: "40px", paddingRight: "20px" }}>
																		<Grid xs={12} className={"grid-left"}><br></br>
																			<div className="label_text_notofication">Call List</div>


																		</Grid>

																		<Grid xs={2.5} className={"grid-left"}><br></br>
																			<div className="label_text_notofication">Member ID</div>

																		</Grid>

																		<Grid xs={2.5} className={"grid-left"}><br></br>
																			<div className="label_text_notofication">First Name</div>

																		</Grid>

																		<Grid xs={2.5} className={"grid-left"}><br></br>
																			<div className="label_text_notofication">Last Name</div>

																		</Grid>

																		<Grid xs={2} className={"grid-left"}><br></br>
																			<div className="label_text_notofication">Call Now</div>

																		</Grid>


																		<Grid xs={2.5} className={"grid-left"}><br></br>
																			<div className="label_text_notofication">Action</div>

																		</Grid>

																		{OverlayDiv_Resolvebtnclicked === true &&
																			<Grid xs={12} className={"grid-left"}>
																				<div className="textfield-container_overlaydiv">

																					<TextField id="outlined-basic" placeholder="Type your comment" variant="outlined" className="searchinput_overlaydiv"
																						onKeyUp={(event) => handlechange_OverlayDiv_comment(event)} />

																					<Button
																						variant="contained"
																						aria-label="Submit"
																						type="submit"
																						className={OverlayDiv_typecomment.trim() === "" ? "disabledresolve_Submitbtn" : "resolve_Submitbtn"}
																						onClick={() => handle_OverlayDiv_Submit()}
																					>

																						Submit


																					</Button>
																				</div>
																			</Grid>
																		}


																		<Grid xs={2.5} className={"grid-left"}>
																			{Call_apidata.map((item, index) => (
																				<div className="label_text1-notofication" key={index} onClick={() => handleclick_notificationoverlay_item(item)}><br></br>{item.member_id}</div>
																			))
																			}
																		</Grid>



																		<Grid xs={2.5} className={"grid-left"}>
																			{Call_apidata.map((item, index) => (
																				<div className="label_text1-notofication" key={index} onClick={() => handleclick_notificationoverlay_item(item)}><br></br>
																					<Tooltip title={item.first_name} arrow>
																						<span>{item.first_name}</span>
																					</Tooltip>


																				</div>
																			))
																			}
																		</Grid>

																		<Grid xs={2.5} className={"grid-left"}>
																			{Call_apidata.map((item, index) => (
																				<div className="label_text1-notofication" key={index} onClick={() => handleclick_notificationoverlay_item(item)}><br></br>
																				<Tooltip title={item.last_name} arrow>
																				{item.last_name}
																				</Tooltip>
																				</div>
																			))
																			}
																		</Grid>

																		<Grid xs={2} className={"grid-left"}><br></br>
																			{Call_apidata.map((item, index) => (
																				<div key={index}>
																					{
																						item.is_resolved === true ?

																							<img src="/asset/Agent/call-green.png" alt="call" style={{ width: "24px", marginBottom: "10px", marginTop: "-5px", opacity: "0.3" }} />
																							:
																							<img src="/asset/Agent/call-green.png" alt="call" style={{ width: "24px", marginBottom: "10px", marginTop: "-5px" }} />


																					}

																				</div>

																			))
																			}
																		</Grid>

																		<Grid xs={2.5} className={"grid-left"}><br></br>
																			{Call_apidata.map((item, index) => (
																				<span key={index}>
																					{
																						item.is_resolved === true ?
																							<div key={index} className="Resolvedbtn">
																								Resolved
																							</div>

																							:
																							<Button
																								key={index}
																								variant="contained"
																								aria-label="Resolve"
																								type="submit"
																								className="Resolvebtn"
																								onClick={() => handle_OverlayDiv_Resolve(item.member_id)}
																							>
																								Resolve
																							</Button>
																					}
																				</span>

																			))
																			}
																		</Grid>


																	</Grid>

																</div>
															</div>
														</Box>
													</Grid>

													<Grid xs={4} sx={{ display: "flex", justifyContent: "flex-start" }}>
														<span className="Paginationlabel_text">Show &nbsp;&nbsp;</span>
														<FormControl className={"Drpdownselect_pagination"}>
															<Select
																labelId="demo-simple-select-label"
																id="demo-simple-select"
																value={PaginationRow_items}
																// label="Age"
																onChange={(event) => handleChange_PaginationRow_itemschange(event, "Publishedcampaign")}
															>
																{PaginationRowsarray.map((data, index) => (
																	<MenuItem key={index} value={data.id} sx={{ height: "60px" }}>{data.value}</MenuItem>
																))}
															</Select>

														</FormControl>
														<span className="Paginationlabel_text"> &nbsp; Entries </span>

													</Grid >

													<Grid xs={2}><br></br>


													</Grid>

													<Grid xs={6} sx={{ display: "grid", justifyContent: "flex-end" }}>
														<Stack spacing={2}>
															<Pagination
																count={totalpagecount}// Total number of pages
																variant="outlined"
																shape="rounded"
																page={page} // Current page number
																onChange={(event, value) => handleChange_Pagination(event, value, "Publishedcampaign")}
															/>
														</Stack>
													</Grid>
												</Grid>

											</AccordionDetails>
										</Accordion>
									</div>
								}
							</div>
						)}

					</Grid>
				}
			</Grid>

			{createCampaign === false && deleteCampaign === false && isPublished === false && apicalledgetdetails === true &&
				<Grid container spacing={2} className="Mainbtncontainer">
					<Grid xs={2} className={"grid-left"}><br></br><br></br>
						<Button
							variant="contained"
							aria-label="Create"
							type="submit"
							className={Campaignselector === "" || startDob === "Invalid Date" || endDob === "Invalid Date" ? "disable_SaveAsDraft_btn" : "SaveAsDraft_btn"}
							onClick={handleCreate}
						>

							Create
						</Button>
					</Grid>

					<Grid xs={2} className={"grid-left"}><br></br><br></br>
						<Button

							variant="contained"
							aria-label="Cancel"
							type="submit"
							className="Delete_btn"
							onClick={handleCancel}
						>

							Cancel
						</Button>
					</Grid>
				</Grid>
			}


			{createCampaign === true && deleteCampaign === false && isPublished === false &&
				<Grid container spacing={2} className="Mainbtncontainer">
					<Grid xs={2} className={"grid-left"}><br></br><br></br>
						<Button
							disabled={disabledflag}
							variant="contained"
							aria-label="Save As Draft"
							type="submit"
							className={disabledflag === true ? "disable_SaveAsDraft_btn" : "SaveAsDraft_btn"}
							onClick={() => handle_Draft_btn(selectedTab)}
						>

							Save As Draft
						</Button>
					</Grid>

					<Grid xs={2} className={"grid-left"}><br></br><br></br>
						<Button
							disabled={disabledflag}
							variant="contained"
							aria-label="Delete"
							type="submit"
							className={disabledflag === true ? "disable_SaveAsDraft_btn" : "Delete_btn"}
							onClick={handleDelete}
						>

							Delete
						</Button>
					</Grid>

					<Grid xs={6}>

					</Grid>

					<Grid xs={2} className={"grid-left"}><br></br><br></br>
						<Button
							disabled={disabledflag}
							variant="contained"
							aria-label="Publish"
							type="submit"
							className={disabledflag === false ? "Publish_btn" : "disable_Publish_btn"}
							onClick={() => handle_Publish_btn()}
						>

							Publish
						</Button>
					</Grid>
				</Grid>
			}

			<GlobalAlertPopup
				isOpen={isModalOpen}
				Heading={enddate_warningmsg ? "End date and time cannot be earlier than the start date and time!" : "You have opened maximum tabs!"}
				body=""
				btnname1="OK"
				handleOKButton={handleOKButton}
			/>

			{startdate_warningmsg === true &&
				<GlobalAlertPopup
					isOpen={isModalOpen}
					Heading="Start date and time cannot be in the past!"
					body=""
					btnname1="OK"
					handleOKButton={handleOKButton}
				/>
			}

			<GlobalAlertPopup2
				isOpen={isModalOpenGlobalAlertPopup2}
				Heading={isModalOpenGlobalAlertPopup2heading}
				body=""
				btnname1="OK"
				handleOKButton={handleOKButton_GlobalAlertPopup2}
			/>

			<GlobalSucessPopup
				isOpen={isModalOpenSuccessPopup}
				Heading="Your campaign has been successfully published"
				body=""
				btnname1="OK"
				handleOKButton={handleOKButton_successPopup}

			/>

			<SurveyPreviewPopup
				code={Campaignselector}
				isOpen={isSurveyPreviewModalOpen}
				handleOKButton={handleSurveyPreviewOKButton}
				isUpdated={handleIsUpdated}
				initialSurveyName={surveyName}
				reference={reference}
				campaignCode={campaignCode}
				disabledflag={disabledflag}
				isEditedFlag={isEditedFlag}
			/>

			<AutoResponsePopup
				isOpen={isAutoResponseModalOpen}
				handleOKButton={handleAutoResponseOKButton}
				campaignCode={campaignCode}
				autoresponseapidata={autoresponseapidata}
			/>

			<CreateSurveyPopup
				code={Campaignselector}
				isOpen={isCreateSurveyModalOpen}
				handleOKButton={handleCreateSurveyOKButton}
				isUpdated={handleIsUpdated}
				initialSurveyName={surveyName}
				reference={reference}
				campaignCode={campaignCode}
				disabledflag={disabledflag}
				isEditedFlag={isEditedFlag}
				automaticReminder={AutomaticReminder === "Off" ? false : true}
			/>

			<CreatePromotionPopup
				code={Campaignselector}
				isOpen={isCreatePromotionModalOpen}
				handleOKButton={handleCreatePromotionOKButton}
				isUpdated={handleIsUpdated}
				initialPromotionName={promotionName}
				reference={reference}
				disabledflag={disabledflag}
				isEditedFlag={isEditedFlag}
			/>

			<GlobalConfirmationPopuptab_change
				isOpen={isModalOpenGlobaltabchnage}
				Heading="Save Changes!"
				body="Hi there! Do you want to save the changes you made?"
				btnname1="YES"
				btnname2="NO"
				handleYesButton={handleYesButton_tabchange}
				handleNoButton={handleNoButton_tabchange}
			/>

			<GlobalConfirmationPopup
				isOpen={isModalOpenConfirmationPopup}
				Heading="Publish!"
				body="Hi there! This will save the changes and Publish. Do you want to continue?"
				btnname1="YES"
				btnname2="NO"
				handleYesButton={handleYesButton}
				handleNoButton={handleNoButton}
			/>

			{deletepopup === true &&
				<GlobalConfirmationDeletePopup
					isOpen={isModalOpendeletePopup}
					Heading="Warning!"
					body="Are you sure you want to delete this campaign?"
					btnname1="Yes"
					btnname2="No"
					handleYesButton={handleYesButton_delete}
					handleNoButton={handleNoButton_delete}
				/>
			}

			{cancelpopup === true &&
				<GlobalConfirmationDeletePopup
					isOpen={isModalOpendeletePopup}
					Heading="Warning!"
					body="Are you sure you want to cancel this campaign?"
					btnname1="Yes"
					btnname2="No"
					handleYesButton={handleYesButton_cancel}
					handleNoButton={handleNoButton_cancel}
				/>
			}

			<MemberSnapshotPopup
				isOpen={isMemberSnapshotPopupModalOpen}
				handleOKButton={handleMemberSnapshotOKButton}
				from="campaignpage"
				data={getmemberID}
			/>

			<Publish_ErrorPopup
				isOpen={Publish_ErrorPopupmodal}
				Heading="Warning"
				body="Some fields in the <Survey/Promotion> are incomplete. Please click Edit to update."
				btnname1="Cancel"
				btnname2="Edit"
				handlecancelButton={handlecancelButton}
				handleEditButton={handleEditButton}
			/>

			<UploadmemberListPopup
				isOpen={UploadmemberListPopupmodal}
				handleOKButton={handlecancelUploadmemberListButton}
			/>

			<CampaignHistoryPopup
				isOpen={CampaignHistoryPopupmodal}
				handleOKButton={handleCampaignHistoryPopupmodalButton}
			/>

		</Box>
	)
};

export default CampaignCreatorTracker;
