import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";

import { Accordion, AccordionSummary, AccordionDetails, Modal, TextField, MenuItem, Box, Button, Grid } from "@mui/material";

import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML, convertFromRaw, convertToRaw, } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { htmlToDraft } from "draft-js-import-html";
import { stateFromHTML } from "draft-js-import-html";
import Apiservice from "../../../Services/Apiservice";

import { URL } from "../../../Constants/apiUrl";
import RichTextEditor from "./../../Commonfields/RichTextEditorComponent";
import UpdateSurveyName from "../UpdateSurveyNamePopup/UpdateSurveyNamePopup";
import { promotionNames, updatePromotionNameVariant } from "../../../Constants/static";
import "./CreatePromotionPopup.css";
import CommoncircularProgress from "../../../Shared/CommoncircularProgress/CommoncircularProgress";

function CreatePromotionPopup({ code1, isOpen, handleOKButton, isUpdated, initialPromotionName, reference, disabledflag, isEditedFlag }) {

	let code = sessionStorage.getItem("setCampaignselector");

	let promotionArray = sessionStorage.getItem("promotionArray")	
    
    if (promotionArray === null){
        promotionArray = []
    }

	var req_body = {
		promotion_name: "",
		promotion_desc: "",
		is_custom: 1,
		is_previewed: 0,
		promotion_header: {
			en: "",
			es: "",
			vi: "",
		},
		promotion_information: {
			en: "",
			es: "",
			vi: "",
		},
		invite_reminder_list: [
			{
				sms_text: {
					en: "",
					es: "",
					vi: "",
				},
				email_subject: "",
				email_body: "",
				invite_code: "NRSMS",
				is_created: 1,
				schedule_date: "1964-05-22 19:30:34",
			},
			{
				sms_text: "",
				email_subject: {
					en: "",
					es: "",
					vi: "",
				},
				email_body: {
					en: "",
					es: "",
					vi: "",
				},
				invite_code: "NREML",
				is_created: 1,
				schedule_date: "1964-05-22 19:30:34",
			},
			{
				sms_text: {
					en: "",
					es: "",
					vi: "",
				},
				email_subject: "",
				email_body: "",
				invite_code: "FRSMS",
				is_created: 1,
				schedule_date: "1964-05-22 19:30:34",
			},
			{
				sms_text: "",
				email_subject: {
					en: "",
					es: "",
					vi: "",
				},
				email_body: {
					en: "",
					es: "",
					vi: "",
				},
				invite_code: "FREML",
				is_created: 1,
				schedule_date: "1964-05-22 19:30:34",
			},
			{
				sms_text: {
					en: "",
					es: "",
					vi: "",
				},
				email_subject: "",
				email_body: "",
				invite_code: "FNSMS",
				is_created: 1,
				schedule_date: "1964-05-22 19:30:34",
			},
			{
				sms_text: "",
				email_subject: {
					en: "",
					es: "",
					vi: "",
				},
				email_body: {
					en: "",
					es: "",
					vi: "",
				},
				invite_code: "FNEML",
				is_created: 1,
				schedule_date: "1964-05-22 19:30:34",
			},
		],
	};

	const invitations_reminders = [
		{
			id: "sms_invitation",
			title: "SMS Invitation",
			description: "SMS Invitation Description",
		},
		{
			id: "email_invitation",
			title: "Email Invitation",
			description: "Email Invitation Description",
		},
		{
			id: "first_reminder_sms",
			title: "First Reminder SMS",
			description: "First Reminder SMS Description",
		},
		{
			id: "first_reminder_email",
			title: "First Reminder Email",
			description: "First Reminder Email Description",
		},
		{
			id: "final_reminder_sms",
			title: "Final Reminder SMS",
			description: "Final Reminder SMS Description",
		},
		{
			id: "final_reminder_email",
			title: "Final Reminder Email",
			description: "Final Reminder Email Description",
		},
	];

	const initialFlagsPrebuilt = {
		"email_invitation": false,
		"final_reminder_email": false,
		"final_reminder_sms": false,
		"first_reminder_email": false,
		"first_reminder_sms": false,
		"sms_invitation": false,
	}

	const initialFlagsGeneric = {
		"email_invitation": false,
		"final_reminder_email": false,
		"final_reminder_sms": false,
		"first_reminder_email": false,
		"first_reminder_sms": false,
		"sms_invitation": false,
	}

	const [payload, setPayload] = useState(req_body);
	const [expanded, setExpanded] = useState("panel1");
	const [focusStates, setFocusStates] = useState({
		promotionName: false,
		promotionDescription: false,
		question: false,
		headerText: false,
		promotionInfo: false,
	});
	const [isLoading, setIsLoading] = useState(false);
	const [language, setLanguage] = useState("en");

	const [promotionTemplateDisplay, setpromotionTemplateDisplay] = useState(true);
	const [promotionInfoTemplateDisplay, setpromotionInfoTemplateDisplay] = useState(true);
	const [smsInvitationDisplay, setSmsInvitationDisplay] = useState(false);
	const [emailInvitationDisplay, setEmailInvitationDisplay] = useState(false);
	const [selectedSmsEmail, setSelectedSmsEmail] = useState("");
	const [emailSubject, setEmailSubject] = useState("");
	const [firstemailSubject, setFirstEmailSubject] = useState("");
	const [finalemailSubject, setFinalEmailSubject] = useState("");
	const [emailBody, setEmailBody] = useState("");
	const [firstemailBody, setFirstEmailBody] = useState("");
	const [finalemailBody, setFinalEmailBody] = useState("");
	const [messageText, setMessageText] = useState("");
	const [firstmessageText, setFirstMessageText] = useState("");
	const [finalmessageText, setFinalMessageText] = useState("");
	const [bodyTextError, setBodyTextError] = useState("");
	const [isRichEditorFocused, setIsRichEditorFocused] = useState(false);

	const containerRef = useRef(null);
	const editorRef = useRef(null);
	const [result, setResult] = useState(null);

	const [previousValue, setPreviousValue] = useState("");
	const [currentValue, setCurrentValue] = useState("");
	const [selectedDescription, setSelectedDescription] = useState("");

	const [isInfoDisplay, setIsInfoDisplay] = useState(true);
	const [isDetailsDisplay, setIsDetailsDisplay] = useState(false);

	const [promotionName, setPromotionName] = useState("");
	const [promotionDesc, setPromotionDesc] = useState("");

	const [promotionNameError, setPromotionNameError] = useState(null);
	const [promotionDescError, setPromotionDescError] = useState(null);

	const [promotionNameFocus, setPromotionNameFocus] = useState(false);
	const [promotionDescFocus, setPromotionDescFocus] = useState(false);

	const [headerText, setHeaderText] = useState(null);
	const [promotionInfo, setPromotionInfo] = useState(null);

	const [headerTextError, setHeaderTextError] = useState(null);
	const [promotionInfoError, setPromotionInfoError] = useState(null);

	const [headerTextFocus, setHeaderTextFocus] = useState(false);
	const [promotionInfoFocus, setPromotionInfoFocus] = useState(false);

	const [questionError, setQuestionError] = useState("");
	const [messageTextError, setMessageTextError] = useState(null);
	const [subjectError, setSubjectError] = useState(null);

	const [childModalopen, setChildModalOpen] = useState(false);

	const [promotionUpdateVariant, setPromotionUpdateVariant] = useState("")
	const [checkVariant, setCheckVariant] = useState("")
	const [flags, setFlags] = useState(initialFlagsPrebuilt)
	const isFirstRender = useRef(true);
	const [editedFlag, setEditedFlag] = useState(isEditedFlag)
	const [infoLangChange, setInfoLangChange] = useState(false)


	const resetState = () => {
		setPayload(req_body);
		setExpanded("panel1");
		setFocusStates({
			promotionName: false,
			promotionDescription: false,
			question: false,
			headerText: false,
			promotionInfo: false,
		});
		setIsLoading(false);
		setLanguage("en");

		setpromotionTemplateDisplay(true);
		setpromotionInfoTemplateDisplay(false);
		setSmsInvitationDisplay(false);
		setEmailInvitationDisplay(false);
		setSelectedSmsEmail("");
		setEmailSubject("");
		setFirstEmailSubject("");
		setFinalEmailSubject("");
		setEmailBody("");
		setFirstEmailBody("");
		setFinalEmailBody("");
		setMessageText("");
		setFirstMessageText("");
		setFinalMessageText("");
		setBodyTextError("");
		setIsRichEditorFocused(false);

		// Reset refs
		// containerRef.current = null;
		// editorRef.current = null;
		setResult(null);

		setPreviousValue("");
		setCurrentValue("");
		setSelectedDescription("");

		setIsInfoDisplay(true);
		setIsDetailsDisplay(false);

		setPromotionName("");
		setPromotionDesc("");

		setPromotionNameError(null);
		setPromotionDescError(null);

		setPromotionNameFocus(false);
		setPromotionDescFocus(false);

		setHeaderText(null);
		setPromotionInfo(null);

		setHeaderTextError(null);
		setPromotionInfoError(null);
		setHeaderTextFocus(false);
		setPromotionInfoFocus(false);
		setQuestionError("");
		setMessageTextError(null);
		setSubjectError(null);
		setChildModalOpen(false);
		setPromotionUpdateVariant("");
		setCheckVariant("");
		setFlags(initialFlagsPrebuilt);

		setEditedFlag(isEditedFlag);
		// Reset isFirstRender ref
		// isFirstRender.current = true;
	};



	// Event handler for focus
	const handleFocus = (fieldName, value) => {
		if (value) setPreviousValue(value);

		setPromotionNameFocus(false);
		setPromotionDescFocus(false);
		setHeaderTextFocus(false);
		setPromotionInfoFocus(false);
		if (fieldName == "promotionName") {
			setPromotionNameFocus(true);
		} else if (fieldName === "promotionDescription") {
			setPromotionDescFocus(true);
		} else if (fieldName === "headerText") {
			setHeaderTextFocus(true);
		} else if (fieldName === "promotionInfo") {
			setPromotionInfoFocus(true);
		}
		setFocusStates({ ...focusStates, [fieldName]: true });
	};

	// Event handler for blur
	const handleBlur = (fieldName) => {

		// setFocusStates({ ...focusStates, [fieldName]: false });
		if (fieldName === "promotionName") {
			if (currentValue === "") {
				setPromotionName(previousValue);
				setPromotionNameError("");
			}
			// detailsSave()
		} else if (fieldName === "promotionDescription") {
			if (currentValue === "") {
				setPromotionDesc(previousValue);
				setPromotionDescError("");
			}
			// detailsSave()
		}
	};

	const handleupdatedPromotionName = (promotion_name, variant) => {

		setPromotionName(promotion_name);
		setCheckVariant(variant)
		setEditedFlag(true)
	};

	useEffect(() => {
		setEditedFlag(true)

		if (checkVariant === updatePromotionNameVariant["save_desc"]) {
			detailsSave();
		}
		if (checkVariant === updatePromotionNameVariant["save_info"]) {
			infoSave();
		}
		if (checkVariant === updatePromotionNameVariant["save_inv"]) {
			smsEmailSave();
		}

	}, [checkVariant]);

	const handlePromNameChange = (e) => {
		setEditedFlag(true)

		setCurrentValue(e.target.value);
		setPromotionName(e.target.value);
		if (e.target.value === "") {
			setPromotionNameError("Promotion name cannot be empty");
		} else {
			setPromotionNameError("");
		}
	};

	const handlePromDescChange = (e) => {
		setCurrentValue(e.target.value);
		setPromotionDesc(e.target.value);
		if (e.target.value === "") {
			setPromotionDescError("Description cannot be empty");
		} else {
			setPromotionDescError("");
		}
	};

	const handleHeaderTextChange = (e) => {
		setCurrentValue(e.target.value);
		setHeaderText(e.target.value);
		if (e.target.value === "") {
			setHeaderTextError("Header cannot be empty");
		} else {
			setHeaderTextError("");
		}
	};

	const handleInfoTextChange = (newEditorState) => {

		setCurrentValue(newEditorState);
		setPromotionInfo(newEditorState);
		const currentContent = newEditorState.getCurrentContent();

		if (currentContent.hasText() === false) {
			setPromotionInfoError('Information cannot be empty');
		} else {
			setPromotionInfoError('');
		}
	};


	useEffect(() => {
		if (isFirstRender.current) {
			// Skip the effect on the initial render
			isFirstRender.current = false;
		} else {
			// This will run only when surveyUpdateVariant changes, not on initial load

			setChildModalOpen(true);
		}
	}, [promotionUpdateVariant]);


	const handleTextFieldCancel = (fieldName) => {
		if (fieldName === "promotionName") {
			setPromotionNameFocus(false);
			setPromotionName(previousValue);
		} else if (fieldName === "promotionDescription") {
			setPromotionDescFocus(false);
			setPromotionDesc(previousValue);
		}
	};

	const messageTextChange = (event) => {
		if (selectedSmsEmail === "sms_invitation") {
			setMessageText(event.target.value);
		} else if (selectedSmsEmail === "first_reminder_sms") {
			setFirstMessageText(event.target.value);
		} else {
			setFinalMessageText(event.target.value);
		}
		if (event.target.value === "") {
			setMessageTextError("Message text cannot be empty");
		} else {
			setMessageTextError("");
		}
	};

	const emailSubjectChange = (event) => {
		if (selectedSmsEmail === "email_invitation") {
			setEmailSubject(event.target.value);
		} else if (selectedSmsEmail === "first_reminder_email") {
			setFirstEmailSubject(event.target.value);
		} else {
			setFinalEmailSubject(event.target.value);
		}
		if (event.target.value === "") {
			setSubjectError("Subject cannot be empty");
		} else {
			setSubjectError("");
		}
	};

	const handleEmailBodyTextChange = (value) => {
		const rawContent = convertToRaw(value.getCurrentContent());
		const htmlContent = stateToHTML(value.getCurrentContent());
		if (selectedSmsEmail === "email_invitation") {
			setEmailBody(value);
		} else if (selectedSmsEmail === "first_reminder_email") {
			setFirstEmailBody(value);
		} else {
			setFinalEmailBody(value);
		}

		const currentContent = value.getCurrentContent();
		if (currentContent.hasText() === false) {
			setBodyTextError("Welcome Text cannot be empty");
		} else {
			setBodyTextError("");
		}
	};

	const findDescriptionById = (id) => {
		const foundItem = invitations_reminders.find((item) => item.id === id);
		if (foundItem) {
			setSelectedDescription(foundItem.description);
		} else {
			setSelectedDescription("");
		}
	};

	const detailsSave = () => {
		if (promotionArray.includes(promotionName)) {
			setPromotionUpdateVariant(updatePromotionNameVariant["save_desc"])
		}
		else {
			isUpdated(true);
			payload.promotion_name = promotionName;
			payload.promotion_desc = promotionDesc;
			setPayload(payload);
			setIsInfoDisplay(false);
			setIsDetailsDisplay(true);
			setPromotionNameFocus(false);
			setPromotionDescFocus(false);
			setHeaderTextFocus(false);
			setIsRichEditorFocused(false);
			if (code && code != "create_new" && isDraft) {
				setIsLoading(true);
				let apiurl = URL.UPDATE_PROMOTION;
				const body = {
					promotion_code: code,
					reference: reference,
					is_details: 1,
					promotion_name: promotionName,
					promotion_desc: promotionDesc,
					is_promotion_update: 0,
					is_invite_reminder: 0,
				};
				Apiservice.patchMethod(apiurl, body)
					.then((response) => {
						setIsLoading(false);
						localStorage.removeItem("promotionCreation");
					})
					.catch((error) => {
						setIsLoading(false);
						console.error("There was an error!", error);
					});
			}

			else {
				setIsLoading(true);
				setTimeout(() => {
					setIsLoading(false);
				}, 500);
			}
		}
	};

	const infoSave = () => {

		if (promotionArray.includes(promotionName)) {
			setPromotionUpdateVariant(updatePromotionNameVariant["save_info"])
		}

		else {
			setInfoLangChange(true)
			payload.promotion_information[language] = stateToHTML(promotionInfo.getCurrentContent());
			payload.promotion_header[language] = headerText;

			isUpdated(true);
			setPayload(payload);
			setIsInfoDisplay(false);
			setIsDetailsDisplay(true);
			setPromotionNameFocus(false);
			setPromotionDescFocus(false);

			if (code && code != "create_new" && isDraft) {
				setIsLoading(true);
				let apiurl = URL.UPDATE_PROMOTION;
				const body = {
					promotion_code: code,
					reference: reference,
					is_details: 0,
					promotion_header: {
						"en": payload.promotion_header["en"],
						"es": payload.promotion_header["es"],
						"vi": payload.promotion_header["vi"]
					},
					promotion_information: {
						"en": payload.promotion_information["en"],
						"es": payload.promotion_information["es"],
						"vi": payload.promotion_information["vi"]
					},
					is_invite_reminder: 0,
					is_promotion_update: 1,
				};
				Apiservice.patchMethod(apiurl, body)
					.then((response) => {
						setIsLoading(false);
						localStorage.removeItem("promotionCreation");
					})
					.catch((error) => {
						setIsLoading(false);
						console.error("There was an error!", error);
					});
			}

			else {
				setIsLoading(true);
				setTimeout(() => {
					setIsLoading(false);
				}, 500);
			}
		}
	}

	const smsEmailSave = () => {

		if (promotionArray.includes(promotionName)) {
			setPromotionUpdateVariant(updatePromotionNameVariant["save_inv"])
		}

		else {
			setInfoLangChange(true)
			isUpdated(true);
			if (selectedSmsEmail === "sms_invitation") {
				flags["sms_invitation"] = false
				payload.invite_reminder_list[0].sms_text[language] = messageText;
			} else if (selectedSmsEmail === "email_invitation") {
				flags["email_invitation"] = false
				payload.invite_reminder_list[1].email_subject[language] = emailSubject;
				payload.invite_reminder_list[1].email_body[language] = stateToHTML(emailBody.getCurrentContent());
			} else if (selectedSmsEmail === "first_reminder_sms") {
				flags["first_reminder_sms"] = false
				payload.invite_reminder_list[2].sms_text[language] = firstmessageText;
			} else if (selectedSmsEmail === "first_reminder_email") {
				flags["first_reminder_email"] = false
				payload.invite_reminder_list[3].email_subject[language] = firstemailSubject;
				payload.invite_reminder_list[3].email_body[language] = stateToHTML(firstemailBody.getCurrentContent());
			} else if (selectedSmsEmail === "final_reminder_sms") {
				flags["final_reminder_sms"] = false
				payload.invite_reminder_list[4].sms_text[language] = finalmessageText;
			} else if (selectedSmsEmail === "final_reminder_email") {
				flags["final_reminder_email"] = false
				payload.invite_reminder_list[5].email_subject[language] = finalemailSubject;
				payload.invite_reminder_list[5].email_body[language] = stateToHTML(finalemailBody.getCurrentContent());
			}
			setPayload(payload);


			if (code && code != "create_new" && isDraft) {

				var invite_reminder_list;
				if (selectedSmsEmail === "sms_invitation") {
					invite_reminder_list = payload.invite_reminder_list[0];
				} else if (selectedSmsEmail === "email_invitation") {
					invite_reminder_list = payload.invite_reminder_list[1];
				} else if (selectedSmsEmail === "first_reminder_sms") {
					invite_reminder_list = payload.invite_reminder_list[2];
				} else if (selectedSmsEmail === "first_reminder_email") {
					invite_reminder_list = payload.invite_reminder_list[3];
				} else if (selectedSmsEmail === "final_reminder_sms") {
					invite_reminder_list = payload.invite_reminder_list[4];
				} else if (selectedSmsEmail === "final_reminder_email") {
					invite_reminder_list = payload.invite_reminder_list[5];
				}

				setIsLoading(true);
				let apiurl = URL.UPDATE_PROMOTION;
				const body = {
					promotion_name: promotionName,
					promotion_desc: promotionDesc,
					promotion_code: code,
					reference: reference,
					is_details: 0,
					is_invite_reminder: 1,
					is_promotion_update: 0,
					invite_reminder_list: [invite_reminder_list],
				};

				Apiservice.patchMethod(apiurl, body)
					.then((response) => {
						setIsLoading(false);
						localStorage.removeItem("promotionCreation");
					})
					.catch((error) => {
						setIsLoading(false);
						console.error("There was an error!", error);
					});
			}

			else {
				setIsLoading(true);
				setTimeout(() => {
					setIsLoading(false);
				}, 500);
			}
		}
	};

	const surveyTemplate = () => {
		setpromotionTemplateDisplay(true);
		setpromotionInfoTemplateDisplay(false);
		setSmsInvitationDisplay(false);
		setEmailInvitationDisplay(false);
		setSelectedSmsEmail("");
		setInfoLangChange(false);
	}

	const infoTemplate = () => {
		setpromotionInfoTemplateDisplay(true);
		setpromotionTemplateDisplay(false);
		setSmsInvitationDisplay(false);
		setEmailInvitationDisplay(false);
		setSelectedSmsEmail("");
		setInfoLangChange(false);
	};


	function handleClickcancel(data) {
		if (payload.is_custom === 1){
			sessionStorage.setItem("setCampaignselector", "");
		}
		
		payload.reference = reference
		payload.promotion_name = promotionName
		payload.promotion_desc = promotionDesc

		if (!(code && code !== 'create_new' && isDraft)) {
            // for not created one            
            localStorage.setItem("promotionCreation", JSON.stringify(payload));
        }
        else{
            localStorage.removeItem('promotionCreation');
        }

		// localStorage.setItem("promotionCreation", JSON.stringify(payload));
		// Pass the value to the handleOK function of main component from wheere the popup was called
		handleOKButton(data, flags,code, JSON.stringify(payload));
	}

	const languageChange = (event) => {
		if(infoLangChange){
            setInfoLangChange(false)
        }
		setLanguage(event.target.value);

		if (promotionInfoTemplateDisplay) {
			setHeaderText(result.data.promotion_header[event.target.value]);
			setPromotionInfo(
				convertHtmlToEditorState(
					result.data.promotion_information[event.target.value]
				)
			);
		}

		if (selectedSmsEmail === "sms_invitation") {
			if (
				result?.data?.invitations_reminders?.[selectedSmsEmail]?.body?.[
				event.target.value
				]
			) {
				setMessageText(
					result.data.invitations_reminders[selectedSmsEmail].body[
					event.target.value
					]
				);
			} else if (
				payload.invite_reminder_list[0].sms_text[event.target.value]
			) {
				setMessageText(
					payload.invite_reminder_list[0].sms_text[event.target.value]
				);
			} else {
				setMessageText("");
			}
		} else if (selectedSmsEmail === "first_reminder_sms") {
			if (
				result?.data?.invitations_reminders?.[selectedSmsEmail]?.body?.[
				event.target.value
				]
			) {
				setFirstMessageText(
					result.data.invitations_reminders[selectedSmsEmail].body[
					event.target.value
					]
				);
			} else if (
				payload.invite_reminder_list[2].sms_text[event.target.value]
			) {
				setFirstMessageText(
					payload.invite_reminder_list[2].sms_text[event.target.value]
				);
			} else {
				setFirstMessageText("");
			}
		} else if (selectedSmsEmail === "final_reminder_sms") {
			if (
				result?.data?.invitations_reminders?.[selectedSmsEmail]?.body?.[
				event.target.value
				]
			) {
				setFinalMessageText(
					result.data.invitations_reminders[selectedSmsEmail].body[
					event.target.value
					]
				);
			} else if (
				payload.invite_reminder_list[4].sms_text[event.target.value]
			) {
				setFinalMessageText(
					payload.invite_reminder_list[4].sms_text[event.target.value]
				);
			} else {
				setFinalMessageText("");
			}
		} else if (selectedSmsEmail === "email_invitation") {
			if (
				result?.data?.invitations_reminders?.[selectedSmsEmail]
					?.subject?.[event.target.value]
			) {
				setEmailSubject(
					result.data.invitations_reminders[selectedSmsEmail].subject[
					event.target.value
					]
				);
			} else if (
				payload.invite_reminder_list[1].email_subject[
				event.target.value
				]
			) {
				setEmailSubject(
					payload.invite_reminder_list[1].email_subject[
					event.target.value
					]
				);
			} else {
				setEmailSubject("");
			}
			if (
				result?.data?.invitations_reminders?.[selectedSmsEmail]?.body?.[
				event.target.value
				]
			) {
				setEmailBody(
					convertHtmlToEditorState(
						result.data.invitations_reminders[selectedSmsEmail]
							.body[event.target.value]
					)
				);
			} else if (
				payload.invite_reminder_list[1].email_body[event.target.value]
			) {
				setEmailBody(
					convertHtmlToEditorState(
						payload.invite_reminder_list[1].email_body[
						event.target.value
						]
					)
				);
			} else {
				setEmailBody("");
			}
		} else if (selectedSmsEmail === "first_reminder_email") {
			if (
				result?.data?.invitations_reminders?.[selectedSmsEmail]
					?.subject?.[event.target.value]
			) {
				setFirstEmailSubject(
					result.data.invitations_reminders[selectedSmsEmail].subject[
					event.target.value
					]
				);
			} else if (
				payload.invite_reminder_list[3].email_subject[
				event.target.value
				]
			) {
				setFirstEmailSubject(
					payload.invite_reminder_list[3].email_subject[
					event.target.value
					]
				);
			} else {
				setFirstEmailSubject("");
			}
			if (
				result?.data?.invitations_reminders?.[selectedSmsEmail]?.body?.[
				event.target.value
				]
			) {
				setFirstEmailBody(
					convertHtmlToEditorState(
						result.data.invitations_reminders[selectedSmsEmail]
							.body[event.target.value]
					)
				);
			} else if (
				payload.invite_reminder_list[3].email_body[event.target.value]
			) {
				setFirstEmailBody(
					convertHtmlToEditorState(
						payload.invite_reminder_list[3].email_body[
						event.target.value
						]
					)
				);
			} else {
				setFirstEmailBody("");
			}
		} else if (selectedSmsEmail === "final_reminder_email") {
			if (
				result?.data?.invitations_reminders?.[selectedSmsEmail]
					?.subject?.[event.target.value]
			) {
				setFinalEmailSubject(
					result.data.invitations_reminders[selectedSmsEmail].subject[
					event.target.value
					]
				);
			} else if (
				payload.invite_reminder_list[5].email_subject[
				event.target.value
				]
			) {
				setFinalEmailSubject(
					payload.invite_reminder_list[5].email_subject[
					event.target.value
					]
				);
			} else {
				setFinalEmailSubject("");
			}
			if (
				result?.data?.invitations_reminders?.[selectedSmsEmail]?.body?.[
				event.target.value
				]
			) {
				setFinalEmailBody(
					convertHtmlToEditorState(
						result.data.invitations_reminders[selectedSmsEmail]
							.body[event.target.value]
					)
				);
			} else if (
				payload.invite_reminder_list[5].email_body[event.target.value]
			) {
				setFinalEmailBody(
					convertHtmlToEditorState(
						payload.invite_reminder_list[5].email_body[
						event.target.value
						]
					)
				);
			} else {
				setFinalEmailBody("");
			}
		}
	};

	const getInvitationReminder = (data) => {
		setBodyTextError("");
		setSubjectError("");
		setMessageTextError("");
		setSelectedSmsEmail(data);
		findDescriptionById(data);
		setpromotionTemplateDisplay(false);
		setpromotionInfoTemplateDisplay(false);
		setInfoLangChange(false);

		if (
			data === "sms_invitation" ||
			data === "first_reminder_sms" ||
			data === "final_reminder_sms"
		) {
			// setMessageText(result.data.invitaions_reminders[selectedSmsEmail].body[event.target.value])
			setSmsInvitationDisplay(true);
			setEmailInvitationDisplay(false);
		} else if (
			data === "email_invitation" ||
			data === "first_reminder_email" ||
			data === "final_reminder_email"
		) {
			// setEmailSubject(result.data.invitaions_reminders[selectedSmsEmail].subject[event.target.value])
			// setEmailBody(result.data.invitaions_reminders[selectedSmsEmail].body[event.target.value])
			setEmailInvitationDisplay(true);
			setSmsInvitationDisplay(false);
		}

		if (data === "sms_invitation") {
			if (
				!localStorage.getItem("promotionCreation") &&
				result?.data?.invitations_reminders?.[data]?.body?.[language]
			) {
				setMessageText(
					result.data.invitations_reminders[data].body[language]
				);
			} else if (payload.invite_reminder_list[0].sms_text[language]) {
				setMessageText(
					payload.invite_reminder_list[0].sms_text[language]
				);
			} else {
				setMessageText("");
			}
		} else if (data === "first_reminder_sms") {
			if (
				!localStorage.getItem("promotionCreation") &&
				result?.data?.invitations_reminders?.[data]?.body?.[language]
			) {
				setFirstMessageText(
					result.data.invitations_reminders[data].body[language]
				);
			} else if (payload.invite_reminder_list[2].sms_text[language]) {
				setFirstMessageText(
					payload.invite_reminder_list[2].sms_text[language]
				);
			} else {
				setFirstMessageText("");
			}
		} else if (data === "final_reminder_sms") {
			if (
				!localStorage.getItem("promotionCreation") &&
				result?.data?.invitations_reminders?.[data]?.body?.[language]
			) {
				setFinalMessageText(
					result.data.invitations_reminders[data].body[language]
				);
			} else if (payload.invite_reminder_list[4].sms_text[language]) {
				setFinalMessageText(
					payload.invite_reminder_list[4].sms_text[language]
				);
			} else {
				setFinalMessageText("");
			}
		} else if (data === "email_invitation") {
			if (
				!localStorage.getItem("promotionCreation") &&
				result?.data?.invitations_reminders?.[data]?.subject?.[language]
			) {
				setEmailSubject(
					result.data.invitations_reminders[data].subject[language]
				);
			} else if (
				payload.invite_reminder_list[1].email_subject[language]
			) {
				setEmailSubject(
					payload.invite_reminder_list[1].email_subject[language]
				);
			} else {
				setEmailSubject("");
			}
			if (
				!localStorage.getItem("promotionCreation") &&
				result?.data?.invitations_reminders?.[data]?.body?.[language]
			) {
				setEmailBody(
					convertHtmlToEditorState(
						result.data.invitations_reminders[data].body[language]
					)
				);
			} else if (payload.invite_reminder_list[1].email_body[language]) {
				setEmailBody(
					convertHtmlToEditorState(
						payload.invite_reminder_list[1].email_body[language]
					)
				);
			} else {
				setEmailBody("");
			}
		} else if (data === "first_reminder_email") {
			if (
				!localStorage.getItem("promotionCreation") &&
				result?.data?.invitations_reminders?.[data]?.subject?.[language]
			) {
				setFirstEmailSubject(
					result.data.invitations_reminders[data].subject[language]
				);
			} else if (
				payload.invite_reminder_list[3].email_subject[language]
			) {
				setFirstEmailSubject(
					payload.invite_reminder_list[3].email_subject[language]
				);
			} else {
				setFirstEmailSubject("");
			}
			if (
				!localStorage.getItem("promotionCreation") &&
				result?.data?.invitations_reminders?.[data]?.body?.[language]
			) {
				setFirstEmailBody(
					convertHtmlToEditorState(
						result.data.invitations_reminders[data].body[language]
					)
				);
			} else if (payload.invite_reminder_list[3].email_body[language]) {
				setFirstEmailBody(
					convertHtmlToEditorState(
						payload.invite_reminder_list[3].email_body[language]
					)
				);
			} else {
				setFirstEmailBody("");
			}
		} else if (data === "final_reminder_email") {
			if (
				!localStorage.getItem("promotionCreation") &&
				result?.data?.invitations_reminders?.[data]?.subject?.[language]
			) {
				setFinalEmailSubject(
					result.data.invitations_reminders[data].subject[language]
				);
			} else if (
				payload.invite_reminder_list[5].email_subject[language]
			) {
				setFinalEmailSubject(
					payload.invite_reminder_list[5].email_subject[language]
				);
			} else {
				setFinalEmailSubject("");
			}
			if (
				!localStorage.getItem("promotionCreation") &&
				result?.data?.invitations_reminders?.[data]?.body?.[language]
			) {
				setFinalEmailBody(
					convertHtmlToEditorState(
						result.data.invitations_reminders[data].body[language]
					)
				);
			} else if (payload.invite_reminder_list[5].email_body[language]) {
				setFinalEmailBody(
					convertHtmlToEditorState(
						payload.invite_reminder_list[5].email_body[language]
					)
				);
			} else {
				setFinalEmailBody("");
			}
		}
	};

	const convertHtmlToEditorState = (html) => {
		const blocksFromHTML = convertFromHTML(html);
		const contentState = ContentState.createFromBlockArray(
			blocksFromHTML.contentBlocks,
			blocksFromHTML.entityMap
		);
		return EditorState.createWithContent(contentState);
	};

	const [isDraft, setisDraft] = useState(false);



	const fetchData = async () => {
		try {
			let endpoint = "?promotion_code=" + code;
			let url = URL.PROMOTION_PREVIEW + endpoint;
			const response = await axios.get(url);
			payload.promotion_code = code;
			payload.is_custom = 0;
			setResult(response.data);
			setPromotionName(response.data.data.promotion_name);
			setPromotionDesc(response.data.data.promotion_desc);
			setHeaderText(
				response.data.data.promotion_header[language]
			);
			setPromotionInfo(
				convertHtmlToEditorState(
					response.data.data.promotion_information[
					language
					]
				)
			);
			if (response.data.data.invitations_reminders) {
				payload.invite_reminder_list[0].sms_text = response.data.data.invitations_reminders.sms_invitation.body
				payload.invite_reminder_list[1].email_subject = response.data.data.invitations_reminders.email_invitation.subject
				payload.invite_reminder_list[1].email_body = response.data.data.invitations_reminders.email_invitation.body
				payload.invite_reminder_list[2].sms_text = response.data.data.invitations_reminders.first_reminder_sms.body
				payload.invite_reminder_list[3].email_subject = response.data.data.invitations_reminders.first_reminder_email.subject
				payload.invite_reminder_list[3].email_body = response.data.data.invitations_reminders.first_reminder_email.body
				payload.invite_reminder_list[4].sms_text = response.data.data.invitations_reminders.final_reminder_sms.body
				payload.invite_reminder_list[5].email_subject = response.data.data.invitations_reminders.final_reminder_email.subject
				payload.invite_reminder_list[5].email_body = response.data.data.invitations_reminders.final_reminder_email.body

				payload.invite_reminder_list[0].schedule_date = response.data.data.invitations_reminders.sms_invitation.schedule_date
                payload.invite_reminder_list[1].schedule_date = response.data.data.invitations_reminders.email_invitation.schedule_date
                payload.invite_reminder_list[2].schedule_date = response.data.data.invitations_reminders.first_reminder_sms.schedule_date
                payload.invite_reminder_list[3].schedule_date = response.data.data.invitations_reminders.first_reminder_email.schedule_date
                payload.invite_reminder_list[4].schedule_date = response.data.data.invitations_reminders.final_reminder_sms.schedule_date
                payload.invite_reminder_list[5].schedule_date = response.data.data.invitations_reminders.final_reminder_email.schedule_date

				setMessageText(response.data.data.invitations_reminders.sms_invitation.body[language]);
				setEmailSubject(response.data.data.invitations_reminders.email_invitation.subject[language]);
				setEmailBody(convertHtmlToEditorState(response.data.data.invitations_reminders.email_invitation.body[language]));
				setFirstMessageText(response.data.data.invitations_reminders.first_reminder_sms.body[language]);
				setFirstEmailSubject(response.data.data.invitations_reminders.first_reminder_email.subject[language]);
				setFirstEmailBody(convertHtmlToEditorState(response.data.data.invitations_reminders.first_reminder_email.body[language]));
				setFinalMessageText(response.data.data.invitations_reminders.final_reminder_sms.body[language]);
				setFinalEmailSubject(response.data.data.invitations_reminders.final_reminder_email.subject[language]);
				setFinalEmailBody(convertHtmlToEditorState(response.data.data.invitations_reminders.final_reminder_email.body[language]));
			}

			setFlags(response.data.data.flags)


			//isDraft
			setisDraft(response.data.data.is_draft);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			console.error("There was an error!", error);
			handleOKButton();
		}
	};


	useEffect(() => {
		setInfoLangChange(false);
		setLanguage('en')
		if (isOpen) {
			
			setpromotionTemplateDisplay(true);
			setpromotionInfoTemplateDisplay(false);
			setSmsInvitationDisplay(false);
			setEmailInvitationDisplay(false);

			if (code && code !== 'create_new' ){
                setIsInfoDisplay(false)
                setIsDetailsDisplay(true)
            }

            else{

                if (code === "create_new"){
                    setIsInfoDisplay(true)
                    setIsDetailsDisplay(false)
					setFlags(initialFlagsGeneric)
                    resetState()
                }

            }

            if (code && code !== 'create_new' && !localStorage.getItem("promotionCreation")){
                setIsLoading(true);
                fetchData();
            }

		}
	}, [isOpen]);

	const boxStyle = {
		"--box-width": isInfoDisplay ? "50%" : "70%",
		"--box-height": isInfoDisplay ? "50%" : "80%",
	};

	// Image upload callback function
	const uploadImageCallBack = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onloadend = () => {
				resolve({ data: { link: reader.result } });
			};
			reader.onerror = reject;
			reader.readAsDataURL(file);
		});
	};


	return (
		<div>
			<Modal open={isOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
				<Box className={"createPromotionPopupcontainer"} style={boxStyle} >

					{isLoading && (
						<CommoncircularProgress></CommoncircularProgress>
					)}

					<Grid container>
						<Grid item xs={12} className={"GridIcon"} onClick={handleClickcancel} style={{ cursor: "pointer" }} >
							<img src="/asset/Member/cancelicon.png" alt="cancelIcon" width="15px" height="15px" />
						</Grid>

						{isInfoDisplay && (
							<Grid item xs={12} className={"Gridtext"}>
								<br></br>
								<Grid container spacing={2} alignItems="center" className={"accordian"}>
									<Grid container className="surveyDiv">
										<Grid item xs={12} style={{ position: "relative" }} >

											<div className="label_text"> Promotion Name </div>

											<TextField
												className={"textField"}
												value={promotionName}
												onChange={handlePromNameChange}
												inputProps={{ maxLength: 64 }}
												id="outlined-basic"
												variant="outlined"
												fullWidth
												placeholder="Type your promotion name..."
												disabled={disabledflag}
											/>
										</Grid>

										<Grid item xs={12} className="descDiv">

											<div className="label_text"> Description </div>

											<TextField
												className={"textField"}
												id="outlined-multiline-flexible"
												multiline
												value={promotionDesc}
												inputProps={{ maxLength: 255 }}
												rows={3}
												onChange={handlePromDescChange}
												fullWidth
												variant="outlined"
												placeholder="Write a description here..."
												disabled={disabledflag}
											/>
											{promotionDescError && (
												<span className="errorMsg"> {promotionDescError} </span>
											)}

										</Grid>

										<Grid item xs={12} className="saveCancelDiv" >
											<Button
												variant="contained"
												aria-label="Save"
												type="submit"
												disabled={
													promotionNameError != "" ||
													promotionDescError != "" ||
													disabledflag
												}
												onClick={detailsSave}
												className="Savebtn"
											>
												Save
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						)}
						{isDetailsDisplay && (
							<Grid item xs={12} className={"Gridtext"}>
								<Grid container xs={12} className={"accordian"}>
									<Grid item xs={3}>
										<button
											className={promotionTemplateDisplay ? "surveyBtn active" : "surveyBtn"}
											onClick={() => surveyTemplate()}
										>
											Promotion Details
										</button>
										<button
											className={promotionInfoTemplateDisplay ? "surveyBtn active" : "surveyBtn"}
											onClick={() => infoTemplate()}
										>
											Promotion Informations
										</button>
										<Accordion defaultExpanded>
											<AccordionSummary
												className="accordianSummary"
												expandIcon={null}
												disabled
											>
												Invitations & Reminders
											</AccordionSummary>
											<AccordionDetails variant="soft" className="accordianDetails" >
												<div>
													{invitations_reminders.map(
														(item, index) => (
															<button
																className={selectedSmsEmail === item.id ? "questions active" : "questions"}
																onClick={() => getInvitationReminder(item.id)}
																key={index}
															>
																<p style={{ margin: 0, display: 'flex', alignItems: 'center' }}>
																	{item.title}
																	<span style={{ marginLeft: 'auto' }}>
																		{flags[item.id] ? <img src="/asset/Agent/issue.png" alt="issue Icon" style={{ marginLeft: '10px' }} /> : ""}
																	</span>
																</p>
															</button>
														)
													)}
												</div>
											</AccordionDetails>
										</Accordion>
									</Grid>
									<Grid item xs={9}>
										{promotionTemplateDisplay && (
											<Grid container alignItems="center" style={{ position: "relative" }}>
												<Grid container item xs={12} className="surveyDiv" >
													<Grid item xs={12} style={{ position: "relative", }}>

														<div className="label_text">Promotion Name </div>
														<TextField className={"textField"}
															value={promotionName}
															onFocus={() => handleFocus("promotionName", promotionName)}
															onBlur={() => handleBlur("promotionName")}
															onChange={handlePromNameChange}
															inputProps={{ maxLength: 64, }}
															id="outlined-basic"
															variant="outlined"
															fullWidth
															placeholder="Type your promotion name..."
															disabled={disabledflag}
														/>
														{promotionNameError && (
															<span className="errorMsg"> {promotionNameError} </span>
														)}
														{promotionNameFocus && (
															<div style={{
																position: "absolute",
																bottom: "-28px",
																right: "5%",
																display: "flex",
																alignItems: "center",
															}}>
																<div className="tickBox" onClick={detailsSave} >
																	<img className="iconTickClose" src="/asset/Agent/tick.png" alt="update" />
																</div>

																<div className="closeBox" onClick={() => handleTextFieldCancel("promotionName")}>
																	<img className="iconTickClose" src="/asset/Agent/close.png" alt="cancel" />
																</div>
															</div>
														)}
													</Grid>
													<Grid item xs={12} className="descDiv" >
														<div className="label_text">
															Description
														</div>
														<TextField
															className={"textField"}
															id="outlined-multiline-flexible"
															multiline
															value={promotionDesc}
															rows={3}
															inputProps={{ maxLength: 255, }}
															onChange={handlePromDescChange}
															onFocus={() => handleFocus("promotionDescription", promotionDesc)}
															onBlur={() => handleBlur("promotionDescription")}
															fullWidth
															variant="outlined"
															placeholder="Write a description here..."
															disabled={disabledflag}
														/>
														{promotionDescError && (
															<span className="errorMsg"> {promotionDescError} </span>
														)}
														{promotionDescFocus && (
															<div
																style={{
																	position: "absolute",
																	bottom: "-28px",
																	right: "5%",
																	display: "flex",
																	alignItems: "center",
																}}
															>
																<div className="tickBox">
																	<img
																		className="iconTickClose"
																		src="/asset/Agent/tick.png"
																		alt="update"
																		onClick={detailsSave}
																	/>
																</div>

																<div className="closeBox">
																	<img
																		className="iconTickClose"
																		src="/asset/Agent/close.png"
																		alt="cancel"
																		onClick={() => handleTextFieldCancel("promotionDescription")}
																	/>
																</div>
															</div>
														)}
													</Grid>
												</Grid>
											</Grid>
										)}

										{promotionInfoTemplateDisplay && (
											<Grid
												container
												xs={12}
												alignItems="center"
												style={{ position: "relative" }}
											>
												<Grid item xs={10}>
													<h3 className="surveyText" style={{ marginLeft: 65, }}>
														{promotionName}
													</h3>
												</Grid>
												<Grid item xs={2}>
													<div className="label_text"></div>
													<TextField
														className={"textField"}
														fullWidth
														select
														variant="outlined"
														value={language}
														onChange={languageChange}
													>
														<MenuItem value="en">
															English
														</MenuItem>
														<MenuItem value="es">
															Spanish
														</MenuItem>
														<MenuItem value="vi">
															Vietnamese
														</MenuItem>
													</TextField>
												</Grid>
												<Grid container xs={12} className="surveyDiv" >
													<Grid item xs={12} style={{ position: "relative", }} >
														<div className="label_text">
															Header Name
														</div>
														<TextField
															className={"textField"}
															value={headerText}
															// onFocus={() => handleFocus( "headerText", headerText ) }
															// onBlur={() => handleBlur( "headerText" ) }
															onChange={handleHeaderTextChange}
															inputProps={{ maxLength: 255, }}
															id="outlined-basic"
															variant="outlined"
															fullWidth
															placeholder="Type your header here..."
															disabled={disabledflag}
														/>
														{headerTextError && (
															<span className="errorMsg">
																{
																	headerTextError
																}
															</span>
														)}
														{/* {headerTextFocus && (
															<div
																style={{
																	position: "absolute",
																	bottom: "-28px",
																	right: "5%",
																	display: "flex",
																	alignItems: "center",
																}}
															>
																<div className="tickBox" onClick={ detailsSave } >
																	<img className="iconTickClose" src="/asset/Agent/tick.png" alt="update" />
																</div>

																<div className="closeBox" onClick={() => handleTextFieldCancel( "headerText" ) } >
																	<img className="iconTickClose" src="/asset/Agent/close.png" alt="cancel" />
																</div>
															</div>
														)} */}
													</Grid>
													<Grid item xs={12} className="descDiv" >

														<div className="label_text"> Information </div>

														<Grid xs={12} className="richTextEditorWelcomeThankyou"
															style={{
																border: isRichEditorFocused ? "2px solid #2194F2" : "1px solid gray",
																padding: "10px",
																marginTop: "10px",

																opacity: disabledflag ? 0.7 : 1,
																pointerEvents: disabledflag ? 'none' : 'auto'
															}}>

															<Editor
																editorState={promotionInfo}
																onEditorStateChange={handleInfoTextChange}
																wrapperClassName="demo-wrapper"
																editorClassName="demo-editor"
																placeholder="Write a information here..."
																onFocus={() => handleFocus("promotionInfo", promotionInfo)}
																onBlur={() => handleBlur("promotionInfo")}
																toolbar={{
																	options: [
																		"history",
																		"inline",
																		"blockType",
																		"fontSize",
																		"list",
																		"textAlign",
																		"link",
																		"image", // Add the image option
																	],
																	image: {
																		uploadEnabled: true,
																		uploadCallback: uploadImageCallBack, // Handle image uploads
																		previewImage: true,
																		alt: { present: true, mandatory: false }, // Alt text configuration
																	},
																	inline: { inDropdown: true, },
																	list: { inDropdown: false, },
																	textAlign: { inDropdown: false, },
																	link: { inDropdown: false, },
																	history: {
																		inDropdown: false,
																	},
																}}
																ref={editorRef}
																disabled={disabledflag}
															/>
														</Grid>

														{promotionInfoError && (
															<span className="errorMsg">
																{
																	promotionInfoError
																}
															</span>
														)}
														{/* {promotionInfoFocus && (
															<div
																style={{
																	position: "absolute",
																	bottom: "-28px",
																	right: "5%",
																	display: "flex",
																	alignItems: "center",
																}}
															>
																<div className="tickBox">
																	<img
																		className="iconTickClose"
																		src="/asset/Agent/tick.png"
																		alt="update"
																		onClick={ detailsSave }
																	/>
																</div>

																<div className="closeBox">
																	<img
																		className="iconTickClose"
																		src="/asset/Agent/close.png"
																		alt="cancel"
																		onClick={() => handleTextFieldCancel( "promotionInfo" ) }
																	/>
																</div>
															</div>
														)} */}
														<Grid item xs={12} className="saveCancelDiv" >
															<Button
																variant="contained"
																aria-label="Save"
																type="submit"
																disabled={messageTextError || disabledflag}
																onClick={infoSave}
																className="Savebtn"
															>
																Save
															</Button>

														</Grid>
													</Grid>
												</Grid>
											</Grid>
										)}

										{smsInvitationDisplay && (
											<Grid container xs={12} className="surveyQuestionDiv" style={{ position: "relative" }} >
												<Grid item xs={10}>

													<h3 className="surveyText"> {promotionName} </h3>
													<p className="smsDesc"> {selectedDescription} </p>

												</Grid>
												<Grid item xs={2}>
													<div className="label_text"></div>
													<TextField
														className={"textField"}
														fullWidth
														select
														variant="outlined"
														value={language}
														onChange={languageChange}
													>
														<MenuItem value="en"> English </MenuItem>
														<MenuItem value="es"> Spanish </MenuItem>
														<MenuItem value="vi"> Vietnamese </MenuItem>
													</TextField>
												</Grid>
												<Grid
													item
													xs={12}
													className="descDiv"
												>
													<div className="label_text">
														Message Text
													</div>
													<TextField
														className={"textField"}
														id="outlined-multiline-flexible"
														multiline
														value={selectedSmsEmail === "sms_invitation" ? messageText : selectedSmsEmail === "first_reminder_sms" ? firstmessageText : finalmessageText}
														rows={3}
														onChange={messageTextChange}
														onFocus={handleFocus}
														onBlur={handleBlur}
														fullWidth
														variant="outlined"
														placeholder="Type message here..."
														disabled={disabledflag}
													/>
													{messageTextError && (
														<span className="errorMsg">
															{messageTextError}
														</span>
													)}
												</Grid>
												<Grid item xs={12} spacing={2} className="saveCancelDiv" >
													<Button
														variant="contained"
														aria-label="Save"
														type="submit"
														disabled={messageTextError || disabledflag}
														onClick={smsEmailSave}
														className="Savebtn"
													>
														Save
													</Button>
												</Grid>
											</Grid>
										)}

										{emailInvitationDisplay && (
											<Grid container xs={12} className="surveyQuestionDiv" style={{ position: "relative" }} >
												<Grid item xs={10}>
													<h3 className="surveyText">
														{promotionName}
													</h3>
													<p className="smsDesc">
														{selectedDescription}
													</p>
												</Grid>
												<Grid item xs={2}>
													<div className="label_text"></div>
													<TextField
														className={"textField"}
														fullWidth
														select
														variant="outlined"
														value={language}
														onChange={languageChange}
													>
														<MenuItem value="en"> English </MenuItem>
														<MenuItem value="es"> Spanish </MenuItem>
														<MenuItem value="vi"> Vietnamese
														</MenuItem>
													</TextField>
												</Grid>
												<Grid
													item
													xs={12}
													className="descDiv"
												>
													<div className="label_text">
														Subject
													</div>
													<TextField
														className={"textField"}
														value={selectedSmsEmail === "email_invitation" ? emailSubject : selectedSmsEmail === "first_reminder_email" ? firstemailSubject : finalemailSubject}
														onChange={emailSubjectChange}
														onFocus={handleFocus}
														onBlur={handleBlur}
														inputProps={{ maxLength: 255, }}
														id="outlined-basic"
														variant="outlined"
														fullWidth
														placeholder="Enter subject line"
														disabled={disabledflag}
													/>
													{subjectError && (
														<span className="errorMsg">
															{subjectError}
														</span>
													)}
												</Grid>
												<div className="body_text_label">
													Body Text
												</div>
												<Grid item xs={12} className="richTextEditor"
													sx={{
														opacity: disabledflag ? 0.7 : 1,
														pointerEvents: disabledflag ? 'none' : 'auto'
													}}>
													<Editor
														editorState={selectedSmsEmail === "email_invitation" ? emailBody : selectedSmsEmail === "first_reminder_email" ? firstemailBody : finalemailBody
														}
														onEditorStateChange={handleEmailBodyTextChange}
														wrapperClassName="demo-wrapper"
														editorClassName="demo-editor"
														placeholder=""
														toolbar={{
															options: ["history", "inline", "blockType", "fontSize", "list", "textAlign", "link",],
															inline: { inDropdown: true, },
															list: { inDropdown: false, },
															textAlign: { inDropdown: false, },
															link: { inDropdown: false, },
															history: { inDropdown: false, },
														}}
														ref={editorRef}
													/>
												</Grid>
												<Grid item xs={12} spacing={2} className="saveCancelDiv" >
													<Button
														variant="contained"
														aria-label="Save"
														type="submit"
														disabled={subjectError || disabledflag}
														onClick={smsEmailSave}
														className="Savebtn"
													>
														Save
													</Button>
												</Grid>
											</Grid>
										)}
										
									</Grid>
								</Grid>
							</Grid>
						)}

						<UpdateSurveyName
							childModalopen={childModalopen}
							setChildModalOpen={setChildModalOpen}
							surveyName={promotionName}
							updatedSurveyName={handleupdatedPromotionName}
							campaignType="promotion"
							surveyUpdateVariant={promotionUpdateVariant}
							namesArray={promotionArray}
						/>
						
					</Grid>
				</Box>
			</Modal>
		</div>
	);
}

export default CreatePromotionPopup;
